import { Content } from "react-bulma-components";
import { Title } from "../../common/layout/title/Title";
import { Table } from "../../layout/table/table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import keys from "./keys.json"
import Markdown from "react-markdown";
import useAPIQuery from "../../hooks/use-api-query.hook";
import { H4A_API } from "@hamm4all/shared";
import H4AImage from "../../layout/image/image.component";
import { Link } from "react-router-dom";

export default function KeysItems() {
    return <>
        <Title>Les Clés</Title>
        <Content>
            <p>Dans Hammerfest, un certain nombre de dimensions sont bloquées, car elles nécessitent une clé pour les ouvrir.<br/>En voici la liste :</p>
            <Table>
                <thead>
                    <tr>
                        <td>Apparence</td>
                        <td>Nom</td>
                        <td>Niveau d'obtension</td>
                        <td>Conditions</td>
                        <td>Effet</td>
                    </tr>
                </thead>
                <tbody>
                    {keys.map((key) => {
                        return <tr key={key.name}>
                            <td>{key.itemID ? <img
                                src={`/assets/items/${key.itemID}.svg`}
                                style={{maxWidth: "35px"}}
                                alt="item"
                            /> : <FontAwesomeIcon icon={faTimes} className="has-text-danger" />}</td>
                            <td>{key.name}</td>
                            <td>{key.level && <LevelWhereToGetItem level={key.level}/>}</td>
                            <td>{key.conditions ? 
                                    <Markdown
                                       components={{
                                            "a": ({node, href, ref, ...props}) => <Link to={href!} {...props} />,
                                        }}
                                    >
                                        {key.conditions}
                                    </Markdown> :
                                    <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
                                }
                            </td>
                            <td>
                                <Markdown
                                    components={{
                                        "a": ({node, href, ref, ...props}) => <Link to={href!} {...props} />,
                                    }}
                                >
                                    {key.effect}
                                </Markdown>
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </Content>
    </>
}

function LevelWhereToGetItem(props: Readonly<{level: {gameID: number, levelName: string}}>) {
    const levelQuery = useAPIQuery({
        "endpoint": H4A_API.v1.levels.get,
        "params": props.level,
        "query": {}
    })

    if (levelQuery.isPending || !props.level) {
        return <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
    }

    const level = levelQuery.data;

    return <Link to={`/games/${props.level.gameID}/levels/${level.name}`}>
        <H4AImage src={level.mainScreenshotID} />
    </Link>
}