import { HTTPMethod } from "../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { LevelsParam } from "../../levels.params";
import { LevelsVideoAddBody } from "./videos.add.body";


export class LevelsVideoAddCall extends APIEndpointAction<LevelsParam, LevelsVideoAddBody, {}> {
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("videos")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<LevelsParam, LevelsVideoAddBody>): string {
        return `Une erreur s'est produite lors de l'ajout de la vidéo du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<LevelsParam, {}>): string {
        return `La vidéo du niveau ${params.params.levelName} a bien été ajoutée !`
    }
}