import { ComponentProps } from "react";

interface TableProps extends ComponentProps<"div"> {
    children: React.ReactNode
}

export function Table(props: TableProps) {
    return <div className="centered" id={props.id}>
        <table className="table is-bordered is-hoverable">
            {props.children}
        </table>
    </div>
}