import { useState } from "react";
import ModalForm from "../../../../components/form/modal-form.component";
import useVideoAdd from "../hooks/useVideoAdd";
import useVideoUpdate from "../hooks/useVideoUpdate";
import { LevelsVideoUpdateBody } from "@hamm4all/shared";
import { FormFieldCheckbox } from "../../../../components/form/field-components/form-checkbox.component";
import { FormFieldInput } from "../../../../components/form/field-components/form-field-input.component";

type LevelVideosModalProps = LevelVideosAddModalProps | LevelVideosEditModalProps;

interface LevelVideosAddModalProps extends BaseLevelVideosModalProps {
    action: "add";
}

interface LevelVideosEditModalProps extends BaseLevelVideosModalProps {
    action: "edit";
    videoIndex: number;
    initialData: LevelsVideoUpdateBody;
}

interface BaseLevelVideosModalProps {
    isModalOpen: boolean;
    setModalOpen: (value: boolean) => void;
    gameID: number;
    levelName: string;
}

export function LevelVideosModal(props: Readonly<LevelVideosModalProps>) {
    // Default values for add action
    const defaults = { link: "", livesLost: 0, hasOrder: false };
    const initialData = props.action === "edit" ? props.initialData : defaults;

    const [link, setLink] = useState(initialData.link);
    const [livesLost, setLivesLost] = useState(initialData.livesLost);
    const [hasOrder, setHasOrder] = useState(initialData.hasOrder);

    const addMutation = useVideoAdd({
        gameID: props.gameID,
        levelName: props.levelName,
        closePopup: () => props.setModalOpen(false),
    });
    const updateMutation = useVideoUpdate({
        gameID: props.gameID,
        levelName: props.levelName,
        closePopup: () => props.setModalOpen(false),
        videoIndex: props.action === "edit" ? props.videoIndex : 0,
    });

    const mutation = props.action === "edit" ? updateMutation : addMutation;
    const title = props.action === "edit" ? "Modifier une vidéo" : "Ajouter une vidéo";

    const handleSubmit = async () => mutation.mutate({ link, livesLost, hasOrder });

    return (
        <ModalForm
            title={title}
            isOpen={props.isModalOpen}
            setIsOpen={props.setModalOpen}
            handleSubmit={handleSubmit}
        >
            <FormFieldInput
                label="Lien"
                changeHandler={setLink}
                required
                minLength={1}
                maxLength={70}
                value={link}
            />

            <FormFieldInput
                label="Vies perdues"
                changeHandler={setLivesLost}
                required
                type="number"
                value={livesLost}
            />
            
            <FormFieldCheckbox
                label="A un ordre"
                changeHandler={setHasOrder}
                checked={hasOrder}
            />
        </ModalForm>
    );
}
