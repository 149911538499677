import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Item } from "@hamm4all/shared"
import { useState } from "react"
import ItemFormModal from "../../items/items.add-update.component"

interface ItemUpdateButtonProps {
    item: Item
}

export default function ItemUpdateButton({item}: Readonly<ItemUpdateButtonProps>) {
    const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false)

    return <>
        <button className="button is-success" onClick={() => setIsEditItemModalOpen(true)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
        <ItemFormModal
            type="update"
            modal={{
                "isModalOpen": isEditItemModalOpen,
                "setIsModalOpen": setIsEditItemModalOpen
            }}
            initialData={{
                "names": item.name,
                "coefficient": item.coefficient,
                "effect": item.effect,
                "gameID": item.game.id,
                "id": item.id,
                "inGameID": item.in_game_id,
                "value": item.value
            }}
        />
    </>
}