import { GameParam, H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseAddLevelProps extends GameParam {
    closePopup: () => void;
}

export default function useAddLevel(props: Readonly<UseAddLevelProps>) {
    const {gameID} = props
    const addMutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.add,
        "params": {gameID},
        "onSuccess": props.closePopup,
    })

    return addMutation
}