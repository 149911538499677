import { H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseSaveClassicTransitionsProps {
    gameID: number;
    levelName: string;
    onSuccess: () => void;
}

export default function useSaveClassicTransitions(props: Readonly<UseSaveClassicTransitionsProps>) {
    const saveClassicTransitions = useAPIMutation({
        "endpoint": H4A_API.v1.levels.transitions.classic.update,
        "params": props,
        "onSuccess": props.onSuccess,
    })

    return saveClassicTransitions
}