import ModalForm from "../../../components/form/modal-form.component";
import { useState } from "react";
import { H4A_API, Level, LevelTransitionsLabyUpdateBody, LevelTransitionsLaby, LevelTransitionLabyType, LevelTransitionLabyUpdateBody, LEVEL_TRANSITION_LABY_TYPES } from "@hamm4all/shared";
import { FormFieldSelect } from "../../../components/form/field-components/form-field-select.component";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface LevelLabyLinksModalProps {
    gameID: number;
    levelName: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    initialLinks: LevelTransitionsLaby;
    levelsOfGame: Level[]
}

const EMPTY_LINK = "---"

export default function LevelLabyLinksModal({ isOpen, setIsOpen, initialLinks, gameID, levelName, levelsOfGame }: Readonly<LevelLabyLinksModalProps>) {

    const [labyLinks, setLabyLinks] = useState<LevelTransitionsLabyUpdateBody>(
        Object.entries(initialLinks).reduce((acc, [key, value]) => {
            const typedKey = key as LevelTransitionLabyType // I think it's a real TS problem, cause it seems typed correctly
            acc[typedKey] = {
                "targetID": value.level.id
            }
            return acc
        }, {} as LevelTransitionsLabyUpdateBody)
    )

    function onChangeLinkType(type: LevelTransitionLabyType, newTargetID: number) {
        setLabyLinks({
            ...labyLinks,
            [type]: {
                "targetID": newTargetID
            }
        })
    }

    const nextLinksUpdateMutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.transitions.laby.update,
        "params": {
            "gameID": gameID,
            "levelName": levelName
        },
        "onSuccess": () => {
            setIsOpen(false);
        }
    });

    const labyTransitionTypes: LevelTransitionLabyType[] = ["up", "right", "down", "left"]

    return (
        <ModalForm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Editer les niveaux suivants"
            handleSubmit={async () => nextLinksUpdateMutation.mutate(
                (() => {
                    const links = {...labyLinks}
                    for(const transitionsType of LEVEL_TRANSITION_LABY_TYPES) {
                        const transition = links[transitionsType];
                        if (transition && transition.targetID === -1) {
                            delete links[transitionsType]
                        }
                    }
                    return links
                })()
            )}
        >
            <div className="field is-grouped">
                {labyTransitionTypes.map(transitionType => 
                    <div className="control" key={transitionType}>
                        <LabyLinkSelect
                            type={transitionType}
                            labyLink={labyLinks[transitionType]}
                            levelsOfGame={levelsOfGame}
                            onChangeLinkType={onChangeLinkType}
                        />
                    </div>
                )}

            </div>
        </ModalForm>
    )
}

interface LabyLinkSelectProps {
    type: LevelTransitionLabyType;
    labyLink?: LevelTransitionLabyUpdateBody
    levelsOfGame: Level[]
    onChangeLinkType: (type: "left" | "up" | "right" | "down", newTargetID: number) => void;
}

function LabyLinkSelect(props: Readonly<LabyLinkSelectProps>) {
    return (
        <FormFieldSelect
            label={getNameOfType(props.type)}
            value={props.labyLink?.targetID}
            changeHandler={(newValue) => props.onChangeLinkType(props.type, parseInt(newValue))}
            options={[
                {
                    "value": "-1",
                    "label": EMPTY_LINK
                },
                ...props.levelsOfGame.map((level) => ({
                    "value": "" + level.id,
                    "label": level.name
                }))
            ]}
        />
    )
}

function getNameOfType(type: LevelTransitionLabyType) {
    const names = {
        up: "Haut",
        right: "Droite",
        down: "Bas",
        left: "Gauche"
    };
    return names[type];
}