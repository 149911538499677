import { LEVELS_SCREENSHOT_FILE_FIELD_NAME, ScreenshotParam } from "@hamm4all/shared"
import ModalFormFileUpload from "../../../../components/form/modal-form-file-upload.component"
import useUpdateScreenshot from "../../../levels/screenshots/hook/useUpdateScreenshot"

interface LevelScreenshotUpdateModalProps extends ScreenshotParam {
    isModalOpen: boolean
    setIsModalOpen: (value: boolean) => void
}

export default function LevelScreenshotUpdateModal(props: Readonly<LevelScreenshotUpdateModalProps>) {
    const uploadScreenshotMutation = useUpdateScreenshot({
        "gameID": props.gameID,
        "levelName": props.levelName,
        "screenshotOrderNumber": props.screenshotOrderNumber,
        "onClose": () => props.setIsModalOpen(false)
    })

    function handleFileUpload(file: File) {
        const formData = new FormData()
        formData.append(LEVELS_SCREENSHOT_FILE_FIELD_NAME, file)
        uploadScreenshotMutation.mutate(formData)
    }

    return (
        <ModalFormFileUpload
            isOpen={props.isModalOpen}
            setIsOpen={props.setIsModalOpen}
            handleSubmit={async (file) => handleFileUpload(file)}
            title="Modifier un screen"
            name="screenshot"
            label="Sélectionner un screen"
        />
    )
}