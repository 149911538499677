import { Content } from "react-bulma-components";
import { Title } from "../../common/layout/title/Title";
import { Table } from "../../layout/table/table.component";
import crystals from "./crystals.json"

export default function CrystalRules() {

    return <>
        <Title>Les Cristaux</Title>
        <Content>
            <p>Les Les cristaux sont probablement les objets les plus communs et les plus importants. Ils tombent du ciel à la mort d'un ennemi, et donnent tous des points, dont le montant augmente selon le nombre d'ennemis tué par une même bombe.<br/>Voici le tableau comparatif des cristaux :</p>
        </Content>
            <Table>
                <thead>
                    <tr>
                        <td>Cristal</td>
                        <td>Points</td>
                        <td>Ennemis tués</td>
                    </tr>
                </thead>
                <tbody>
                    {crystals.map(crystal => <tr key={crystal.value}>
                        <td><img src={`/assets/items/1000/${crystal.id}.svg`} alt="cristal" /></td>
                        <td className="has-text-centered">{crystal.value}</td>
                        <td className="has-text-centered">{crystal.nbKilled}</td>
                    </tr>)}
                </tbody>
            </Table>
        <Content>
            <br/>
            <p>La formule pour connaître le nombre de points qu'un cristal rapporte est <em>n² * 500</em>, où n est le nombre d'ennemis tués d'un coup.</p>
        </Content>
    </>
}