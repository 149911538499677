export class TechniqueVideo {

    declare name: string;
    declare type: string;
    declare source: string;
}

export class Technique {
    declare name: string;
    declare presentation: string;
    declare description: string;
    declare utility: string;
    declare videos: TechniqueVideo[][]
}