import { Game } from "@hamm4all/shared"
import { ThumbnailCardBox } from "../../../components/ThumbnailCardBox/ThumbnailCardBox";

interface GameThumbnailProps {
    game: Game;
}

export default function GameThumbnail({game}: Readonly<GameThumbnailProps>) {
    return <ThumbnailCardBox
        href={`/games/${game.id}`}
        name={game.name}
        difficulty={game.difficulty ?? undefined}
        thumbnail_url={game.thumbnail_id}
        variable_difficulty={game.has_variable_difficulty}
        level_count={game.level_count}
    />
}