import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { ScreenshotParam } from "../screenshots.params";
import { LevelScreenshot } from "../screenshots.types";

export class ScreenshotGetCall extends APIEndpointGET<ScreenshotParam, {}, LevelScreenshot> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<ScreenshotParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("screenshots")
            .addParam("screenshotOrderNumber")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }

}