import { H4A_API } from "@hamm4all/shared"
import { useState } from "react"
import { FormFieldInput } from "../form/field-components/form-field-input.component"
import ModalForm from "../form/modal-form.component"
import MultiLanguageFormPart from "../form/multi-language-form.component"
import { FormFieldSelect } from "../form/field-components/form-field-select.component"
import useAPIQuery from "../../hooks/use-api-query.hook"
import useAPIMutation from "../../core/api/useAPIMutation"

// Type for 'create' props
interface ItemCreateModalProps {
    type: "create";
    modal: ModalProps;
    gameID: number;
}

// Type for 'update' props
interface ItemUpdateModalProps {
    type: "update";
    modal: ModalProps;
    initialData: {
        id: string;
        names: { [language: string]: string };
        coefficient: number;
        effect?: string;
        inGameID: number;
        value: number;
        gameID: number;
    };
}

// Combined type using discriminated union
type ItemModalProps = ItemCreateModalProps | ItemUpdateModalProps;

// Type for the modal props
interface ModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
}

export default function ItemFormModal(props: Readonly<ItemModalProps>) {
    const [names, setNames] = useState<{ [language: string]: string }>(
        props.type === "create" ? { "fr": "" } : props.initialData.names
    )
    const [coefficient, setCoefficient] = useState<number>(
        props.type === "create" ? 0 : props.initialData.coefficient
    )
    const [effect, setEffect] = useState<string>(
        props.type === "create" ? "" : props.initialData.effect ?? ""
    )
    const [inGameID, setInGameID] = useState<number>(
        props.type === "create" ? 0 : props.initialData.inGameID
    )
    const [value, setValue] = useState<number>(
        props.type === "create" ? 0 : props.initialData.value
    )

    const [gameID, setGameID] = useState<number>(
        props.type === "create" ? props.gameID :
        props.type === "update" ? props.initialData.gameID : 0
    );

    function onSuccess() {
        props.modal.setIsModalOpen(false);
    }

    const addMutation = useAPIMutation({
        "endpoint": H4A_API.v1.items.add,
        "params": {},
        "onSuccess": onSuccess
    })
    const updateMutation = useAPIMutation({
        "endpoint": H4A_API.v1.items.update,
        "params": {
            "itemID": props.type === "update" ? props.initialData.id : 0
        },
        "onSuccess": onSuccess
    })

    function handleSubmit() {
        if (props.type === "create") {
            addMutation.mutate({
                "names": names,
                "coefficient": coefficient,
                "effect": effect,
                "inGameID": inGameID,
                "value": value,
                "gameID": gameID,
            })
        } else if (props.type === "update") {
            updateMutation.mutate({
                "names": names,
                "coefficient": coefficient,
                "effect": effect,
                "inGameID": inGameID,
                "value": value,
                "gameID": gameID
            })
        }
    }

    return (
        <ModalForm
            isOpen={props.modal.isModalOpen}
            setIsOpen={props.modal.setIsModalOpen}
            title="Ajouter un objet"
            handleSubmit={async () => handleSubmit()}
        >
            <fieldset>
                <legend>Noms de l'objet</legend>
                <MultiLanguageFormPart names={names} setNames={setNames} />
            </fieldset>
            <hr/>
            <FormFieldInput
                label="ID dans le jeu"
                value={inGameID}
                changeHandler={setInGameID}
                type="number"
                min={0}
            />

            <FormFieldInput
                label="Coefficient"
                value={coefficient}
                changeHandler={setCoefficient}
                type="number"
                min={0}
                max={8}
            />

            <FormFieldInput
                label="Effet"
                value={effect}
                changeHandler={setEffect}
                type="text"
            />

            <FormFieldInput
                label="Valeur"
                value={value}
                changeHandler={setValue}
                type="number"
                min={0}
            />

            {props.type === "update" &&
                <SelectGameID
                    gameID={gameID}
                    setGameID={setGameID}
                />
            }


        </ModalForm>
    )
}

function SelectGameID(props: Readonly<{ gameID: number, setGameID: (gameID: number) => void }>) {
    const gamesQuery = useAPIQuery({
        "endpoint": H4A_API.v1.games.list,
        "params": {},
        "query": {}
    })

    if (gamesQuery.isLoading) {
        return <></>
    }

    const games = gamesQuery.data

    return (
        <FormFieldSelect
            label="Jeu"
            value={props.gameID}
            changeHandler={newValue => props.setGameID(+newValue)}
            options={games.map((game) => ({
                "label": game.name,
                "value": "" + game.id
            }))}
        />
    )
}