import { useState } from "react";
import { AdderIcon } from "../../../components/adder/adder.component";
import ThumbnailSequenceModal from "../../game-parts/game-part/components/ThumbnailSequenceModal/ThumbnailSequenceModal";
import { GamePartParam } from "@hamm4all/shared";

interface AddSequenceButtonProps extends GamePartParam {

}

export default function AddSequenceButton(props: Readonly<AddSequenceButtonProps>) {
    const [isAddSequenceModalOpen, setIsAddSequenceModalOpen] = useState(false)

    return <>
        <AdderIcon toggleModal={setIsAddSequenceModalOpen} />
        <ThumbnailSequenceModal
            gameID={props.gameID}
            gamePartIndex={props.gamePartIndex}
            isModalOpen={isAddSequenceModalOpen}
            setIsModalOpen={setIsAddSequenceModalOpen}
            type="create"
        />
    </>
}