import H4AImage from "../../layout/image/image.component";
import { H4A_API, UserParam, USERS_PROFILE_PICTURE_FILE_FIELD_NAME } from "@hamm4all/shared";
import { getDisplayedRoleName } from "../../utilities/user/roles.utilities";
import useURLParams from "../../hooks/use-url-params.hook";
import useAPIQuery from "../../hooks/use-api-query.hook";
import { LoadingPage } from "../../components/loading/loading.component";
import { Title } from "../../common/layout/title/Title";
import { useState } from "react";
import ModalFormFileUpload from "../../components/form/modal-form-file-upload.component";
import useAPIMutation from "../../core/api/useAPIMutation";
import { Button } from "react-bulma-components";
import { useAuth } from "../../context/user/user.context";


export default function ProfilePage() {
    const { userID } = useURLParams(UserParam)
    const [isOpen, setIsOpen] = useState(false)
    const {data: user, isLoading} = useAPIQuery({
        "endpoint": H4A_API.v1.user.profile,
        "params": {
            "userID": userID
        },
        "query": {}
    })

    if (isLoading) {
        return <LoadingPage />
    }

    return <>
        <Title>Profil de {user.name}</Title>
        <br/>
        <div className="is-ancestor tile">
            <div className="is-parent tile is-6 is-vertical">
                <article className="tile is-child box">
                    <figure className="image">
                        <H4AImage src={user?.profilePicture} alt={user?.name} />
                    </figure>
                    <Button color={"info"} fullwidth onClick={() => setIsOpen(true)}>Changer la photo de profil</Button>
                </article>
            </div>
            <div className="tile is-parent is-6 is-vertical">
                <article className="box tile is-child">
                    <p>Date d'inscription : {new Date(user?.createdAt).toLocaleDateString("fr-FR")}</p>
                    <br/>
                    <p>Grade : {getDisplayedRoleName(user?.role)}</p>
                </article>
                <article className="box tile is-child">
                    <p>Historique</p>
                    <p>[Coming soon]</p>
                </article>
            </div>
        </div>
        <div className="tile is-ancestor">
            <div className="tile is-parent is-12">
                <article className="box tile is-child">
                    {user?.currentLevel > 0 ? <>
                        <h4 className="subtitle is-4 has-text-centered">Niveau {user?.currentLevel}</h4>
                        <h4 className="subtitle is-7 has-text-centered">
                            {user?.experience - user.experienceToReachCurrentLevel}/{user?.experienceToReachNextLevel - user.experienceToReachCurrentLevel} XP
                        </h4>
                        <progress className="progress is-primary is-large"
                            value={user?.experience - user.experienceToReachCurrentLevel}
                            max={user?.experienceToReachNextLevel - user.experienceToReachCurrentLevel}
                        ></progress>
                    </> : <>
                        <h4 className="subtitle is-4 has-text-centered">Niveau ???</h4>
                        <p>Il y a eu un problème dans le traitement de ton expérience.</p>
                    </>}
                </article>
            </div>
        </div>
        <UploadProfilePictureModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            userID={userID}
        />
    </>
}

interface UploadProfilePictureModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    userID: number;
}

function UploadProfilePictureModal(props: Readonly<UploadProfilePictureModalProps>) {
    const {refreshAuth} = useAuth()
    const uploadProfilePictureMutation = useAPIMutation({
        "endpoint": H4A_API.v1.user.profilePicture.update,
        "params": {
            userID: props.userID
        },
        "onSuccess": () => {
            props.setIsOpen(false)
            refreshAuth()
        }
    })

    return (
        <ModalFormFileUpload
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            title="Ajouter une photo de profil"
            name="profilePicture"
            label="Photo de profil"
            handleSubmit={async (file) => {
                const formData = new FormData()
                formData.append(USERS_PROFILE_PICTURE_FILE_FIELD_NAME, file)
                uploadProfilePictureMutation.mutate(formData)
            }}
        />
    )
}