import { GamePartParam } from "@hamm4all/shared";
import useURLParams from "../../hooks/use-url-params.hook";
import useGamePart from "../game-parts/game-part/hooks/useGamePart";
import useGame from "../games/hooks/use-game.hook";
import { LoadingPage } from "../../components/loading/loading.component";
import { Block } from "react-bulma-components";
import ThumbnailSequencesZone from "./components/ThumbnailSequencesZone";
import GamePartTitle from "./components/GamePartTitle";

export default function GamePartPage() {
    const {gameID, gamePartIndex} = useURLParams(GamePartParam)
    const {game, isLoading: isGameLoading} = useGame(gameID)
    const {gamePart, isLoading: isGamePartLoading} = useGamePart({
        "gameID": gameID,
        "partIndex": gamePartIndex
    })

    if (isGameLoading || isGamePartLoading) {
        return <LoadingPage />
    }

    return <>
        <GamePartTitle game={game} gamePart={gamePart} gamePartIndex={gamePartIndex} />
        <Block>
            <p>{gamePart.description}</p>
        </Block>
        <Block>
            <ThumbnailSequencesZone gameID={gameID} gamePartIndex={gamePartIndex} />
        </Block>
    </>
}