import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { ItemsAddBody } from "./items.add.body";


export class ItemsAddCall extends APIEndpointAction<{}, ItemsAddBody, {}> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder()
            .addPath("items")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<{}, {}>): string {
        return `L'objet a bien été ajouté !`
    }

    public override getErrorMessage(params: APIEndpointErrorParams<{}, ItemsAddBody>): string {
        return `Une erreur s'est produite lors de l'ajout de l'objet : ${params.error.data.message}`
    }
}