import { IsInt, Min } from "class-validator"
import { GamePartParam } from "../game-parts.params"
import { Type } from "class-transformer"


export class ThumbnailSequenceParam extends GamePartParam {
    @IsInt()
    @Type(() => Number)
    @Min(1)
    declare sequenceIndex: number
}