import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { Item } from "../items.types";
import { ItemsListQuery } from "./items.list.query";

export class ItemsListCall extends APIEndpointGET<{}, ItemsListQuery, Array<Item>> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder()
            .addPath("items")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}