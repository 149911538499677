import { LoginCall } from "./routes/auth/login/login.call";
import { SignupCall } from "./routes/auth/signup/signup.call";
import { AuthorsListCall } from "./routes/authors/list/authors.list.call";
import { AddChangelogCall } from "./routes/changelog/add/changelog.add.call";
import { ChangelogListCall } from "./routes/changelog/list/changelog.list.call";
import { FamiliesListCall } from "./routes/families/list/families.list.call";
import { GamePartsAddCall } from "./routes/games/parts/add/game-parts.add.call";
import { GamePartsGetCall } from "./routes/games/parts/get/game-parts.get.call";
import { GamePartsListCall } from "./routes/games/parts/list/game-parts.list.call";
import { GamesAddCall } from "./routes/games/add/games.add.call";
import { GamesGetCall } from "./routes/games/get/game.get.call";
import { GameGradesAddCall } from "./routes/games/grade/add/games.grade.add.call";
import { GamesLevelsListCall } from "./routes/games/levels/games.levels.list.call";
import { GamesListCall } from "./routes/games/list/game.list.call";
import { GamesThumbnailUpdateCall } from "./routes/games/thumbnail/games.thumbnail.update.call";
import { GamesUpdateCall } from "./routes/games/update/games.update.call";
import { ItemsAddCall } from "./routes/items/add/items.add.call";
import { ItemsDeleteCall } from "./routes/items/delete/items.delete.call";
import { ItemsGetCall } from "./routes/items/get/items.get.call";
import { ItemsListCall } from "./routes/items/list/items.list.call";
import { ItemsPictureUpdateCall } from "./routes/items/picture/items.picture.update.call";
import { ItemsUpdateCall } from "./routes/items/update/items.update.call";
import { LandsAddCall } from "./routes/games/lands/add/lands.add.call";
import { LandsGetCall } from "./routes/games/lands/get/lands.get.call";
import { LandsListCall } from "./routes/games/lands/list/lands.list.call";
import { LanguagesListCall } from "./routes/languages/list/languages.list.call";
import { LevelsAddCall } from "./routes/levels/add/levels.add.call";
import { LevelsDescriptionAddCall } from "./routes/levels/description/add/description.add.call";
import { LevelsGetCall } from "./routes/levels/get/level.get.call";
import { LevelsRandomCall } from "./routes/levels/random/levels.random.call";
import { ScreenshotGetCall } from "./routes/levels/screenshots/get/screenshots.get.call";
import { LevelTransitionsListCall } from "./routes/levels/transitions/classic/list/level-transitions.list.call";
import { LevelTransitionsUpdateCall } from "./routes/levels/transitions/classic/update/level-transitions.update.call";
import { LevelTransitionsLabyListCall } from "./routes/levels/transitions/laby/list/level-transitions.list.call";
import { LevelTransitionsLabyUpdateCall } from "./routes/levels/transitions/laby/update/level-transitions.update.call";
import { LevelsUpdateCall } from "./routes/levels/update/levels.update.call";
import { QuestsListCall } from "./routes/quests/list/quests.list.call";
import { TechniquesCall } from "./routes/techniques/techniques.call";
import { UsersListCall } from "./routes/users/list/list.call";
import { UsersProfileCall } from "./routes/users/profile/profile.call";
import { UsersSessionCall } from "./routes/users/session/session.call";
import { LevelsVideoListCall } from "./routes/levels/videos/list/videos.list.call";
import { LevelsVideoAddCall } from "./routes/levels/videos/add/videos.add.call";
import { LevelsVideoUpdateCall } from "./routes/levels/videos/update/videos.update.call";
import { LevelsVideoDeleteCall } from "./routes/levels/videos/delete/videos.delete.call";
import { LevelsVideoGetCall } from "./routes/levels/videos/get/videos.get.call";
import { ScreenshotListCall } from "./routes/levels/screenshots/list/screenshots.list.call";
import { ScreenshotAddCall } from "./routes/levels/screenshots/upload/add/screenshots.upload.add.call";
import { LevelsScreenshotUpdateCall } from "./routes/levels/screenshots/upload/update/screenshots.upload.update.call";
import { LevelsScreenshotDeleteCall } from "./routes/levels/screenshots/delete/screenshots.delete.call";
import { LevelsScreenshotOrderUpdateCall } from "./routes/levels/screenshots/order/screenshots.order.update";
import { ThumbnailSequenceAddCall } from "./routes/games/parts/thumbnail-sequences/add/thumbnail-sequences.add.call";
import { ThumbnailSequenceListCall } from "./routes/games/parts/thumbnail-sequences/list/thumbnail-sequences.list.call";
import { ThumbnailSequenceUpdateCall } from "./routes/games/parts/thumbnail-sequences/update/thumbnail-sequences.update.call";
import { ThumbnailSequenceOrderUpdateCall } from "./routes/games/parts/thumbnail-sequences/order/update/thumbnail-sequences.order.call";
import { ThumbnailLinkAddCall } from "./routes/games/parts/thumbnail-sequences/thumbnail-links/add/thumbnail-links.add.call";
import { ThumbnailLinkDeleteCall } from "./routes/games/parts/thumbnail-sequences/thumbnail-links/delete/thumbnail-links.delete.call";
import { ThumbnailLinkListCall } from "./routes/games/parts/thumbnail-sequences/thumbnail-links/list/thumbnail-links.list.call";
import { ThumbnailLinkUpdateCall } from "./routes/games/parts/thumbnail-sequences/thumbnail-links/update/thumbnail-links.update.call";
import { LevelsDescriptionListCall } from "./routes/levels/description/list/description.list.call";
import { FruitOrderUpdateCall } from "./routes/levels/screenshots/fruit-order/update/fruit-order.update.call";
import { FruitOrderListCall } from "./routes/levels/screenshots/fruit-order/list/fruit-order.list.call";
import { FileGetCall } from "./routes/files/get/files.get.call";
import { FileGetRawCall } from "./routes/files/get-raw/files.get-raw.call";
import { GamePartsUpdateCall } from "./routes/games/parts/update/game-parts.update.call";
import { ThumbnailSequencesDeleteCall } from "./routes/games/parts/thumbnail-sequences/delete/thumbnail-sequences.delete.call";
import { LogoutCall } from "./routes/auth/logout/logout.call";
import { UsersProfilePictureUpdateCall } from "./routes/users/profile-picture/update/users.profile-picture.call";


export function parseH4AUrl(url: string, params: object) {
    let parsedUrl = url.toString();
    for (const [key, value] of Object.entries(params)) {
        parsedUrl = parsedUrl.replace(`:${key}`, value.toString());
    }
    return parsedUrl;
}

export const H4A_API = {
    v1: {
        techniques: new TechniquesCall(),
        user: {
            profile: new UsersProfileCall(),
            session: new UsersSessionCall(),
            list: new UsersListCall(),
            profilePicture: {
                update: new UsersProfilePictureUpdateCall()
            }
        },
        auth: {
            login: new LoginCall(),
            signup: new SignupCall(),
            logout: new LogoutCall()
        },
        authors: {
            list: new AuthorsListCall()
        },
        changelog: {
            list: new ChangelogListCall(),
            add: new AddChangelogCall(),
        },
        quests: {
            list: new QuestsListCall()
        },
        files: {
            get: new FileGetCall(),
            getRaw: new FileGetRawCall()
        },
        families: {
            list: new FamiliesListCall()
        },
        items: {
            list: new ItemsListCall(),
            get: new ItemsGetCall(),
            add: new ItemsAddCall(),
            update: new ItemsUpdateCall(),
            delete: new ItemsDeleteCall(),
            picture: {
                update: new ItemsPictureUpdateCall()
            }
        },
        games: {
            add: new GamesAddCall(),
            list: new GamesListCall(),
            get: new GamesGetCall(),
            update: new GamesUpdateCall(),
            thumbnail: {
                update: new GamesThumbnailUpdateCall()
            },
            levels: new GamesLevelsListCall(),
            grades: {
                add: new GameGradesAddCall()
            }
        },
        languages: {
            list: new LanguagesListCall()
        },
        levels: {
            add: new LevelsAddCall(),
            get: new LevelsGetCall(),
            update: new LevelsUpdateCall(),
            description: {
                list: new LevelsDescriptionListCall(),
                add: new LevelsDescriptionAddCall(),
            },
            random: new LevelsRandomCall(),
            screenshots: {
                get: new ScreenshotGetCall(),
                list: new ScreenshotListCall(),
                add: new ScreenshotAddCall(),
                update: new LevelsScreenshotUpdateCall(),
                delete: new LevelsScreenshotDeleteCall(),
                order: {
                    update: new LevelsScreenshotOrderUpdateCall()
                },
                fruitOrder: {
                    list: new FruitOrderListCall(),
                    update: new FruitOrderUpdateCall()
                }
            },
            transitions: {
                classic: {
                    list: new LevelTransitionsListCall(),
                    update: new LevelTransitionsUpdateCall(),
                },
                laby: {
                    list: new LevelTransitionsLabyListCall(),
                    update: new LevelTransitionsLabyUpdateCall(),
                }
            },
            videos: {
                list: new LevelsVideoListCall(),
                add: new LevelsVideoAddCall(),
                update: new LevelsVideoUpdateCall(),
                delete: new LevelsVideoDeleteCall(),
                get: new LevelsVideoGetCall()
            }
        },
        lands: {
            add: new LandsAddCall(),
            list: new LandsListCall(),
            get: new LandsGetCall()
        },
        gameParts: {
            list: new GamePartsListCall(),
            add: new GamePartsAddCall(),
            update: new GamePartsUpdateCall(),
            get: new GamePartsGetCall()
        },
        thumbnailSequences: {
            add: new ThumbnailSequenceAddCall(),
            list: new ThumbnailSequenceListCall(),
            update: new ThumbnailSequenceUpdateCall(),
            order: {
                update: new ThumbnailSequenceOrderUpdateCall()
            },
            delete: new ThumbnailSequencesDeleteCall(),
            links: {
                add: new ThumbnailLinkAddCall(),
                delete: new ThumbnailLinkDeleteCall(),
                list: new ThumbnailLinkListCall(),
                update: new ThumbnailLinkUpdateCall()
            }
        }
    }
}