import { HTTPMethod } from "../../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../../api.endpoint";
import { H4ARole } from "../../../../../api.roles";
import { ThumbnailSequenceParam } from "../../thumbnail-sequences.params";
import { ThumbnailLinkAddBody } from "./thumbnail-links.add.body";

export class ThumbnailLinkAddCall extends APIEndpointAction<ThumbnailSequenceParam, ThumbnailLinkAddBody, {}> {

    protected getVersion(): APIVersion {
        return 1
    }
    
    protected getUriBuilder(): URIBuilder<ThumbnailSequenceParam> {
        return new URIBuilder<ThumbnailSequenceParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
            .addParam("sequenceIndex")
            .addPath("thumbnail-links")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ThumbnailSequenceParam, ThumbnailLinkAddBody>): string {
        return `Une erreur est survenue lors de l'ajout du lien d'image : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ThumbnailSequenceParam, ThumbnailLinkAddBody, {}>): string {
        return `Le lien d'image a bien été ajouté !`
    }
}