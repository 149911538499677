import { HTTPMethod } from "../../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../../api.endpoint";
import { H4ARole } from "../../../../../api.roles";
import { ThumbnailLinkParam } from "../thumbnail-links.params";
import { ThumbnailLinkUpdateBody } from "./thumbnail-links.update.body";

export class ThumbnailLinkUpdateCall extends APIEndpointAction<ThumbnailLinkParam, ThumbnailLinkUpdateBody, {}> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<ThumbnailLinkParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
            .addParam("sequenceIndex")
            .addPath("thumbnail-links")
            .addParam("thumbnailLinkIndex")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getQueryKeysToInvalidate(params: ThumbnailLinkParam): (string | object)[][] {
        return [
            ["games", params.gameID.toString(), "parts", params.gamePartIndex.toString(), "thumbnail-sequences", params.sequenceIndex.toString()]
        ]
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ThumbnailLinkParam, ThumbnailLinkUpdateBody>): string {
        return `Une erreur est survenue lors de la mise à jour du lien d'image : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ThumbnailLinkParam, ThumbnailLinkUpdateBody, {}>): string {
        return `Le lien d'image a bien été mis à jour !`
    }
}