import { H4A_API, LevelsParam } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseUpdateLevelProps extends LevelsParam {
    closePopup: () => void;
}

export default function useUpdateLevel(props: Readonly<UseUpdateLevelProps>) {
    const updateMutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.update,
        "params": props,
        "onSuccess": () => props.closePopup()
    })

    return updateMutation
}