export function getDisplayedRoleName(grade: number) {
    switch (grade) {
        case 1:
            return "Utilisateur";
        case 2:
            return "VIP";
        case 3:
            return "Modérateur";
        case 4:
            return "Administrateur";
        case 5:
            return "Créateur";
        default:
            return "Utilisateur";
    }
}