import { GameParam } from "@hamm4all/shared";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { LoadingPage } from "../../components/loading/loading.component";
import { CardZoneWithoutAdder } from "../../components/card-zone/card-zone.component";
import useURLParams from "../../hooks/use-url-params.hook";
import useGame from "../games/hooks/use-game.hook";
import GameTitle from "./components/GameTitle";
import GameIdentityCard from "./components/GameIdentityCard";
import { ThumbnailCardBox } from "../../components/ThumbnailCardBox/ThumbnailCardBox";


export default function GamePage() {
    const {gameID} = useURLParams(GameParam)
    const {game, isLoading: gameIsLoading} = useGame(gameID)

    if (gameIsLoading) {
        return <LoadingPage />
    }

    return <>
        <GameTitle game={game} />
        <br />
        <GameIdentityCard game={game} />
        <br />
        <div className="box is-block">
            <Subtitle centered>Description</Subtitle>
            <br/>
            {game.description}
        </div>
        <br />

        <CardZoneWithoutAdder>
            <ThumbnailCardBox
                href={`/games/${game.id}/parts`}
                name={"Niveaux"}
                thumbnail_url={game.thumbnail_id}
            />
            <ThumbnailCardBox
                href={`/games/${game.id}/lands`}
                name={"Contrées"}
                thumbnail_url={game.thumbnail_id}
            />
            <ThumbnailCardBox
                href={`/games/${game.id}/items`}
                name={"Objets"}
                thumbnail_url={game.thumbnail_id}
            />
        </CardZoneWithoutAdder>
    </>
}
