import { Game, H4A_API } from "@hamm4all/shared";
import { Title, TitleWithEdit } from "../../../common/layout/title/Title";
import { useAuth } from "../../../context/user/user.context";
import { useState } from "react";
import GameModalForm from "../../games/game-modal-form.component";

interface GameTitleProps {
    game: Game
}

export default function GameTitle(props: Readonly<GameTitleProps>) {
    const {hasRole} = useAuth()
    return hasRole(H4A_API.v1.games.update.getRole()) ?
        <GameTitleWithEdit {...props} /> :
        <GameTitleWithoutEdit {...props} />
}

function GameTitleWithEdit(props: Readonly<GameTitleProps>) {
    const [updateGameModalOpen, setUpdateGameModalOpen] = useState(false)
    return <>
        <TitleWithEdit toggleModal={() => setUpdateGameModalOpen(true)}>
            {props.game.name}
        </TitleWithEdit>
        <GameModalForm
            type="edit"
            modalInteraction={{
                "isOpen": updateGameModalOpen,
                "setIsOpen": setUpdateGameModalOpen
            }}
            gameData={props.game}
        />
    </>
}

function GameTitleWithoutEdit(props: Readonly<GameTitleProps>) {
    return <Title>
        {props.game.name}
    </Title>
}