import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { ItemParam } from "../items.params";


export class ItemsDeleteCall extends APIEndpointAction<ItemParam, {}, {}> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<ItemParam> {
        return new URIBuilder<ItemParam>()
            .addPath("items")
            .addParam("itemID")
    }

    public getMethod(): HTTPMethod {
        return "DELETE"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ItemParam, {}>): string {
        return `Une erreur s'est produite lors de la suppression de l'objet : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ItemParam, {}>): string {
        return `L'objet a bien été supprimé.`
    }
}