import { GameParam, LevelTransitionClassic } from "@hamm4all/shared"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

interface PreviousTransitionsProps extends GameParam {
    previousTransitions: LevelTransitionClassic[]
}

export default function PreviousTransitions({gameID, previousTransitions}: Readonly<PreviousTransitionsProps>) {
    const navigate = useNavigate()
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft" && previousTransitions.length > 0) {
                navigate(`/games/${gameID}/levels/${previousTransitions[0].level.name}`)
            }
        }

        document.addEventListener("keydown", handleKeyDown)

        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    
    })

    return (
        <div className="column">
            {previousTransitions.length > 0 ?
                previousTransitions.map((transition) => {
                    return <div key={transition.level.id + transition.type} className="has-text-right">
                        <Link to={`/games/${gameID}/levels/${transition.level.name}`}>
                            &lt;&lt;&lt; Niveau {transition.level.name}
                        </Link>
                    </div>
                })
                : <p className="has-text-centered">Aucun niveau précédent</p>
            }
        </div>
    )
}