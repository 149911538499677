import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { LevelsVideoParam } from "../videos.params";
import { LevelsVideo } from "../videos.types";

export class LevelsVideoGetCall extends APIEndpointGET<LevelsVideoParam, {}, LevelsVideo> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsVideoParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("videos")
            .addParam("videoIndex")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}