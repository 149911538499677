import { useState } from "react";
import { FormFieldInput, FormFieldTextArea } from "../../../components/form/field-components/form-field-input.component";
import ModalForm from "../../../components/form/modal-form.component";
import useSaveChangelog from "../hooks/useSaveChangelog";

interface ChangelogAddModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
}

export default function ChangelogAddModal(props: Readonly<ChangelogAddModalProps>) {
    const [changelogAddFormName, setChangelogAddFormName] = useState("");
    const [changelogAddFormChanges, setChangelogAddFormChanges] = useState("");

    const addChangelogMutation = useSaveChangelog({
        "closeModal": () => props.setIsModalOpen(false),
    })

    return (
        <ModalForm
            isOpen={props.isModalOpen}
            setIsOpen={props.setIsModalOpen}
            title="Ajouter une version"
            handleSubmit={async () => addChangelogMutation.mutate({
                "versionName": changelogAddFormName,
                "description": changelogAddFormChanges
            })}
        >
            <FormFieldInput
                label="Nom de la version"
                value={changelogAddFormName}
                changeHandler={setChangelogAddFormName}
                required
                placeholder="Nom de la version"
                maxLength={50}
            />

            <FormFieldTextArea
                label="Changements apportés"
                value={changelogAddFormChanges}
                changeHandler={setChangelogAddFormChanges}
                required
                placeholder="Changements apportés"
                rows={10}
                maxLength={10_000}
            />
        </ModalForm>
    )
}