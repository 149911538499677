import { H4A_API } from "@hamm4all/shared"
import useAPIMutation from "../../../core/api/useAPIMutation"

interface UseUpdateGamePartProps {
    gameID: number;
    gamePartIndex: number;
    onClose: () => void
}

export default function useUpdateGamePart(props: UseUpdateGamePartProps) {
    const mutation = useAPIMutation({
        "endpoint": H4A_API.v1.gameParts.update,
        "params": props,
        "onSuccess": props.onClose
    })

    return mutation
}