import { IsInt, IsOptional, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

export class LevelTransitionLabyUpdateBody {
    @IsInt()
    declare targetID: number;
}

export class LevelTransitionsLabyUpdateBody {

    @ValidateNested()
    @Type(() => LevelTransitionLabyUpdateBody)
    @IsOptional()
    declare up?: LevelTransitionLabyUpdateBody;

    @ValidateNested()
    @Type(() => LevelTransitionLabyUpdateBody)
    @IsOptional()
    declare right?: LevelTransitionLabyUpdateBody;

    @ValidateNested()
    @Type(() => LevelTransitionLabyUpdateBody)
    @IsOptional()
    declare left?: LevelTransitionLabyUpdateBody;

    @ValidateNested()
    @Type(() => LevelTransitionLabyUpdateBody)
    @IsOptional()
    declare down?: LevelTransitionLabyUpdateBody;
}