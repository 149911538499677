import { Link } from "react-router-dom";
import { Title } from "../../common/layout/title/Title";

export default function Home() {
    return <>
        <Title>Hamm4all</Title>
        <br />
        <div className="content">
            <p>Voici le nouveau et dernier site d'aide Hammerfest encore mis à jour régulièrement ! Ce site inclut :</p>
            <ul>
                <li>Tous les ordres et niveaux d'Hammerfest</li>
                <li>Différentes pages d'aides pour les nouveaux joueurs</li>
                <li>Toutes les contrées Eternalfest au fur et à mesure de leur parution et de l'avancée de la communauté</li>
            </ul>
            <p>Ce site est complètement gratuit et open-source, le code est disponible <a href="https://gitlab.com/BlessedRaccoon/hamm4all">ici</a>.<br/>
            Egalement, vous pouvez contribuer au site (ajouter des screens, des descriptions de niveaux etc...) en vous créant un compte gratuitement !</p>
            <p>Merci à tous ceux qui m'ont aidé à améliorer le site avec des critiques constructives !</p>
        </div>

        <br />
        <Link to="changelog" className="button is-info">Voir l'historique des versions</Link>
    </>
}