import { useState } from "react";
import ModalForm from "./modal-form.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

interface ModalFormFileUploadProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    handleSubmit: (file: File) => Promise<any>;
    title: string;
    name: string;
    label: string;
}

export default function ModalFormFileUpload(props: Readonly<ModalFormFileUploadProps>) {

    const [file, setFile] = useState<File | null>(null)

    return (
        <ModalForm
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            handleSubmit={() => {
                if (file) {
                    return props.handleSubmit(file)
                } else {
                    return Promise.reject(new Error("Aucune image n'a été sélectionnée"))
                }
            }}
            title={props.title}
        >
            <div className="file has-name is-fullwidth is-info">
                <label className="file-label">
                    <input className="file-input" type="file" name={props.name} required onChange={e => setFile(e.target.files?.[0] ?? null)} />
                    <span className="file-cta">
                    <span className="file-icon">
                        <FontAwesomeIcon icon={faUpload} />
                    </span>
                    <span className="file-label">
                        {props.label}
                    </span>
                    </span>
                    <span className="file-name">
                        {file?.name}
                    </span>
                </label>
            </div>
        </ModalForm>
    )
}