import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { Level } from "../levels.types";
import { LevelsAddBody } from "./levels.add.body";


export class LevelsAddCall extends APIEndpointAction<{}, LevelsAddBody, Level> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder()
            .addPath("levels")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }

    public override getErrorMessage(params: APIEndpointErrorParams<{}, LevelsAddBody>): string {
        return `Une erreur s'est produite lors de l'ajout du niveau ${params.body.name} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<{}, LevelsAddBody, Level>): string {
        return `Le niveau ${params.response.data.name} a bien été ajouté !`
    }
}