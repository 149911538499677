import { Transform } from "class-transformer";
import { IsNumber, IsOptional, IsString, Max, Min } from "class-validator";
import sanitizeHtml from "sanitize-html"

export class ItemsAddBody {

    declare names: {
        [key: string]: string;
    }

    @IsNumber()
    @Min(0)
    @Max(8)
    declare coefficient: number;

    @IsNumber()
    declare inGameID: number;

    @IsNumber()
    declare value: number;

    @IsString()
    @IsOptional()
    @Transform((variable) => sanitizeHtml(variable.value))
    declare effect?: string;

    @IsNumber()
    declare gameID: number;
}