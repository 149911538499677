import { Link } from "react-router-dom";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";
import useAPIQuery from "../../hooks/use-api-query.hook";
import { H4A_API } from "@hamm4all/shared";
import { LoadingPage } from "../../components/loading/loading.component";

export default function UsersPage() {
    const usersQuery = useAPIQuery({
        "endpoint": H4A_API.v1.user.list,
        "params": {},
        "query": {}
    })

    if (usersQuery.isLoading) {
        return <LoadingPage />
    }

    const users = usersQuery.data;

    return <>
        <Title>Profils d'Hamm4all</Title>
        <br/>
        <div className="columns tile is-ancestor is-multiline">
            {users.map((user) => {
                return <div className="column is-4" key={user.id}>
                    <div className="tile is-parent">
                        <div className="tile is-child box">
                            <Subtitle centered>{user.name}</Subtitle>
                            <p>
                                id : {user.id}
                                <br/>
                                créé le : {(new Date(user.createdAt)).toLocaleDateString('fr-FR')}
                                <br/>
                                Niveau : {user.currentLevel}
                                <br/>
                                rôle : {user.role}
                            </p>
                            <br/>
                            <Link to={`/users/${user.id}`}>Compte</Link>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>
}