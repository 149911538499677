import { Content } from "react-bulma-components";
import { Title } from "../../common/layout/title/Title";
import { Table } from "../../layout/table/table.component";
import letters from "./letters.json"

export default function LettersRules() {

    return <>
        <Title>Les Lettres</Title>
        <Content>
            <p>Dans Hammerfest, comme il l'est précisé dans l'Apprentissage, il est possible de récupérer les lettres du mot CRISTAL. Ces lettres, une fois assemblées, font disparaître tous les fruits du niveaux et donnent une vie supplémentaire.</p>
            <p>Les lettres n'apparaissent que lorsqu'il y a des ennemis dans le niveau. Il faut donc mieux attendre dans un niveau si on le peut, pour récupérer les lettres.</p>
            <p>Cependant, les lettres ont une règle de génération : en effet, le nombre de lettres différentes dans un niveau équivaut au <em>big bang*</em> maximal fait au niveau précédent. Cette règle est à prendre en considération pour les parties "Enfers" par exemple, où le nombre de vies est vital : il vaut donc parfois mieux faire un big bang 6 au niveau 16 et ne pas prendre les dimensions, car on aura le choix parmi six lettres au niveau 17 !</p>
            <p>Egalement, il faut noter que chaque lettre prise donne 5 points, que faire CRISTAL en prenant les lettres dans l'ordre donne un bonus généreux de 150,000 points, et que pour rendre les choses encore plus difficiles, les lettres ont des probabilités plus ou moins grandes d'apparaître :</p>
        </Content>
        <Table>
            <thead>
                <tr>
                    <td>Lettre</td>
                    <td>Probabilité</td>
                </tr>
            </thead>
            <tbody>
                {letters.map((letter, index) => <tr key={letter.id}>
                    <td><img src={`/assets/items/0/${index}.svg`} alt={letter.id.toString()} /></td>
                    <td className="has-text-centered">{letter.percentage}%</td>
                </tr>)}
            </tbody>
        </Table>
        <br/>
        <Content>
            <p>Comme on peut le constater, la lettre L est plus de deux fois plus rare que les lettres C, R, et A : ce qui démontre le degré d'importance de certaines lettres par rapport à d'autres</p>
            <br/>
            <p><em>big bang*</em> : Nombre d'ennemis tués en une seule bombe</p>
        </Content>
    </>
}