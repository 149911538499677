import { APIEndpointGET, APIVersion, URIBuilder } from "../../api.endpoint";
import { H4ARole } from "../api.roles";
import { Technique } from "./techniques.types";

export class TechniquesCall extends APIEndpointGET<{}, {}, Technique[]> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("techniques")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}