import { Game, GamePart, H4A_API } from "@hamm4all/shared";
import { Title, TitleWithEdit } from "../../../common/layout/title/Title";
import { useAuth } from "../../../context/user/user.context";
import GamePartModal from "../../GameParts/components/AddGamePartModal/GamePartModal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Subtitle } from "../../../common/layout/title/Subtitle";

interface GamePartTitleProps {
    game: Game;
    gamePart: GamePart;
    gamePartIndex: number;
}

export default function GamePartTitle(props: Readonly<GamePartTitleProps>) {
    const {hasRole} = useAuth();
    return <>
        {hasRole(H4A_API.v1.gameParts.update.getRole()) ?
            <GamePartTitleWithEdit {...props} /> :
            <GamePartTitleWithoutEdit {...props} />
        }
        <Subtitle centered>
            <Link to={`/games/${props.game.id}/parts`}>Retour</Link>
        </Subtitle>
    </>
}

function GamePartTitleWithEdit({game, gamePart, gamePartIndex}: Readonly<GamePartTitleProps>) {
    const [isEditGamePartModalOpen, setIsEditGamePartModalOpen] = useState(false)

    return <>
        <TitleWithEdit toggleModal={() => setIsEditGamePartModalOpen(true)}>
            {game.name} - {gamePart.name}
        </TitleWithEdit>
        <GamePartModal
            gameID={game.id}
            initialData={{
                "name": gamePart.name,
                "description": gamePart.description,
                "partIndex": gamePartIndex,
            }}
            isOpen={isEditGamePartModalOpen}
            setIsOpen={setIsEditGamePartModalOpen}
            type="update"
        />
    </>
}

function GamePartTitleWithoutEdit({game, gamePart}: Readonly<GamePartTitleProps>) {
    return <Title>{game.name} - {gamePart.name}</Title>
}