import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../hooks/use-api-query.hook";

export default function useGame(gameID: number) {
    const gameQuery = useAPIQuery({
        "endpoint": H4A_API.v1.games.get,
        "params": {
            "gameID": gameID
        },
        "query": {}
    })

    return {
        game: gameQuery.data,
        ...gameQuery
    }
}