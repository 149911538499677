import { IsBoolean, IsInt, IsNotEmpty, IsUrl, Matches, Min } from "class-validator";

export class LevelsVideoAddBody {
    
    @IsNotEmpty()
    @IsUrl()
    // Youtube URL
    @Matches(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/)
    declare link: string;

    @IsBoolean()
    declare hasOrder: boolean;

    @IsInt()
    @Min(0)
    declare livesLost: number;
}