import { APIEndpointGET, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { UserProfile } from "../users.types";

export class UsersSessionCall extends APIEndpointGET<{}, {}, UserProfile> {
    protected getUriBuilder() {
        return new URIBuilder()
            .addPath("user-session")
    }

    protected getVersion(): 1 {
        return 1;
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override isSilent(): boolean {
        return true
    }
}