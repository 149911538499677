import { ToastType, toast } from "bulma-toast";

interface NotificationProps {
    message: string | string[];
    type: "success" | "error" | "warning";
}

export const NOTIFICATION_CLASS = "custom-notification"

export function createNotification(props: NotificationProps) {

    let toastType: ToastType;
    switch (props.type) {
        case "success":
            toastType = "is-success";
            break;
        case "error":
            toastType = "is-danger";
            break;
        case "warning":
            toastType = "is-warning";
            break;
        default:
            toastType = "is-info";
            break;
    }

    if (typeof props.message !== "string") {
        if (props.message instanceof Array) {
            props.message = props.message.join("\n");
        }
        else {
            props.message = `Le texte de cette notification n'a pas pu être affiché.`
        }   
    }

    // Here we need to sanitize the message, because it can contain HTML tags
    // Since the notification of bulma-toast renders HTML by default and on purpose,
    // there is a risk of XSS attacks
    const sanitizedMessage = props.message.replace(/(<([^>]+)>)/gi, "");

    toast({
        message: sanitizedMessage,
        type: toastType,
        closeOnClick: true,
        position: "top-right",
        duration: 10_000,
        extraClasses: `${NOTIFICATION_CLASS} ${props.type}`
    })
}