import { APIEndpointGET, URIBuilder, APIVersion } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { ScreenshotParam } from "../../screenshots.params";
import { FruitOrder } from "../fruit-order.types";

export class FruitOrderListCall extends APIEndpointGET<ScreenshotParam, {}, FruitOrder[]> {
    protected getUriBuilder(): URIBuilder<ScreenshotParam> {
        return new URIBuilder<ScreenshotParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("screenshots")
            .addParam("screenshotOrderNumber")
            .addPath("fruit-order");
    }

    protected getVersion(): APIVersion {
        return 1;
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}