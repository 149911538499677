import { Content } from "react-bulma-components";
import { Link } from "react-router-dom";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";
import image from "./cache-ennemi.png"

export default function CacheEnnemiRule() {
    return <>
        <Title>Le Cache-Ennemi</Title>
        <Content>
            <p>La technique du cache-ennemi (ou plutôt, le théorème du cache-ennemi) est une fonctionnalité du jeu découverte peu après la sortie du jeu par <Link to="http://www.hammerfest.fr/user.html/6283">Kahakatashi</Link>. Elle sert à déterminer de manière certaine l'ordre d'un niveau.</p>
            <p>Le théorème est le suivant :</p>
            <Subtitle centered>Si deux ennemis se croisent, celui qui passe derrière l'autre est à tuer avant l'autre dans l'ordre.</Subtitle>
            <p>Cela veut dire que si on arrive à faire croiser la plupart des ennemis d'un niveau, on peut en déduire l'ordre du niveau. Ce théorème est donc fondamental quand on commence à jouer les points : si on n'arrive plus à s'y retrouver au milieu de l'ordre, il suffit de faire croiser les ennemis pour déduire l'ordre totalement.</p>
            <img src={image} alt="cache-ennemi" style={{marginLeft: "45%"}} loading="lazy" />
            <br/>
            <p>ATTENTION : Il y a cependant une exception à la règle : un litchi qui vient à perdre sa carapace passera <strong>derrière</strong> l'autre, alors qu'en vérité, casser la carapace d'un litchi détruit l'ordre en le rendant impossible. Il existe néanmoins un <Link to="/rules/bugs">bugfix</Link> dans Eternalfest qui corrige le problème, mais il n'est pas forcément actif sur chaque contrée.</p>
        </Content>
    </>

}