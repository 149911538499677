import { useNavigate } from "react-router-dom"
import useAPIMutation from "../../../core/api/useAPIMutation"
import { H4A_API } from "@hamm4all/shared"

export default function RandomLevelButton() {
    const redirect = useNavigate()
    const randomLevelMutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.random,
        "params": {},
        "onSuccess": ({data}) => {
            redirect(`/games/${data.gameID}/levels/${encodeURIComponent(data.level.name)}`)
        }
    })

    return (
        <button
            className="button is-info is-fullwidth"
            onClick={() => randomLevelMutation.mutate({})}
        >
            Niveau Aléatoire
        </button>
    )
}