import { IsInt, Min } from "class-validator";
import { ThumbnailSequenceParam } from "../thumbnail-sequences.params";
import { Type } from "class-transformer";


export class ThumbnailLinkParam extends ThumbnailSequenceParam {
    @IsInt()
    @Type(() => Number)
    @Min(1)
    declare thumbnailLinkIndex: number
}