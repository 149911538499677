import { ReducedLevel } from "@hamm4all/shared";
import { Link } from "react-router-dom";
import H4AImage from "../../../layout/image/image.component";
import styles from "./LevelThumbnail.module.css"
import { useState } from "react";

export interface LevelThumbnailProps {
    gameID: number;
    level: ReducedLevel;
    width?: string;
    controlButtons?: React.ReactNode
}

export default function LevelThumbnail({gameID, level, width, controlButtons}: Readonly<LevelThumbnailProps>) {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div style={{"position": "relative"}} onMouseEnter={() => setIsHovered(true)} onMouseLeave={(e) => {
            e.stopPropagation()
            setIsHovered(false)
        }}>
            <Link to={`/games/${gameID}/levels/${encodeURIComponent(level.name)}`} style={{"maxWidth": width, "minWidth": width}}>
                <H4AImage src={level.mainScreenshotID} alt={level.name} title={level.name} className={styles["level-thumbnail"]} width={width} />
            </Link>

            {isHovered && controlButtons}
        </div>
    )
}