import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint"
import { H4ARole } from "../../../api.roles"
import { GameParam } from "../../games.params"
import { GamePart } from "../game-parts.types"

export class GamePartsListCall extends APIEndpointGET<GameParam, {}, Array<GamePart>> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")

    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}