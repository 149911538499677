import { Link } from "react-router-dom";
import { GameParam } from "@hamm4all/shared";
import useURLParams from "../../hooks/use-url-params.hook";
import LevelsBulkWarp from "./components/LevelsBulkWarp/LevelsBulkWarp";
import GamePartsCardZone from "./components/GamePartsCardZone/GamePartsCardZone";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";
import useGame from "../games/hooks/use-game.hook";
import { LoadingPage } from "../../components/loading/loading.component";

export default function GamePartsPage() {
    const {gameID} = useURLParams(GameParam);
    const {game, isLoading} = useGame(gameID);
    if (isLoading) return <LoadingPage />

    return <>
        <Title>Niveaux</Title>
        <Subtitle centered>
            <Link to={`/games/${gameID}`}>Retour au jeu</Link>
        </Subtitle>
    
        <br />
        <LevelsBulkWarp game={game} />
        <br/>
        <Subtitle centered>Parties de jeu</Subtitle>
        <GamePartsCardZone game={game} />
    </>

}