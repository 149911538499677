import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ModalForm from "../../../../components/form/modal-form.component";
import { GamePartParam, ThumbnailSequence, ThumbnailSequenceParam } from "@hamm4all/shared";
import { useState } from "react";
import useSaveThumbnailSequenceOrder from "../../../game-parts/game-part/hooks/sequences/useSaveThumbnailSequenceOrder";
import useThumbnailLinks from "../../../game-parts/game-part/hooks/links/useThumbnailLinks";
import useThumbnailSequenceTitle from "../../hooks/useThumbnailSequenceTitle";

interface ThumbnailSequencesOrderModalProps extends GamePartParam {
    sequences: ThumbnailSequence[]
    isModalOpen: boolean
    setIsModalOpen: (value: boolean) => void
}

export default function ThumbnailSequencesOrderModal(props: Readonly<ThumbnailSequencesOrderModalProps>) {
    const sequenceOrderSaveMutation = useSaveThumbnailSequenceOrder({
        "gameID": props.gameID,
        "gamePartIndex": props.gamePartIndex,
        "onSuccess": () => props.setIsModalOpen(false)
    })
    const [sequences, setSequences] = useState<ThumbnailSequence[]>(() => props.sequences)

    function onDragEnd(event: DragEndEvent) {
        const {active, over} = event
        if (active.id === over?.id) {
            return
        }
        
        const oldIndex = sequences.findIndex((sequence) => sequence.id === active.id)
        const newIndex = sequences.findIndex((sequence) => sequence.id === over?.id)
        setSequences(arrayMove(sequences, oldIndex, newIndex))
    }

    return (
        <ModalForm
            isOpen={props.isModalOpen}
            setIsOpen={props.setIsModalOpen}
            title="Changer l'ordre des séquences"
            handleSubmit={async () => sequenceOrderSaveMutation.mutate({
                "order": sequences.map((sequence) => sequence.orderNumber)
            })}
        >
            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
                <SortableContext items={sequences} strategy={verticalListSortingStrategy}>
                    {sequences.map((sequence, index) => (
                        <SortableThumbnailSequence
                            key={sequence.id}
                            sequence={sequence}
                            gameID={props.gameID}
                            gamePartIndex={props.gamePartIndex}
                            sequenceIndex={index + 1}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </ModalForm>
    )
}

interface SortableThumbnailSequenceProps extends ThumbnailSequenceParam {
    sequence: ThumbnailSequence
}

function SortableThumbnailSequence(props: Readonly<SortableThumbnailSequenceProps>) {
    const {thumbnailLinks, isPending} = useThumbnailLinks({
        "gameID": props.gameID,
        "gamePartIndex": props.gamePartIndex,
        "sequenceIndex": props.sequenceIndex,
    })
    const name = useThumbnailSequenceTitle({
        "sequence": props.sequence,
        "thumbnailLinks": thumbnailLinks,
        "gameID": props.gameID
    })
    
    const {setNodeRef, transition, transform, attributes, listeners} = useSortable({ id: props.sequence.id})
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    if (isPending) {
        return <></>
    }

    return (
        <div className="box" ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {name}
        </div>
    )
}