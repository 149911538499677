import styles from "./columns.module.css"

interface OneEighthColumnProps {
    children?: React.ReactNode
}

export default function OneEighthColumn(props: Readonly<OneEighthColumnProps>) {
    return (
        <div className={`column is-narrow ${styles["is-one-eighth"]} is-one-quarter-touch`}>
            {props.children}
        </div>
    )
}