import { LevelsParam, LEVELS_SCREENSHOT_FILE_FIELD_NAME } from "@hamm4all/shared"
import ModalFormFileUpload from "../../../../components/form/modal-form-file-upload.component"
import useUploadNewScreenshot from "../../../levels/screenshots/hook/useUploadNewScreenshot"

interface LevelScreenshotAddModalProps extends LevelsParam {
    isModalOpen: boolean
    setIsModalOpen: (value: boolean) => void
}

export default function LevelScreenshotAddModal(props: Readonly<LevelScreenshotAddModalProps>) {
    const uploadScreenshotMutation = useUploadNewScreenshot({
        "gameID": props.gameID,
        "levelName": props.levelName,
        "onSuccess": () => props.setIsModalOpen(false)
    })

    function handleFileUpload(file: File) {
        const formData = new FormData()
        formData.append(LEVELS_SCREENSHOT_FILE_FIELD_NAME, file)
        uploadScreenshotMutation.mutate(formData)
    }

    return (
        <ModalFormFileUpload
            isOpen={props.isModalOpen}
            setIsOpen={props.setIsModalOpen}
            handleSubmit={async (file) => handleFileUpload(file)}
            title="Ajouter un screen"
            name="screenshot"
            label="Sélectionner un screen"
        />
    )
}