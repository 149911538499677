import { IsArray, IsBoolean, IsDateString, IsString, Length } from "class-validator";


export class GamesAddBody {

    @IsString()
    @Length(1, 128)
    declare name: string;

    @IsString()
    @Length(10, 2048)
    declare description: string;

    @IsBoolean()
    declare has_variable_difficulty: boolean;

    @IsDateString()
    declare released_at: string;

    @IsArray()
    declare authors: number[];
}