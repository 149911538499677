import { Link } from "react-router-dom";
import { H4A_API, GameParam } from "@hamm4all/shared";
import LevelZone from "../../../components/level/land-level-zone.component";
import AddLevelModal from "../components/level-add-update-modal.component";
import { useState } from "react";
import useURLParams from "../../../hooks/use-url-params.hook";
import useAPIQuery from "../../../hooks/use-api-query.hook";
import { Subtitle } from "../../../common/layout/title/Subtitle";
import { Title } from "../../../common/layout/title/Title";
import { LoadingPage } from "../../../components/loading/loading.component";


export default function LevelsPage() {

    const {gameID} = useURLParams(GameParam)
    const [isAddLevelModalOpen, setIsAddLevelModalOpen] = useState<boolean>(false)

    const {data: game, isLoading: isGameLoading} = useAPIQuery({
        "endpoint": H4A_API.v1.games.get,
        "params": {
            "gameID": gameID
        },
        "query": {}
    })

    const {data: levels, isLoading: isLevelsLoading} = useAPIQuery({
        "endpoint": H4A_API.v1.games.levels,
        "params": {
            "gameID": gameID
        },
        "query": {}
    })

    if (isGameLoading || isLevelsLoading) {
        return <LoadingPage />
    }

    const gameName = game.name

    return <>
        <Title>Niveaux de "{gameName}"</Title>
        <Subtitle centered>
            <Link to={`/games/${gameID}/parts`}>
                Retour
            </Link>
        </Subtitle>
        <br/>
        <LevelZone
            game_id={gameID}
            levels={levels}
            is_first_level_apart={false}
            roleToAdd={1}
            toggleModal={setIsAddLevelModalOpen}
            displayAdder={true}
        />

        <AddLevelModal
            gameID={+gameID} 
            modal={{
                "isOpen": isAddLevelModalOpen,
                "setIsOpen": setIsAddLevelModalOpen
            }}
            type="add"
        />
    </>
}