import { Game, H4A_API } from "@hamm4all/shared"
import CardZone from "../../../components/card-zone/card-zone.component"
import GameModalForm from "../../games/game-modal-form.component"
import { useState } from "react"
import { sortingMethods, SortingTypes } from "./GamesSorter"
import GameThumbnail from "../../../common/components/GameThumbnail/GameThumbnail"

interface GamesZoneDisplayProps {
    games: Game[]
    officialfest?: Game
    search: string;
    sortingMethod: SortingTypes;
}

export default function GamesZoneDisplay(props: Readonly<GamesZoneDisplayProps>) {
    const [addGameModalOpen, setAddGameModalOpen] = useState(false);
    const displayedGames = props.games
        .filter((game) => game.id !== props.officialfest?.id)
        .filter((game) => game.name.toLowerCase().includes(props.search.toLowerCase()))
        .toSorted(sortingMethods[props.sortingMethod])

    return (
        <div data-cy="games-card-zone">
            <CardZone
                toggleModal={setAddGameModalOpen}
                roleToAdd={H4A_API.v1.games.add.getRole()}
                modal={
                    <GameModalForm
                        type="create"
                        modalInteraction={{
                            "isOpen": addGameModalOpen,
                            "setIsOpen": setAddGameModalOpen
                        }}
                    />
                }
            >
                {displayedGames.map((game) =>
                    <GameThumbnail key={game.id} game={game} />
                )}
            </CardZone>
        </div>
    )
}

