import { GamePartParam, H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../../../core/api/useAPIMutation";

interface UseSaveThumbnailSequenceOrderProps extends GamePartParam {
    onSuccess: () => void
}

export default function useSaveThumbnailSequenceOrder(props: Readonly<UseSaveThumbnailSequenceOrderProps>) {
    const saveThumbnailSequenceOrderMutation = useAPIMutation({
        "endpoint": H4A_API.v1.thumbnailSequences.order.update,
        "params": props,
        "onSuccess": props.onSuccess
    })

    if (saveThumbnailSequenceOrderMutation.isError) {
        throw new Error(`Impossible de sauvegarder l'ordre des liens dans la séquence de miniatures de la partie ${props.gamePartIndex} du jeu ${props.gameID}: ${saveThumbnailSequenceOrderMutation.error.message}`)
    }

    return saveThumbnailSequenceOrderMutation
}