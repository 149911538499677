import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GameParam, LevelTransitionLaby, LevelTransitionsLaby } from "@hamm4all/shared";
import { Link } from "react-router-dom";

interface NavigationCrossProps {
    transitions: LevelTransitionsLaby;
    gameID: number;
}

export default function NavigationCross({transitions, gameID}: Readonly<NavigationCrossProps>) {
    return (<>
        <div className="columns">
            <div className="column"></div>
            <div className="column">
                {transitions.up &&
                    <NavigationCrossArrow gameID={gameID} icon={faArrowUp} transition={transitions.up} />
                }
            </div>
            <div className="column"></div>
        </div>
        <div className="columns">
            <div className="column">
                {transitions.left &&
                    <NavigationCrossArrow gameID={gameID} icon={faArrowLeft} transition={transitions.left} />
                }
            </div>
            <div className="column"></div>
            <div className="column">
                {transitions.right &&
                    <NavigationCrossArrow gameID={gameID} icon={faArrowRight} transition={transitions.right} />
                }
            </div>
        </div>
        <div className="columns">
            <div className="column"></div>
            <div className="column">
                {transitions.down &&
                    <NavigationCrossArrow gameID={gameID} icon={faArrowDown} transition={transitions.down} />
                }
            </div>
            <div className="column"></div>
        </div>
    </>)
}

interface NavigationCrossArrowProps extends GameParam {
    icon: IconDefinition;
    transition: LevelTransitionLaby
}

function NavigationCrossArrow({gameID, icon, transition}: Readonly<NavigationCrossArrowProps>) {
    return (
        <Link to={`/games/${gameID}/levels/${transition.level.name}`}>
            <span className="icon">
                <FontAwesomeIcon icon={icon} size="2x" />
            </span>
        </Link>
    )
}