import { HTTPMethod } from "../../../../api.call"
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint"
import { H4ARole } from "../../../api.roles"
import { GamePartParam } from "../game-parts.params"
import { GamePartsUpdateBody } from "./game-parts.update.body"

export class GamePartsUpdateCall extends APIEndpointAction<GamePartParam, GamePartsUpdateBody, {}> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<GamePartParam> {
        return new URIBuilder<GamePartParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<GamePartParam, GamePartsUpdateBody>): string {
        return `Une erreur est survenue lors de la mise à jour de la partie : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<GamePartParam, GamePartsUpdateBody, {}>): string {
        return `La partie a bien été mise à jour !`
    }
}