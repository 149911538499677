import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

interface EditIconProps {
    toggleModal: (toggle: boolean) => void
}

export function EditIcon(props: Readonly<EditIconProps>) {
    return (
        <Link to="#" onClick={() => props.toggleModal(true)}>    
            <span className="icon-text">
                <span>
                    Editer
                </span>
                <span className="icon">
                    <FontAwesomeIcon icon={faEdit} style={{color: "gray"}} />
                </span>
            </span>
        </Link>
    )
}