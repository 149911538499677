import { H4A_API, ThumbnailLinkParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../../core/api/useAPIMutation";

interface UseUpdateThumbnailLinkProps extends ThumbnailLinkParam {
    onSuccess: () => void
}

export default function useUpdateThumbnailLink(props: Readonly<UseUpdateThumbnailLinkProps>) {
    const updateThumbnailLinkMutation = useAPIMutation({
        "endpoint": H4A_API.v1.thumbnailSequences.links.update,
        "params": props,
        "onSuccess": props.onSuccess
    })

    return updateThumbnailLinkMutation
}