import { H4A_API, LevelsParam } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseUpdateDescriptionProps extends LevelsParam {
    onClose: () => void
}

export default function useUpdateDescription(props: UseUpdateDescriptionProps) {
    const mutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.description.add,
        "onSuccess": props.onClose,
        "params": props
    })

    return mutation
}