import styles from "./clickable.module.css"

export interface ClickableProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

export default function Clickable(props: Readonly<ClickableProps>) {
    const {className, ...otherProps} = props;
    return (
        <div className={styles.clickable + " " + className} {...otherProps}>
            {props.children}
        </div>
    );
}