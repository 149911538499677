import { Game } from "@hamm4all/shared"
import GameIdentityLeftPart from "./GameIdentityCard/GameIdentityLeftPart"
import GameIdentityRightPart from "./GameIdentityCard/GameIdentityRightPart"

interface GameIdentityCardProps {
    game: Game
}

export default function GameIdentityCard({game}: Readonly<GameIdentityCardProps>) {
    return (
        <div className="columns">
            <div className="column is-8 is-offset-2" style={{border: "2px solid gray", borderRadius: "5px"}}>
                <article className="media">
                    <div className="media-left">
                        <GameIdentityLeftPart game={game} />
                    </div>
                    <div className="media-content">
                        <GameIdentityRightPart game={game} />
                    </div>
                </article>
            </div>
        </div>
    )
}