import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { FileParam } from "../files.params";

export class FileGetRawCall extends APIEndpointGET<FileParam, {}, Buffer> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<FileParam> {
        return new URIBuilder<FileParam>()
            .addPath("files")
            .addParam("id")
            .addPath("raw")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}