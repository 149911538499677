import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../../../api.endpoint";
import { H4ARole } from "../../../../../api.roles";
import { ThumbnailSequenceParam } from "../../thumbnail-sequences.params";
import { ThumbnailLink } from "../thumbnail-links.types";


export class ThumbnailLinkListCall extends APIEndpointGET<ThumbnailSequenceParam, {}, ThumbnailLink[]> {

    protected getVersion(): APIVersion {
        return 1
    }
    
    protected getUriBuilder() {
        return new URIBuilder<ThumbnailSequenceParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
            .addParam("sequenceIndex")
            .addPath("thumbnail-links")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}