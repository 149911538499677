import { H4A_API, ScreenshotParam } from "@hamm4all/shared";
import { Button } from "react-bulma-components";
import useSaveOrderNumbers from "../../levels/screenshots/hook/useSaveOrderNumbers";
import { useContext } from "react";
import { OrderNumbersContext } from "../../levels/screenshots/context/OrderNumbersContext";
import { useAuth } from "../../../context/user/user.context";

interface LevelOrderControlPanelProps extends ScreenshotParam {
}

export default function LevelOrderControlPanel(props: LevelOrderControlPanelProps) {
    const orderNumbersContext = useContext(OrderNumbersContext)
    const saveOrderNumbersMutation = useSaveOrderNumbers(props)
    const {hasRole} = useAuth()

    if (!orderNumbersContext) {
        return <></>
    }

    function saveOrderNumbers(numbers: {x: number, y: number}[]) {
        const orderNumbers = numbers.map(({x, y}) => {
            return {
                "x": x,
                "y": y
            }
        })

        saveOrderNumbersMutation.mutate({"order": orderNumbers})
    }

    return (
        <div className="buttons is-centered">
            {hasRole(H4A_API.v1.levels.screenshots.fruitOrder.update.getRole()) && <>
                <Button color="info" onClick={orderNumbersContext.addOrderNumber}>Ajouter numéro</Button>
                <Button color="success" onClick={async () => saveOrderNumbers(orderNumbersContext.orderNumbers)}
                >Sauvegarder</Button>
                <Button color="danger" disabled={orderNumbersContext.selectedNumberIndex === -1} onClick={() => orderNumbersContext.removeSelectedOrderNumber()}>Supprimer</Button>
            </>}
        </div>
    )
}