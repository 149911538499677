import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint"
import { H4ARole } from "../../api.roles"
import { Game } from "../games.types"

export class GamesListCall extends APIEndpointGET<{}, {}, Array<Game>> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("games")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}