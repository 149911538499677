import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../hooks/use-api-query.hook";

interface UseLevelLabyTransitionsProps {
    levelName: string;
    gameID: number;
}

export function useLevelLabyTransitions({levelName, gameID}: UseLevelLabyTransitionsProps) {
    const transitionsQuery = useAPIQuery({
        "endpoint": H4A_API.v1.levels.transitions.laby.list,
        "params": {
            "gameID": gameID,
            "levelName": levelName
        },
        "query": {}
    })

    if (transitionsQuery.isError) {
        throw new Error(`Les transitions labyrinthe du niveau ${levelName} du jeu numéro ${gameID} n'ont pas pu être récupérées`)
    }

    return {
        ...transitionsQuery,
        transitions: transitionsQuery.data
    }
}