import { GameParam, H4A_API } from "@hamm4all/shared";
import { Container } from "react-bulma-components";
import { Title } from "../../common/layout/title/Title";
import ItemsTable from "../../components/ItemTable/ItemTable";
import { LoadingPage } from "../../components/loading/loading.component";
import useAPIQuery from "../../hooks/use-api-query.hook";
import useURLParams from "../../hooks/use-url-params.hook";
import GameItemsButtons from "./GameItemsButtons";

export default function GameItemsPage() {
    const { gameID } = useURLParams(GameParam);

    const {data: items, isLoading} = useAPIQuery({
        "endpoint": H4A_API.v1.items.list,
        "params": {},
        "query": {
            "games": [gameID]
        }
    });

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
        <>
            <Title>Objets du jeu</Title>
            <br />
            <Container>
                <GameItemsButtons gameID={gameID} />
            </Container>
            <ItemsTable items={items} displayGame={false} />
        </>
    );
}