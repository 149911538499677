import React, { ReactNode } from "react";
import { H4AError } from "../../errors/h4a-error";
import { Title } from "../../common/layout/title/Title";

type ErrorBoundaryProps = {
    children: ReactNode;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state: {error: Error | null} = {
        error: null
    };

    static getDerivedStateFromError(error: any) {
        return { error };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error("ErrorBoundary caught an error", error, errorInfo)
    }

    render() {
        if (! this.state.error) {
            return this.props.children;
        }

        let errorFurtherInformation: JSX.Element | null = null;
        if (this.state.error instanceof H4AError) {
            errorFurtherInformation = this.state.error.getDisplay();
        }
        else if ((this.state.error as any).data !== undefined) {
            errorFurtherInformation = <p>{(this.state.error as any).data.message}</p>
        }
        else {
            errorFurtherInformation = <p>{this.state.error?.toString()}</p>
        }

        return <>
            <Title>Une erreur est survenue</Title>
            <br />
            <p>La page ne parvient pas à se charger à cause d'une erreur.</p>
            {errorFurtherInformation && <>
                <br />
                {errorFurtherInformation}
            </>}
        </>
    }
}