import FormFieldWrapper from "../form-field.component";

interface FormFieldCheckboxProps extends React.HTMLProps<HTMLInputElement>  {
    label: string;
    changeHandler: (element: boolean) => void;
}

export function FormFieldCheckbox({label, changeHandler, ...inputOptions}: Readonly<FormFieldCheckboxProps>) {
    return <FormFieldWrapper label={label}>
        <input type="checkbox" className="checkbox" {...inputOptions} onChange={(e) => changeHandler(e.target.checked)} />
    </FormFieldWrapper>
}