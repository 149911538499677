import { IsInt, IsNumber, IsObject, IsOptional, Max, Min, ValidateNested } from "class-validator"
import { Type } from "class-transformer";

export class LevelCoordinates {
    @IsNumber()
    @Min(-50)
    @Max(50)
    declare x: number;

    @IsNumber()
    @Min(-50)
    @Max(50)
    declare y: number;
}

export class ThumbnailLinkAddBody {
    @IsInt()
    @Min(0)
    declare levelID: number

    @IsObject()
    @ValidateNested()
    @IsOptional()
    @Type(() => LevelCoordinates)
    declare position?: LevelCoordinates
}