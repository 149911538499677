import axios, {AxiosResponse} from "axios";

export interface APIRequestBody {
    [key: string]: any
}

export type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export interface AxiosProps<Body extends APIRequestBody, Query extends APIRequestBody> {
    url: string;
    method: HTTPMethod;
    body?: Body;
    query?: Query;
    headers?: object;
    authToken?: string;
    isSilent?: boolean;
}

export type APIResponse<FetchDataType> = AxiosResponse<FetchDataType, APIError>;

export interface APIError {
    readonly error: boolean;
    readonly error_id: number;
    readonly message: string;
}

axios.defaults.withCredentials = true;

export async function apiCall<FetchDataType, Body extends APIRequestBody = {}, Query extends APIRequestBody = {}>({url, method, body, headers = {}, authToken, query, isSilent}: AxiosProps<Body, Query>) {
    return axios<FetchDataType, APIResponse<FetchDataType>>({
        url: url,
        method: method,
        data: body,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken ? `Bearer ${authToken}` : undefined,
            ...headers
        },
        params: query,
        // Make arrays have `?key=1&key=2` instead of `key[]=1&key[]=2`
        paramsSerializer: (params) => {
            return Object
                .keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&');
        }
    })
    .catch((error: any) => {
        if (axios.isAxiosError(error)) {
            if (!isSilent) {
                console.error(error)
            }
            return Promise.reject(error.response);
        }
        else {
            return Promise.reject({
                error: true,
                error_id: -1,
                message: "Unknown error"
            });
        }
    })
}