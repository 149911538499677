import { LevelScreenshot, LevelsParam } from "@hamm4all/shared";
import Pagination from "../../../common/components/Pagination";

interface LevelScreenshotsPaginationProps extends LevelsParam {
    screenDisplayedOrderNumber: number
    setScreenDisplayedOrderNumber: (index: number) => void
    screenshots: LevelScreenshot[]
    displayedScreenshotPath: string | undefined
}

export default function LevelScreenshotsPagination(props: Readonly<LevelScreenshotsPaginationProps>) {
    if (props.screenshots.length === 0) {
        return (
            <Pagination
                index={props.screenDisplayedOrderNumber - 1}
                setIndex={(index) => props.setScreenDisplayedOrderNumber(index + 1)}
                length={props.screenshots.length}
            />
        )
    }
    else {
        return (
            <LevelScreenshotsPaginationWithDownload
                {...props}
                />
        )
    }
}

function LevelScreenshotsPaginationWithDownload(props: Readonly<LevelScreenshotsPaginationProps>) {
    return (
        <div className="is-flex is-justify-content-space-around">
            <Pagination
                index={props.screenDisplayedOrderNumber - 1}
                setIndex={(index) => props.setScreenDisplayedOrderNumber(index + 1)}
                length={props.screenshots.length}
            />
            {props.displayedScreenshotPath &&
                <a
                    className="button is-primary"
                    href={`/api/v1/files/${props.displayedScreenshotPath}/raw`}
                    download={`${props.levelName}-${props.screenDisplayedOrderNumber}.png`}
                >
                    Télécharger
                </a>
            }
        </div>
    )
}