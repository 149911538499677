import { GamePartParam, H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../../../hooks/use-api-query.hook";

interface UseThumbnailSequencesProps extends GamePartParam {

}

export default function useThumbnailSequences(props: Readonly<UseThumbnailSequencesProps>) {

    const thumbnailSequencesQuery = useAPIQuery({
        "endpoint": H4A_API.v1.thumbnailSequences.list,
        "params": {
            "gameID": props.gameID,
            "gamePartIndex": props.gamePartIndex
        },
        "query": {}
    })

    if (thumbnailSequencesQuery.isError) {
        throw new Error(`Impossible de charger la séquence de miniatures pour la partie ${props.gamePartIndex} du jeu ${props.gameID}`)
    }

    return {
        "thumbnailSequences": thumbnailSequencesQuery.data,
        ...thumbnailSequencesQuery
    }
}