import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { Level } from "../../levels/levels.types";
import { GameParam } from "../games.params";

export class GamesLevelsListCall extends APIEndpointGET<GameParam, {}, Array<Level>> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}