import { H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseLoginProps {
    onSuccess: () => Promise<void>
}

export default function useLogin(props: Readonly<UseLoginProps>) {
    const login = useAPIMutation({
        "endpoint": H4A_API.v1.auth.login,
        "params": {},
        "onSuccess": props.onSuccess
    })

    return login
}