import "reflect-metadata";
import './styles/index.scss';
import { AuthProvider } from './context/user/user.context';
import H4ARoutes from './h4a-routes';
import { ErrorBoundary } from './components/error/error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from "react-router-dom";

function App() {
	return (
		<AuthProvider>
			<RootPart />
		</AuthProvider>
	);
}

function RootPart() {
	return (
		<ErrorBoundaryPart />
	);
}

function ErrorBoundaryPart() {
	return (
		<ErrorBoundary>
			<QueryClientProviderPart />
		</ErrorBoundary>
	)
}

function QueryClientProviderPart() {
	const queryClient = new QueryClient({});
	return <QueryClientProvider client={queryClient}>
		<RoutingPart />
	</QueryClientProvider>
}

function RoutingPart() {
	return (
		<BrowserRouter>
			<H4ARoutes />
		</BrowserRouter>
	)
}

export default App;
