import { ThumbnailSequenceParam, ThumbnailSequence, ThumbnailLink, ReducedLevel, H4A_API } from "@hamm4all/shared";
import { useAuth } from "../../../../../../context/user/user.context";
import { useState } from "react";
import LevelThumbnail from "../../../../../../common/components/LevelThumbnail/LevelThumbnail";
import OneEighthColumn from "../../../../../../common/layout/columns/OneEighthColumn";
import { AdderIcon } from "../../../../../../components/adder/adder.component";
import ThumbnailLinkModal from "../../../../../game-parts/game-part/components/ThumbnailLinkModal/ThumbnailLinkModal";
import ThumbnailLinkControlButtons from "../../control-buttons/ThumbnailLinkControlButtons";

interface RenderDimensionSequenceProps extends ThumbnailSequenceParam {
    sequence: ThumbnailSequence;
    links: ThumbnailLink[];
    levels: ReducedLevel[];
}

export default function RenderDimensionSequence(props: Readonly<RenderDimensionSequenceProps>) {
    const {hasRole} = useAuth()
    const [isAddThumbnailLinkModalOpen, setIsAddThumbnailLinkModalOpen] = useState(false)

    const firstLink = props.links[0]
    const otherLinks = props.links.slice(1)

    const canAdd = hasRole(H4A_API.v1.thumbnailSequences.links.add.getRole())

    return (
        <div className={`columns box is-mobile`}>
            <div className="column is-one-fifth">
                <div className="columns is-multiline is-gapless is-mobile">
                    <div className="column is-half">
                    {firstLink && 
                        <LevelThumbnail
                            gameID={props.gameID}
                            level={firstLink.level}
                            controlButtons={
                                <ThumbnailLinkControlButtons
                                    gameID={props.gameID}
                                    gamePartIndex={props.gamePartIndex}
                                    sequenceIndex={props.sequenceIndex}
                                    link={firstLink}
                                    levels={props.levels}
                                />
                            }
                        />
                    }
                    </div>
                </div>
                {canAdd && (!firstLink) && <AdderIcon toggleModal={() => setIsAddThumbnailLinkModalOpen(true)} />}
            </div>
            <div className="column">
                <div className="columns is-multiline is-gapless is-mobile">
                    {otherLinks.map((link, index) => 
                        <OneEighthColumn key={link.orderNumber}>
                            <LevelThumbnail
                                gameID={props.gameID}
                                level={link.level}
                                controlButtons={
                                    <ThumbnailLinkControlButtons
                                        gameID={props.gameID}
                                        gamePartIndex={props.gamePartIndex}
                                        sequenceIndex={props.sequenceIndex}
                                        link={link}
                                        levels={props.levels}
                                    />
                                }
                            />
                        </OneEighthColumn>
                    )}
                    {canAdd && firstLink && <AdderIcon toggleModal={() => setIsAddThumbnailLinkModalOpen(true)} />}
                </div>
            </div>
            {canAdd &&
                <ThumbnailLinkModal
                    type="create"
                    gameID={props.gameID}
                    levelsOfGame={props.levels}
                    gamePartIndex={props.gamePartIndex}
                    sequenceIndex={props.sequenceIndex}
                    isModalOpen={isAddThumbnailLinkModalOpen}
                    setIsModalOpen={setIsAddThumbnailLinkModalOpen}
                />
            }
        </div>
    )
}