import { EditIcon } from "./EditIcon";

export interface TitleProps {
    children: React.ReactNode
}

export function Title(props: Readonly<TitleProps>) {
    return (
        <h1 className="title has-text-centered">
            {props.children}
        </h1>
    )
}

export interface TitleWithEditProps extends TitleProps {
    toggleModal: (toggle: boolean) => void
}

export function TitleWithEdit(props: Readonly<TitleWithEditProps>) {
    const title = <h1 className="title has-text-centered">{props.children}</h1>

    return <div className="columns">
        <div className="column">

        </div>
        <div className="column">
            {title}
        </div>
        <div className="column is-flex" style={{"alignItems": "center", "justifyContent": "right"}}>
            <div className="subtitle is-4">
                <EditIcon toggleModal={props.toggleModal} />
            </div>
        </div>
    </div>
}