import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { LandParam } from "../lands.params";
import { Land } from "../lands.types";

export class LandsGetCall extends APIEndpointGET<LandParam, {}, Land> {
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<LandParam>()
            .addPath("lands")
            .addParam("landID");
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}