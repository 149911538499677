import { H4A_API } from "@hamm4all/shared";
import { useState } from "react";
import { FormFieldInput } from "../../../components/form/field-components/form-field-input.component";
import ModalForm from "../../../components/form/modal-form.component";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface DifficultyRatingModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    gameID: number;
}

export default function DifficultyRatingModal(props: Readonly<DifficultyRatingModalProps>) {
    const [difficulty, setDifficulty] = useState<number | null>(null)
    const rateDifficultyMutation = useAPIMutation({
        "endpoint": H4A_API.v1.games.grades.add,
        "params": {
            "gameID": props.gameID
        },
        "onSuccess": () => {
            props.setIsOpen(false)
        }
    })

    return (
        <ModalForm
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            title="Noter la difficulté"
            handleSubmit={async () => rateDifficultyMutation.mutate({"grade": difficulty!})}
        >
            <FormFieldInput
                label="Difficulté"
                name="difficulty"
                type="number"
                changeHandler={(value) => setDifficulty(parseFloat(value))}
            />
        </ModalForm>
    )
}