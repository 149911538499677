import { useAuth } from "../../context/user/user.context";
import { AdderIcon } from "../adder/adder.component";

type CardZoneProps = {
    children: React.ReactElement<HTMLAnchorElement> | React.ReactElement<HTMLAnchorElement>[];
    roleToAdd: number;
    toggleModal: (value: boolean) => void;
    modal: React.ReactElement;
};

export default function CardZone(props: Readonly<CardZoneProps>) {
    const {hasRole} = useAuth();

    let adderIcon = null;
    if (props.roleToAdd && hasRole(props.roleToAdd)) {
        adderIcon = <AdderIcon toggleModal={props.toggleModal} />;
    }

    return <div className="box columns is-multiline is-vcentered">
        {props.children}
        {adderIcon}
        {props.modal}
    </div>

}

export function CardZoneWithoutAdder(props: Readonly<{children: React.ReactElement<HTMLAnchorElement> | React.ReactElement<HTMLAnchorElement>[]}>) {
    return <div className="box columns is-multiline is-vcentered">
        {props.children}
    </div>
}