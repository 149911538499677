import { HTTPMethod } from "../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { ScreenshotParam } from "../../screenshots.params";
import { LevelsScreenshotUploadBody } from "../screenshots.upload.body";

export class LevelsScreenshotUpdateCall extends APIEndpointAction<ScreenshotParam, LevelsScreenshotUploadBody, {}> {
    protected getVersion(): APIVersion {
        return 1;
    }
    
    protected getUriBuilder(): URIBuilder<ScreenshotParam> {
        return new URIBuilder<ScreenshotParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("screenshots")
            .addParam("screenshotOrderNumber")
    }

    public override isFileForm(): boolean {
        return true
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ScreenshotParam, FormData>): string {
        return `Une erreur s'est produite lors de la mise à jour du screen ${params.params.screenshotOrderNumber} du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ScreenshotParam, {}>): string {
        return `Le screen ${params.params.screenshotOrderNumber} du niveau ${params.params.levelName} a bien été mis à jour.`
    }
}