import { H4A_API } from "@hamm4all/shared"
import FormLanguageInput from "./field-components/form-field-input-language.component"
import useAPIQuery from "../../hooks/use-api-query.hook";

interface MultiLanguageFormPartProps {
    names: { [language: string]: string };
    setNames: (names: { [language: string]: string }) => void;
}

export default function MultiLanguageFormPart(props: Readonly<MultiLanguageFormPartProps>) {
    const languagesQuery = useAPIQuery({
        "endpoint": H4A_API.v1.languages.list,
        "query": {},
        "params": {}
    })

    function handleNameChange(language: string, name: string) {
        props.setNames({
            ...props.names,
            [language]: name
        })
    }

    function removeLanguage(language: string) {
        const newNames = { ...props.names }
        delete newNames[language]
        props.setNames(newNames)
    }

    if (languagesQuery.isLoading) {
        return <></>
    }

    const languages = languagesQuery.data

    function addLanguage(language: string) {
        if (!props.names[language]) {
            props.setNames({
                ...props.names,
                [language]: ""
            })
        }
    }

    return (
        <div>
            {Object.entries(props.names).map(language => (
                <FormLanguageInput key={language[0]} language={language[0]} onNameChange={handleNameChange} onRemove={removeLanguage} required={language[0] === "fr"} value={language[1]} />
            ))}

            <div className="field is-horizontal">
                <div className="field-label is-normal" style={{"flexGrow": 2}}>
                    <label className="label">
                        Ajouter une langue
                    </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-narrow">
                            <div className="select">
                                <select onChange={e => addLanguage(e.target.value)}>
                                    {languages.map(language => <option key={language.code} value={language.code}>{language.code}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}