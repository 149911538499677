import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4A_API, ThumbnailLinkParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../../core/api/useAPIMutation";

interface ThumbnailLinkDeleteButtonProps extends ThumbnailLinkParam {

}

export default function ThumbnailLinkDeleteButton(props: Readonly<ThumbnailLinkDeleteButtonProps>) {
    const deleteLinkMutation = useAPIMutation({
        "endpoint": H4A_API.v1.thumbnailSequences.links.delete,
        "params": props,
    })

    function confirmAndValidateDelete() {
        if (window.confirm("Veux-tu vraiment supprimer ce lien ?")) {
            deleteLinkMutation.mutate({})
        }
    }

    return (
        <button className="button is-small is-danger" onClick={() => confirmAndValidateDelete()}>
            <FontAwesomeIcon icon={faTrash} />
        </button>
    )
}