import { useState } from "react";
import { Button, Form } from "react-bulma-components";
import useLogin from "../hooks/useLogin";

export default function LoginForm({onLogin}: Readonly<{onLogin: () => void}>) {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const loginMutation = useLogin({
        "onSuccess": async () => onLogin()
    })

    return (
        <form id="login-form" onSubmit={(e) => {
            e.preventDefault();
        }}>
            <Form.Field>
                <Form.Label>Nom d'utilisateur *</Form.Label>
                <Form.Control>
                    <Form.Input type="text" name="username" maxLength={50} autoFocus required
                        onChange={(event) => setUsername(event.target.value)} data-cy={"login-username"} />
                </Form.Control>
            </Form.Field>
            <Form.Field>
                <Form.Label>Mot de passe *</Form.Label>
                <Form.Control>
                    <Form.Input type="password" name="password" autoFocus required
                        onChange={(event) => setPassword(event.target.value)} data-cy={"login-password"} />
                </Form.Control>
            </Form.Field>
            <Form.Field>
                <Form.Control>
                    <Button
                        className="is-link"
                        onClick={() => loginMutation.mutate({
                            "username": username,
                            "password": password
                        })}
                        data-cy={"login-submit"}
                    >
                        Se connecter
                    </Button>
                </Form.Control>
            </Form.Field>
        </form>
    )
}