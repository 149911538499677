import { H4A_API, LevelDescription, LevelsParam } from "@hamm4all/shared";
import { useAuth } from "../../../context/user/user.context";
import { Subtitle, SubtitleWithEdit } from "../../../common/layout/title/Subtitle";
import { useState } from "react";
import DescriptionEditModal from "./LevelDescriptionUpdateModal";

interface LevelDescriptionTitleProps extends LevelsParam {
    description: LevelDescription;
}

export default function LevelDescriptionTitle(props: Readonly<LevelDescriptionTitleProps>) {
    const {hasRole} = useAuth()

    return hasRole(H4A_API.v1.levels.description.add.getRole()) ?
        <LevelDescriptionTitleWithEdit {...props} /> :
        <LevelDescriptionTitleWithoutEdit {...props} />
}

function LevelDescriptionTitleWithEdit(props: Readonly<LevelDescriptionTitleProps>) {
    const [isDescriptionEditModalOpen, setIsDescriptionEditModalOpen] = useState<boolean>(false)

    return <>
        <SubtitleWithEdit centered toggleModal={setIsDescriptionEditModalOpen}>
            {props.description ? "Description" : "Aucune description pour ce niveau"}
        </SubtitleWithEdit>

        <DescriptionEditModal
            initialDescription={props.description?.content}
            gameID={props.gameID}
            levelName={props.levelName}
            setIsDescriptionEditModalOpen={setIsDescriptionEditModalOpen}
            isDescriptionEditModalOpen={isDescriptionEditModalOpen}
        />
    </>;
}

function LevelDescriptionTitleWithoutEdit(props: Readonly<LevelDescriptionTitleProps>) {
    return <>
        <Subtitle centered>
            {props.description ? "Description" : "Aucune description pour ce niveau"}
        </Subtitle>
    </>;
}