import { GameParam, LevelTransitionsLaby } from "@hamm4all/shared";
import { Subtitle } from "../../../../common/layout/title/Subtitle";
import NavigationCross from "./NavigationCross";

interface LabyrinthTransitionsProps extends GameParam {
    labyrinthTransitions: LevelTransitionsLaby;
}

export default function LabyrinthTransitions({gameID, labyrinthTransitions}: Readonly<LabyrinthTransitionsProps>) {
    return (
        <div className="column has-text-centered">
            <Subtitle centered>Navigation</Subtitle>
            <NavigationCross transitions={labyrinthTransitions} gameID={gameID} />
        </div>
    )
}