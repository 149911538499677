import { ThumbnailLink } from "@hamm4all/shared";
import { useCallback } from "react";

interface UseLabySequenceDataProps {
    links: ThumbnailLink[];
}

export default function useLabySequenceData(props: Readonly<UseLabySequenceDataProps>) {
    const labyLinks = props.links.filter(link => !!link.position)
    let xRange = labyLinks.reduce((acc, link) => {
        return {
            "min": Math.min(acc.min, link.position!.x),
            "max": Math.max(acc.max, link.position!.x)
        }
    }, {"min": -1, "max": 9})
    let yRange = labyLinks.reduce((acc, link) => {
        return {
            "min": Math.min(acc.min, link.position!.y),
            "max": Math.max(acc.max, link.position!.y)
        }
    }, {"min": -1, "max": 1})
    if (labyLinks.some(link => link.position!.x === xRange.min)) xRange.min--;
    if (labyLinks.some(link => link.position!.x === xRange.max)) xRange.max++;
    if (labyLinks.some(link => link.position!.y === yRange.min)) yRange.min--;
    if (labyLinks.some(link => link.position!.y === yRange.max)) yRange.max++;

    function getLinkAt(x: number, y: number) {
        return labyLinks.some(link => link.position!.x === x && link.position!.y === y)
    }

    const getLinkAtCallback = useCallback(getLinkAt, [labyLinks])

    return {
        xRange,
        yRange,
        getLinkAt: getLinkAtCallback,
        labyLinks
    }
}