import { GameParam, LevelTransitionClassic } from "@hamm4all/shared";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

interface NextTransitionsProps extends GameParam {
    nextTransitions: LevelTransitionClassic[]
}

export default function NextTransitions({gameID, nextTransitions}: Readonly<NextTransitionsProps>) {
    const navigate = useNavigate()
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowRight" && nextTransitions.length > 0) {
                navigate(`/games/${gameID}/levels/${nextTransitions[0].level.name}`)
            }
        }

        document.addEventListener("keydown", handleKeyDown)

        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    })

    return (
        <div className="column">
            {nextTransitions.length > 0 ?
                nextTransitions.map((transition) => 
                    <div key={transition.level.id + transition.type} className="has-text-left">
                        <Link to={`/games/${gameID}/levels/${transition.level.name}`}>
                            Niveau {transition.level.name} &gt;&gt;&gt;
                        </Link>
                    </div>
                )
                : <p className="has-text-centered">Aucun niveau suivant</p>
            }
        </div>
    )
}