import { useState } from "react";
import useScreenshots from "../../levels/screenshots/hook/useScreenshots";
import LevelOrderControlPanel from "./LevelOrderControlPanel";
import LevelScreenshotControlPanel from "./LevelScreenshotControlPanel";
import { OrderNumbersProvider } from "../../levels/screenshots/context/OrderNumbersContext";
import { LevelsParam } from "@hamm4all/shared";
import { Subtitle } from "../../../common/layout/title/Subtitle";
import LevelScreenshotPlace from "./LevelScreenshot";

interface LevelScreenshotsZoneProps extends LevelsParam {
}

export default function LevelScreenshotsZone(props: Readonly<LevelScreenshotsZoneProps>) {
    const [screenDisplayedOrderNumber, setScreenDisplayedOrderNumber] = useState<number>(1)

    const screenshotsQuery = useScreenshots({
        "gameID": props.gameID,
        "levelName": props.levelName
    })

    if (screenshotsQuery.isLoading) {
        return <></>
    }

    const {screenshots} = screenshotsQuery

    const result = <>
        <LevelScreenshotPlace
            gameID={props.gameID}
            levelName={props.levelName}
            screenshots={screenshots}
            screenDisplayedOrderNumber={screenDisplayedOrderNumber}
            setScreenDisplayedOrderNumber={setScreenDisplayedOrderNumber}
        />
        <div className="box columns">
            <div className="column">
                <LevelScreenshotControlPanel
                    gameID={props.gameID}
                    levelName={props.levelName}
                    screenshotOrderNumber={screenDisplayedOrderNumber}
                    screenshots={screenshots}
                />
            </div>
            <div className="column">
                <Subtitle centered>Ordre</Subtitle>
                <LevelOrderControlPanel gameID={props.gameID} levelName={props.levelName} screenshotOrderNumber={screenDisplayedOrderNumber} />
            </div>
        </div>
    </>

    return screenshots.length > 0 ?
        <OrderNumbersProvider
            gameID={props.gameID}
            levelName={props.levelName}
            screenshotOrderNumber={screenDisplayedOrderNumber}
        >
            {result}
        </OrderNumbersProvider>
    : result
}