import { Item } from "@hamm4all/shared";

export default function useItemGamesWithoutDuplicates(items: Readonly<Item[]>) {
    const gamesWithDuplucates = items.map((item) => ({
        "label": item.game.name,
        "value": "" + item.game.id
    }))
    const sortedGamesWithoutDuplicates = gamesWithDuplucates.filter((game, index, self) =>
        index === self.findIndex((t) => (
            t.value === game.value
        ))
    )

    return sortedGamesWithoutDuplicates;
}