import { H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseSignupProps {
    onSuccess: () => Promise<void>
}

export default function useSignup(props: Readonly<UseSignupProps>) {
    const signup = useAPIMutation({
        "endpoint": H4A_API.v1.auth.signup,
        "params": {},
        "onSuccess": props.onSuccess,
    })

    return signup
}