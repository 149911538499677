import { H4A_API, Item } from "@hamm4all/shared"
import { useAuth } from "../../context/user/user.context"
import ItemUploadButton from "./ItemRowActionButtons/ItemUploadButton"
import ItemUpdateButton from "./ItemRowActionButtons/ItemUpdateButton"
import ItemDeleteButton from "./ItemRowActionButtons/ItemDeleteButton"

interface ItemRowActionButtonsProps {
    item: Item
}

export default function ItemRowActionButtons({item}: Readonly<ItemRowActionButtonsProps>) {
    const {hasRole} = useAuth()

    return <div style={{"minWidth": "150px"}}>
        {hasRole(H4A_API.v1.items.picture.update.getRole()) &&
            <ItemUploadButton itemID={item.id} />
        }

        {hasRole(H4A_API.v1.items.update.getRole()) &&
            <ItemUpdateButton item={item} />
        }  

        {hasRole(H4A_API.v1.items.delete.getRole()) &&
            <ItemDeleteButton itemID={item.id} />
        }
    </div>
}