import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { LoginBody } from "./login.body";

export class LoginCall extends APIEndpointAction<{}, LoginBody, {}> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("auth")
            .addPath("login")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }

    public override getErrorMessage(params: APIEndpointErrorParams<{}, LoginBody>): string {
        return `Impossible de se connecter à l'utilisateur ${params.body.username} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<{}, {}>): string {
        return `Bienvenue !`
    }
}