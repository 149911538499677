import { H4A_API } from "@hamm4all/shared"
import useAPIQuery from "../../../hooks/use-api-query.hook"

export default function useChangelog() {
    const changelogQuery = useAPIQuery({
        "endpoint": H4A_API.v1.changelog.list,
        "params": {},
        "query": {},
    })

    return {
        ...changelogQuery,
        changelog: changelogQuery.data,
    }
}