import { FormFieldSelectMultiple } from "../form/field-components/form-field-select.component"
import { H4A_API } from "@hamm4all/shared"
import useAPIQuery from "../../hooks/use-api-query.hook"

interface AuthorsSelectorProps {
    authors: number[],
    setAuthors: (authors: number[]) => void
    title: string
}

export default function FormAuthorsSelector(props: Readonly<AuthorsSelectorProps>) {

    const allAuthorsQuery = useAPIQuery({
        "endpoint": H4A_API.v1.authors.list,
        "params": {},
        "query": {}
    })

    if (allAuthorsQuery.isLoading) {
        return <></>
    }

    const allAuthors = allAuthorsQuery.data;

    return <FormFieldSelectMultiple
        label={props.title}
        name="authors"
        value={props.authors.map(authorID => authorID.toString())}
        changeHandler={(authors) => props.setAuthors(authors.map(authorID => parseInt(authorID)))}
        options={allAuthors.map((author) => ({value: author.id.toString(), label: author.name}))}
    />
}