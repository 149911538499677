import { HTTPMethod } from "../../../../api.call"
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint"
import { H4ARole } from "../../../api.roles"
import { GameParam } from "../../games.params"
import { GamePart } from "../game-parts.types"
import { GamePartsAddBody } from "./game-parts.add.body"

export class GamePartsAddCall extends APIEndpointAction<GameParam, GamePartsAddBody, GamePart> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.MODERATOR
    }

    public override getErrorMessage(params: APIEndpointErrorParams<GameParam, GamePartsAddBody>): string {
        return `Une erreur est survenue lors de l'ajout de la partie ${params.body.name} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<GameParam, GamePartsAddBody, GamePart>): string {
        return `La partie ${params.body.name} a bien été ajoutée !`
    }
}