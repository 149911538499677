import { H4A_API } from "@hamm4all/shared"
import useAPIQuery from "../../../../hooks/use-api-query.hook"

export default function useLands(gameID: number) {
    const landsQuery = useAPIQuery({
        "endpoint": H4A_API.v1.lands.list,
        "params": {
            "gameID": gameID
        },
        "query": {}
    })

    if (landsQuery.isError) {
        throw new Error(`Une erreur s'est produite lors de la récupération des contrées du jeu numéro ${gameID}`)
    }

    return {
        lands: landsQuery.data,
        ...landsQuery
    }
}