import { ReducedUser } from "../../../users/users.types"


export class ThumbnailSequence {
    declare id: number
    declare orderNumber: number
    declare gamePartID: number
    declare createdAt: string
    declare updatedAt: string
    declare updatedBy: ReducedUser
    declare isFirstLevelApart: boolean
    declare hasLabyrinth: boolean
    declare name?: string
}