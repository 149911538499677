export type LevelTransitionLabyType = "up" | "right" | "left" | "down";

export const LEVEL_TRANSITION_LABY_TYPES: LevelTransitionLabyType[] = ["up", "right", "left", "down"];

export type LevelTransitionsLaby = {
    [keyof in LevelTransitionLabyType]?: LevelTransitionLaby;
}

export interface LevelTransitionLaby {
    level: {
        id: number;
        name: string;
    };
    type: LevelTransitionLabyType;
}