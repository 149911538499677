import { IsInt, IsNumber, Max, Min } from "class-validator";


export class GameGrade {

    @Min(0)
    @Max(10)
    @IsNumber()
    declare grade: number;

    @IsInt()
    declare userID: number;
}