import { Container, Table } from "react-bulma-components";
import styles from "./ItemWeightTable.module.css";

const ITEMS_WEIGHTS = [
    [7, 60],
    [6, 1],
    [5, 10],
    [4, 100],
    [3, 300],
    [2, 1000],
    [1, 2000],
    [0, 0]
]

export default function ItemWeightTable() {
    return (
        <Container className={styles["weight-table"]}>
            <Table>
                <thead>
                    <tr>
                        <td>Coefficient</td>
                        <td>Poids</td>
                    </tr>
                </thead>
                <tbody>
                    {ITEMS_WEIGHTS.map(([coefficient, weight]) => (
                        <tr key={coefficient}>
                            <td>{coefficient}</td>
                            <td>{weight}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}