import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { GameParam } from "../../games.params";
import { Land } from "../lands.types";

export class LandsListCall extends APIEndpointGET<GameParam, {}, Land[]> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("lands")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}