import { HTTPMethod } from "../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { LevelsParam } from "../../../levels.params";
import { LevelTransitionsUpdateBody } from "./level-transitions.update.body";


export class LevelTransitionsUpdateCall extends APIEndpointAction<LevelsParam, LevelTransitionsUpdateBody, {}> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("transitions")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<LevelsParam, LevelTransitionsUpdateBody>): string {
        return `Une erreur s'est produite lors de la mise à jour des transitions du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<LevelsParam, {}>): string {
        return `Les transitions du niveau ${params.params.levelName} ont bien été mises à jour.`
    }
}