import { LevelsParam } from "@hamm4all/shared";
import { useState } from "react";
import { Button } from "react-bulma-components";
import { LevelVideosModal } from "./LevelVideosUpdateModal";

interface LevelVideosAddButtonProps extends LevelsParam {

}

export default function LevelVideosAddButton(props: Readonly<LevelVideosAddButtonProps>) {
    const [isAddVideoModalOpen, setIsAddVideoModalOpen] = useState<boolean>(false)

    return <>
        <Button color="info" onClick={() => setIsAddVideoModalOpen(true)}>Ajouter une vidéo</Button>

        <LevelVideosModal
            gameID={props.gameID}
            levelName={props.levelName}
            isModalOpen={isAddVideoModalOpen}
            setModalOpen={setIsAddVideoModalOpen}
            action="add"
        />
    </>
}