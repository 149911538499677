import { useId, useState } from "react";
import { Modal } from "react-bulma-components";

interface ModalFormProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    children: any;
    handleSubmit: (event: React.MouseEvent) => Promise<void>;
    title: string;
}

export default function ModalForm(props: Readonly<ModalFormProps>) {
    const formID = useId()
    const [loading, setLoading] = useState(false);

    return <Modal show={props.isOpen} onClose={() => props.setIsOpen(false)} closeOnBlur={true}>
        <Modal.Card>
            <Modal.Card.Header>
                <Modal.Card.Title>{props.title}</Modal.Card.Title>
            </Modal.Card.Header>
            <Modal.Card.Body>
                <form id={formID} onSubmit={(e) => e.preventDefault()}>
                    {props.children}
                </form>
            </Modal.Card.Body>
            <Modal.Card.Footer justifyContent={"flex-end"}>
                <button
                    className={`is-success button ${loading ? "is-loading" : ""}`}
                    onClick={(e) => {
                        setLoading(true);
                        props.handleSubmit(e)
                        .finally(() => setLoading(false))
                    }}
                    form={formID}
                    type="submit"
                >
                    Valider
                </button>
            </Modal.Card.Footer>
        </Modal.Card>
    </Modal>
}