import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { Item } from "../items.types";
import { ItemParam } from "../items.params";
import { H4ARole } from "../../api.roles";

export class ItemsGetCall extends APIEndpointGET<ItemParam, {}, Item> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder(): URIBuilder<ItemParam> {
        return new URIBuilder<ItemParam>()
            .addPath("items")
            .addParam("itemID")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }

}