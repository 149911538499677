import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { UserParam } from "../users.params";
import { UserProfile } from "../users.types";

export class UsersProfileCall extends APIEndpointGET<UserParam, {}, UserProfile> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<UserParam> {
        return new URIBuilder<UserParam>()
            .addPath("users")
            .addParam("userID")
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }
}