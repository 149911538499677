import { useState } from "react";
import { Link } from "react-router-dom"
import H4AImage from "./image/image.component";
import { useAuth } from "../context/user/user.context";
import { H4ARole } from "@hamm4all/shared/dist/calls/routes/api.roles";
import useLogout from "../pages/connect/hooks/useLogout";

export default function Navbar() {
    const [isActive, setIsActive] = useState(false);
    const {isAuthenticated, profilePicture, hasRole, id, name, refreshAuth} = useAuth()
    const logoutMutation = useLogout({
        "onSuccess": () => {
            setIsActive(false);
            refreshAuth()
        },
    })

    function toggleMenu() {
        setIsActive(!isActive);
    }

    function closeMenu(event: any) {
        setIsActive(false);
        event.target.blur()
    }

    return <nav role="navigation" aria-label="main navigation" className="navbar is-dark">
        <div className="navbar-brand">
            <Link to="/" className="navbar-item" onClick={closeMenu}>
                <img src="/favicon.png" alt="Hamm4all" />
                <p className="navbar-item">Hamm4all</p>
            </Link>
            <Link to="#" role="button" className={(isActive ? "is-active" : "") + " navbar-burger burger"} aria-label="menu" aria-expanded="false" data-target="menuh4a" onClick={toggleMenu} data-cy={"navbar-burger"}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </Link>
        </div>

        <div className={(isActive ? "is-active" : "") + " navbar-menu"} id="menuh4a">
            <div className="navbar-start">
                <Link to="/techniques" className="navbar-item" onClick={closeMenu}>Techniques</Link>
                <div className="navbar-item has-dropdown is-hoverable">
                    <Link to="#" className="navbar-link">Règles</Link>
                    <div className="navbar-dropdown">
                        <Link to="/rules/crystals" className="navbar-item" onClick={closeMenu}>Les Cristaux</Link>
                        <Link to="/rules/letters" className="navbar-item" onClick={closeMenu}>Les Lettres</Link>
                        <Link to="/rules/cache-ennemi" className="navbar-item" onClick={closeMenu}>Le Cache-ennemi</Link>
                        <Link to="/rules/orders" className="navbar-item" onClick={closeMenu}>Les Ordres</Link>
                        <Link to="/rules/bugs" className="navbar-item" onClick={closeMenu}>Les Bugs</Link>
                    </div>
                </div>
                <div className="navbar-item has-dropdown is-hoverable">
                    <Link to="#" className="navbar-link">Ennemis</Link>
                    <div className="navbar-dropdown">
                        <Link to="/enemies/fruits" className="navbar-item" onClick={closeMenu}>Les Fruits</Link>
                        <Link to="/enemies/bosses" className="navbar-item" onClick={closeMenu}>Les Boss</Link>
                    </div>
                </div>
                <div className="navbar-item has-dropdown is-hoverable">
                    <Link to="#" className="navbar-link">Objets</Link>
                    <div className="navbar-dropdown">
                        <Link to="/objects/special" className="navbar-item" onClick={closeMenu}>Objets spéciaux</Link>
                        <Link to="/objects/families" className="navbar-item" onClick={closeMenu}>Les familles</Link>
                        <Link to="/objects/quests" className="navbar-item" onClick={closeMenu}>Les quêtes</Link>
                        <Link to="/objects/coeffs" className="navbar-item" onClick={closeMenu}>Coefficients</Link>
                        <Link to="/objects/keys" className="navbar-item" onClick={closeMenu}>Les Clés</Link>
                    </div>
                </div>
                <Link to="/games" className="navbar-item" onClick={closeMenu}>Jeux</Link>
                <div className="navbar-item has-dropdown is-hoverable">
                    <Link to="#" className="navbar-link">Créations</Link>
                    <div className="navbar-dropdown">
                        <Link to="/creations/levels" className="navbar-item" onClick={closeMenu}>Niveaux</Link>
                        <Link to="/creations/musics" className="navbar-item" onClick={closeMenu}>Musiques</Link>
                        <Link to="/creations/tools" className="navbar-item" onClick={closeMenu}>Outils</Link>
                    </div>
                </div>
                <Link to="/thanks" className="navbar-item" onClick={closeMenu}>Remerciements</Link>
            </div>
            <div className="navbar-end">
                {isAuthenticated ?
                    <div className="navbar-item has-dropdown is-hoverable">
                        <Link to="#" className="navbar-link">
                            <H4AImage src={profilePicture} alt="Profil" className="is-rounded" />
                        </Link>
                        <div className="navbar-dropdown is-right">
                            {
                                hasRole(H4ARole.ADMINISTRATOR) && <Link to="/users" className="navbar-item" onClick={closeMenu}>Profils de site</Link>
                            }
                            <Link to={`/users/${id}`} className="navbar-item" onClick={closeMenu} data-cy={"profile-link"} data-cy-profile-name={name}>Profil</Link>
                            <Link
                                to="#"
                                className="navbar-item"
                                onClick={async (e) => {
                                    logoutMutation.mutate({});
                                    (e.target as any).blur();
                                }}
                                data-cy={"logout-button"}
                            >
                                Déconnexion
                            </Link>
                        </div>
                    </div>
                    :
                    <div className="navbar-item" onClick={closeMenu} role="button">
                        <div className="buttons">
                            <Link to={`/connect?previousPage=${window.location.pathname}`} className="button is-info" data-cy={"connect-page-button"}>Connexion</Link>
                        </div>
                    </div>
                }
            </div>
        </div>
    </nav>
}