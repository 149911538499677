import ReactMarkdown from "react-markdown";
import { H4A_API } from "@hamm4all/shared";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";
import useAPIQuery from "../../hooks/use-api-query.hook";
import { LoadingPage } from "../../components/loading/loading.component";

export default function Techniques() {
    const techniquesQuery = useAPIQuery({
        "endpoint": H4A_API.v1.techniques,
        "params": {},
        "query": {}
    })

    const techniques = techniquesQuery.data
    if (techniquesQuery.isPending) {
        return <LoadingPage />
    }

    return <>
        <Title>Techniques</Title>
        <br/>
        {techniques.map(technique => {
            return <div key={technique.name}>
                <hr className="hr" />
                <Subtitle centered>{technique.name}</Subtitle>
                <br />
                <div className="content">
                    <ReactMarkdown>
                        {technique.presentation}
                    </ReactMarkdown>
                </div>
                {technique.videos.length > 0 &&
                    <div className="centered">
                        <table className="table is-bordered is-hoverable">
                            <thead>
                                <tr>
                                    {technique.videos.map(video => {
                                        return <td key={video[0].name}>{video[0].name}</td>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {technique.videos.map(video => {
                                        return <td key={video[0].name}>
                                            <video autoPlay loop className="centered">
                                                {video.map(source => {
                                                    return <source key={source.source} src={source.source} type={`video/${source.type}`} />
                                                })}
                                            </video>
                                        </td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                <div className="content"><ReactMarkdown>{technique.utility}</ReactMarkdown></div>
            </div>
        })}
    </>
}
