import { H4A_API, LevelsParam, LevelsVideo } from "@hamm4all/shared";
import LevelVideosAddButton from "./LevelVideosAddButton";
import { useAuth } from "../../../../context/user/user.context";
import LevelVideosEditButton from "./LevelVideosEditButton";

interface LevelVideosButtonsProps extends LevelsParam {
    selectedVideoIndex: number;
    currentVideo: LevelsVideo | undefined;
    videos: LevelsVideo[];
}

export default function LevelVideosButtons(props: Readonly<LevelVideosButtonsProps>) {
    const {hasRole} = useAuth()

    return (
        <div className="buttons is-centered">
            {hasRole(H4A_API.v1.levels.videos.add.getRole()) && 
                <LevelVideosAddButton {...props} />
            }
            {hasRole(H4A_API.v1.levels.videos.update.getRole()) &&
                <LevelVideosEditButton {...props} />
            }
        </div>
    )
}