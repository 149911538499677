import { HTTPMethod } from "../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { UserParam } from "../../users.params";

export class UsersProfilePictureUpdateCall extends APIEndpointAction<UserParam, FormData, {}> {
    
    protected getVersion(): APIVersion {
        return 1;
    }

    protected override getUriBuilder(): URIBuilder<UserParam> {
        return new URIBuilder<UserParam>()
            .addPath("users")
            .addParam("userID")
            .addPath("profile-picture")
    }

    public override getMethod(): HTTPMethod {
        return "PUT"
    }

    public override isFileForm(): boolean {
        return true
    }

    public override getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<UserParam, FormData>): string {
        return `Une erreur est survenue lors de la mise à jour de la photo de profil : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<UserParam, FormData, {}>): string {
        return `La photo de profil a bien été mise à jour !`
    }

    public override getQueryKeysToInvalidate(params: UserParam): (string | object)[][] {
        return [
            ["users", params.userID.toString()]
        ]
    }
}