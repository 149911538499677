import { H4A_API, Item } from "@hamm4all/shared";
import ItemRow from "./ItemRow";
import { Table } from "react-bulma-components";
import { useAuth } from "../../context/user/user.context";

interface ItemsTableCoefficientSectionProps {
    coefficient: number;
    items: Item[];
    itemIDForModalOpen: string | null;
    setItemIDForModalOpen: (itemID: string | null) => void;
    displayGame: boolean;
}

export default function ItemsTableCoefficientSection(props: Readonly<ItemsTableCoefficientSectionProps>) {
    const { hasRole } = useAuth()

    return (
        <Table className="is-fullwidth">
            <thead style={{"border": "0.5px solid white"}}>
                <tr>
                    <td colSpan={5} className="has-text-centered">Coefficients {props.coefficient}</td>
                </tr>
                <tr>
                    <td>Image</td>
                    <td>Nom</td>
                    <td>Effet</td>
                    {props.displayGame &&
                        <td>Jeu</td>
                    }
                    {hasRole(H4A_API.v1.items.update.getRole()) && (
                        <td>Actions</td>
                    )}
                </tr>
            </thead>
            <tbody style={{"border": "0.5px solid white"}}>
                {props.items.map((item) => (
                    <ItemRow
                        key={item.id}
                        item={item}
                        displayGame={props.displayGame}
                    />
                ))}
            </tbody>
        </Table>
    )
}