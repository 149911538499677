import styles from "./columns.module.css"

interface OneTenthColumnProps {
    children?: React.ReactNode
}

export default function OneTenthColumn(props: Readonly<OneTenthColumnProps>) {
    return (
        <div className={`column is-narrow ${styles["is-one-tenth"]} is-one-quarter-touch`}>
            {props.children}
        </div>
    )
}