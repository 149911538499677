import { HTTPMethod } from "../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { LevelsVideoParam } from "../videos.params";


export class LevelsVideoDeleteCall extends APIEndpointAction<LevelsVideoParam, {}, {}> {
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsVideoParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("videos")
            .addParam("videoIndex")
    }

    public getMethod(): HTTPMethod {
        return "DELETE"
    }

    public getRole(): H4ARole {
        return H4ARole.MODERATOR
    }

    public override getErrorMessage(params: APIEndpointErrorParams<LevelsVideoParam, {}>): string {
        return `Une erreur s'est produite lors de la suppression de la vidéo ${params.params.videoIndex} du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<LevelsVideoParam, {}>): string {
        return `La vidéo ${params.params.videoIndex} du niveau ${params.params.levelName} a bien été supprimée.`
    }
}