import { HTTPMethod } from "../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { LevelsParam } from "../../levels.params";
import { LevelsScreenshotOrderUpdateBody } from "./screenshots.order.update.body";


export class LevelsScreenshotOrderUpdateCall extends APIEndpointAction<LevelsParam, LevelsScreenshotOrderUpdateBody, {}> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("screenshots-order")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<LevelsParam, LevelsScreenshotOrderUpdateBody>): string {
        return `Une erreur s'est produite lors de la mise à jour de l'ordre des screens du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<LevelsParam, {}>): string {
        return `L'ordre des screens du niveau ${params.params.levelName} a bien été mis à jour.`
    }

}