import { IsArray, IsEnum, IsNumber, ValidateNested } from "class-validator";
import type { LevelTransitionClassicType } from "../transitions.types";
import { Type } from "class-transformer";

class LevelTransitionUpdateBody {
    @IsNumber()
    declare targetID: number;

    @IsEnum(["simple", "dimension"])
    declare type: LevelTransitionClassicType;
}

export class LevelTransitionsUpdateBody {

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => LevelTransitionUpdateBody)
    declare transitions: LevelTransitionUpdateBody[];
}