import { Content } from "react-bulma-components";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";

export default function ToolsCreation() {
    return <>
        <Title>Les Outils</Title>
        <Content>
            <p>Les différents outils qui aident à la création ou à la simplification de choses sur Hammerfest ne manquent pas ! En voici une liste non exhausive :</p>

            <Subtitle centered>L'éditeur de niveaux</Subtitle>
            <p>L'éditeur de niveau créé par <a href="https://eternalfest.net/users/f7e0876d-1551-4129-a991-5ddd2cd540a3">moulins</a> (aussi appelé "HammerfestCreator") est un éditeur de niveau très performant permettant de faire tous les niveaux que vous pouvez imaginer.<br/>Il continue à être mis à jour de temps en temps<br/>Vous pouvez le télécharger <a href="/assets/outils/HammerfestCreator-1.8.1.zip" download="HammerfestCreator-1.8.1.zip">ici</a>.</p>

            <Subtitle centered>L'outil des ordres</Subtitle>
            <p>Cet outil simple, créé par <a href="https://eternalfest.net/users/82d879b4-a62f-4822-8331-d10d3e6aeea0">Globox62</a> est un analyseur de possibilités d'ordres en fonction des croisements :<br/>Il suffit d'entrer les croisements faits pour un niveau, et le programme sortira les possibilités d'ordres restantes qui conviennent aux croisements.<br/>Vous pouvez le télécharger <a href="/assets/outils/EternalOrdres.zip" download="EternalOrdres.zip">ici</a>.</p>

            <Subtitle centered>Gameresult</Subtitle>
            <p>Le Gameresult est un outil très pratique développé par <a href="https://eternalfest.net/users/b01cbc0d-519f-4775-adeb-a4492ff3ab4a">Erneraude</a> qui permet de visionner un résultat de n'importe quelle partie sur Eternalfest, pour peu qu'on possède l'ID de la partie. On pourra voir dedans le détail de chaque objet pris ! Pratique pour archiver un résultat de partie à tout jamais !<br/>Le Gameresult est disponible en ligne à <a href="https://gameresult.ef.rainbowda.sh/">cette adresse</a>.</p>

            <Subtitle centered>Analyseur de frigo</Subtitle>
            <p>L'analyseur de frigo (ou "Fridge Parser") est un outil développé par <a href="https://eternalfest.net/users/9d048774-33b4-4212-946c-e6fe5b9329b4">BlessedRacc</a> pour analyser le frigo d'un profil Eternalfest.<br/>L'outil donne le nombre de chaque coeffs dans le frigo concerné et donne les pourcentages relatifs pour chaque coeffs !<br/>L'outil est disponible en téléchargement <a href="https://gitlab.com/BlessedRaccoon/fridge-parser/-/archive/master/fridge-parser-master.zip">ici</a> en téléchargement.</p>

            <Subtitle centered>Greenshot</Subtitle>
            <p>Greenshot est un logiciel gratuit qui sert à prendre des screens. Il peut sélectionner une zone (par exemple, celle du jeu) et prendre des screens seulement en appuyant sur une touche !<br/>C'est un vrai must-have quand on se met à prendre des screens<br/>L'outil est disponible <a href="https://getgreenshot.org/">ici</a>. Attention cependant, Greenshot ne fonctionne pas sur Linux !</p>
        </Content>
    </>
}