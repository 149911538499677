import { IsInt, IsNotEmpty, IsString, Min } from "class-validator";
import { ReducedLand } from "../games/lands/lands.types";
import { ReducedUser } from "../users/users.types";

export class ReducedLevel {
    @IsInt()
    @Min(0)
    id: number;

    @IsString()
    @IsNotEmpty()
    name: string;

    mainScreenshotID?: string;
}

export interface Level extends ReducedLevel {
    land: ReducedLand;
    created_at: string;
    updated_at: string;
    updated_by: ReducedUser;
}