import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./navbar";
import Version from "../core/version/Version";
import { ErrorBoundary } from "../components/error/error-boundary";

export default function Layout() {
    const location = useLocation()
    return <>
        <Navbar />
        <div className="container is-fullhd bgbody">
            <ErrorBoundary key={location.pathname}>
                <Outlet />
            </ErrorBoundary>
        </div>
        <Version />
    </>
}