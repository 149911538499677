import { HTTPMethod } from "../../../../../api.call"
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../api.endpoint"
import { H4ARole } from "../../../../api.roles"
import { ThumbnailSequenceParam } from "../thumbnail-sequences.params"
import { ThumbnailSequenceUpdateBody } from "./thumbnail-sequences.update.body"

export class ThumbnailSequenceUpdateCall extends APIEndpointAction<ThumbnailSequenceParam, ThumbnailSequenceUpdateBody, {}> {

    protected getVersion(): APIVersion {
        return 1
    }
    
    protected getUriBuilder(): URIBuilder<ThumbnailSequenceParam> {
        return new URIBuilder<ThumbnailSequenceParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
            .addParam("sequenceIndex")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ThumbnailSequenceParam, ThumbnailSequenceUpdateBody>): string {
        return `Une erreur est survenue lors de la mise à jour de la séquence d'images : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ThumbnailSequenceParam, ThumbnailSequenceUpdateBody, {}>): string {
        return `La séquence d'images a bien été mise à jour !`
    }
}