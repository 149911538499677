import { GameParam, LevelTransitionClassic } from "@hamm4all/shared";
import { Link } from "react-router-dom";

interface DimensionTransitionsProps extends GameParam {
    dimensionsTransitions: LevelTransitionClassic[];
}

export default function DimensionTransitions({gameID, dimensionsTransitions}: Readonly<DimensionTransitionsProps>) {
    return (
        <div className="column has-text-centered">
            {dimensionsTransitions.length > 0 ?
                dimensionsTransitions.map((transition) => {
                    return <div key={transition.level.id + transition.type}>
                        <Link to={`/games/${gameID}/levels/${transition.level.name}`}>
                            Dimension {transition.level.name}
                        </Link>
                    </div>
                })
                : <p>Aucune dimension</p>
            }
        </div>
    )
}