import { GamePartParam, H4A_API, Level, ThumbnailLink, ThumbnailSequence, ThumbnailSequenceParam } from "@hamm4all/shared";
import LevelThumbnail from "../../../../../../common/components/LevelThumbnail/LevelThumbnail";
import useLabySequenceData from "./useLabySequenceData";
import { useState } from "react";
import { AdderIcon } from "../../../../../../components/adder/adder.component";
import ThumbnailLinkModal from "../../../../../game-parts/game-part/components/ThumbnailLinkModal/ThumbnailLinkModal";
import ThumbnailLinkControlButtons from "../../control-buttons/ThumbnailLinkControlButtons";
import { useAuth } from "../../../../../../context/user/user.context";

interface RenderLabySequenceProps extends GamePartParam {
    sequence: ThumbnailSequence;
    links: ThumbnailLink[];
    levels: Level[];
}

export default function RenderLabySequence(props: Readonly<RenderLabySequenceProps>) {
    
    const {labyLinks, getLinkAt, xRange, yRange} = useLabySequenceData({
        links: props.links
    })

    return (
        <div style={{"minWidth": "100%", "overflowX": "scroll"}}>
            <table>
                <tbody>
                    {Array.from({length: yRange.max - yRange.min + 1}).map((_, yIndex) => 
                        <LabySequenceRow
                            key={yIndex}
                            yIndex={yIndex}
                            gameID={props.gameID}
                            gamePartIndex={props.gamePartIndex}
                            sequence={props.sequence}
                            levels={props.levels}
                            yRange={yRange}
                            getLinkAt={getLinkAt}
                            labyLinks={labyLinks}
                            xRange={xRange}
                        />
                    )}
                </tbody>
            </table>
        </div>
    )
}

interface LabySequenceRowProps extends GamePartParam {
    sequence: ThumbnailSequence;
    levels: Level[];
    yIndex: number;
    yRange: {min: number, max: number};
    xRange: {min: number, max: number};
    getLinkAt: (x: number, y: number) => boolean;
    labyLinks: ThumbnailLink[];
}

function LabySequenceRow(props: Readonly<LabySequenceRowProps>) {
    return (
        <tr>
            {Array.from({length: props.xRange.max - props.xRange.min + 1}).map((_, xIndex) => 
                <LabySequenceTile
                    key={xIndex}
                    xIndex={xIndex}
                    {...props}
                />
            )}
        </tr>
    )
}

interface LabySequenceTileProps extends LabySequenceRowProps {
    xIndex: number;
}

function LabySequenceTile(props: Readonly<LabySequenceTileProps>) {
    const {hasRole} = useAuth()
    const realX = props.xIndex + props.xRange.min
    const realY = props.yIndex + props.yRange.min
    const link = props.labyLinks.find(link => link.position!.x === realX && link.position!.y === realY)
    const hasNeighbors =
        props.getLinkAt(realX + 1, realY) ||
        props.getLinkAt(realX - 1, realY) ||
        props.getLinkAt(realX, realY + 1) ||
        props.getLinkAt(realX, realY - 1)

    const style: any = {"minWidth": "120px", "height": `${120 * 119 / 100}px`, "alignItems": "center", "justifyContent": "center"}
    const displayAdder = 
        hasRole(H4A_API.v1.thumbnailSequences.links.add.getRole()) && 
        ((!link && hasNeighbors) ||
        ((realX === 0 && realY === 0) && link === undefined))

    if (displayAdder) {
        style.display = "flex"
    }

    return (
        <td style={{"position": "relative"}} >
            <div style={style}>
                {link && <LevelThumbnail
                    gameID={props.gameID}
                    level={link.level}
                    controlButtons={
                        <ThumbnailLinkControlButtons
                            link={link}
                            gameID={props.gameID}
                            gamePartIndex={props.gamePartIndex}
                            sequenceIndex={props.sequence.orderNumber}
                            position={{
                                x: realX,
                                y: realY
                            }}
                            levels={props.levels}
                        />
                    }
                />}
                {displayAdder && 
                    <LabyLinkAdder
                        gameID={props.gameID}
                        gamePartIndex={props.gamePartIndex}
                        sequenceIndex={props.sequence.orderNumber}
                        levels={props.levels}
                        position={{
                            x: realX,
                            y: realY
                        }}
                    />
                }
            </div>
        </td>
    )
}

interface LabyLinkAdderProps extends ThumbnailSequenceParam {
    levels: Level[];
    position: {x: number, y: number}
}

function LabyLinkAdder(props: Readonly<LabyLinkAdderProps>) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    return <>
        <div className="has-text-centered">
            <AdderIcon toggleModal={() => setIsModalOpen(true)} />
        </div>
        <ThumbnailLinkModal
            type="create"
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            gameID={props.gameID}
            levelsOfGame={props.levels}
            gamePartIndex={props.gamePartIndex}
            sequenceIndex={props.sequenceIndex}
            initialPosition={props.position}
        />
    </>
}