import { Item } from "@hamm4all/shared"
import { useState } from "react"
import ItemsTableCoefficientSection from "./ItemTableCoefficientSection"

interface ItemsTableProps {
    items: Item[]
    displayGame: boolean
}

/**
 * (does not use Array.group since it is not supported by NodeJS yet. Would be better to use it when it is supported)
 * @param items 
 * @returns 
 */
function groupItemsByCoefficientDescending(items: Item[]): { [coefficient: number]: Item[] } {
    const groups: { [coefficient: number]: Item[] } = {}
    items.forEach((item) => {
        if (!groups[item.coefficient]) {
            groups[item.coefficient] = []
        }
        groups[item.coefficient].push(item)
    })
    return groups
}

export default function ItemsTable(props: Readonly<ItemsTableProps>) {

    const [editItemModal, setEditItemModal] = useState<string | null>(null)
    const tables = groupItemsByCoefficientDescending(props.items)

    return (
        <div>
            {Object.entries(tables)
                .sort((a, b) => parseInt(b[0]) - parseInt(a[0]))
                .map(([coefficient, items]) =>
                    <ItemsTableCoefficientSection
                        key={coefficient}
                        coefficient={+coefficient}
                        items={items}
                        itemIDForModalOpen={editItemModal}
                        setItemIDForModalOpen={(itemID) => {
                            setEditItemModal(itemID)
                        }}
                        displayGame={props.displayGame}
                    />
                )
            }
        </div>
    )
}