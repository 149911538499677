import FormFieldWrapper from "../form-field.component";

interface FormFieldProps extends React.HTMLProps<HTMLInputElement>  {
    label: string;
    changeHandler: (element: any) => void;
}

export function FormFieldInput({label, changeHandler, ...inputOptions}: Readonly<FormFieldProps>) {
    return <FormFieldWrapper label={label}>
        <input className="input" {...inputOptions} onChange={(e) => {
            if (inputOptions.type === "number") {
                changeHandler(+e.target.value)
            }
            else {
                changeHandler(e.target.value)
            }
        }} />
    </FormFieldWrapper>
}

interface FormFieldTextAreaProps extends React.HTMLProps<HTMLTextAreaElement>  {
    label: string;
    changeHandler: (element: string) => void;
}

export function FormFieldTextArea({label, changeHandler, ...inputOptions}: Readonly<FormFieldTextAreaProps>) {
    return <FormFieldWrapper label={label}>
        <textarea className="textarea" {...inputOptions} onChange={(e) => changeHandler(e.target.value)} />
    </FormFieldWrapper>
}