import { Title } from "../../common/layout/title/Title"
import { Table } from "../../layout/table/table.component"
import musics from "./musics.creations.json"

export default function MusicsCreations() {
    return <>
        <Title>Créations - Musiques</Title>
        <p>Ici, on verra toutes les splendides créations musicales créées à l'éfigie de ce beau jeu !<br/>Les voici :</p>
        <Table>
            <thead>
                <tr>
                    <td>Nom de la musique</td>
                    <td>Créateur(s)</td>
                    <td>Lien</td>
                </tr>
            </thead>
            <tbody>
                {musics.map((song) => {
                    return <tr key={song.name}>
                        <td>{song.name}</td>
                        <td>{song.author}</td>
                        <td>
                            {song.internal ? 
                                <audio controls>
                                    <source src={song.link} />
                                </audio>
                            :
                                <a href={song.link}>{song.name}</a>
                            }
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    </>
}