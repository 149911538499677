import ModalForm from "../../../components/form/modal-form.component"
import { useState } from "react"
import { FormFieldInput } from "../../../components/form/field-components/form-field-input.component"
import { FormFieldSelect } from "../../../components/form/field-components/form-field-select.component"
import useLands from "../../games/lands/hooks/use-lands.hook"
import useAddLevel from "../hooks/useAddLevel"
import useUpdateLevel from "../hooks/useUpdateLevel"

interface BaseLevelModalProps {
    modal: ModalInteraction;
    gameID: number;
}

interface AddLevelModalProps extends BaseLevelModalProps {
    type: "add";
}

interface UpdateLevelModalProps extends BaseLevelModalProps {
    type: "update";
    initialData: {
        name: string;
        landID: number;
        levelID: number;
    };
}

type LevelModalProps = AddLevelModalProps | UpdateLevelModalProps;

interface ModalInteraction {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}


export default function AddUpdateLevelModal(props: Readonly<LevelModalProps>) {
    const isUpdate = props.type === "update";
    const [name, setName] = useState<string>(
        isUpdate ? props.initialData.name : ""
    );
    const [landID, setLandID] = useState<number>(
        isUpdate ? props.initialData.landID : NaN
    );

    const {lands, isLoading: isLandLoading} = useLands(props.gameID)

    const addMutation = useAddLevel({
        "gameID": props.gameID,
        "closePopup": () => props.modal.setIsOpen(false)
    })
    const updateMutation = useUpdateLevel({
        "gameID": props.gameID,
        "levelName": props.type === "update" ? props.initialData.name : "",
        "closePopup": () => props.modal.setIsOpen(false)
    })

    async function handleSubmit() {
        const effectiveLandID = landID || lands[0].id

        if (isUpdate) {
            updateMutation.mutate({
                "landID": effectiveLandID,
                "name": name
            })
        }
        else {
            addMutation.mutate({
                "landID": effectiveLandID,
                "name": name
            })
        }
    };

    if (isLandLoading) {
        return <></>
    }

    return (
        <ModalForm
            isOpen={props.modal.isOpen}
            setIsOpen={props.modal.setIsOpen}
            title={isUpdate ? `Modifier le niveau` : "Ajouter un niveau"}
            handleSubmit={async () => handleSubmit()}
        >
            <FormFieldInput
                label="Nom"
                name="name"
                placeholder="Nom du niveau"
                required
                changeHandler={setName}
                value={name}
            />

            <FormFieldSelect
                label="Contrée"
                name="land-id"
                required
                changeHandler={(landID) => setLandID(+landID)}
                value={landID}
                options={lands.map(land => ({
                    "value": "" + land.id,
                    "label": land.name,
                }))}
            />

        </ModalForm>
    )

}
