import { Link } from "react-router-dom";
import { Subtitle } from "../../../common/layout/title/Subtitle";
import { Title } from "../../../common/layout/title/Title";
import { Land, LandParam } from "@hamm4all/shared";
import useURLParams from "../../../hooks/use-url-params.hook";
import useLand from "./hooks/use-land.hook";
import { LoadingPage } from "../../../components/loading/loading.component";
import LevelZone from "../../../components/level/land-level-zone.component";
import useGame from "../hooks/use-game.hook";

function LandPage() {
    const {landID} = useURLParams(LandParam)
    const {land, isLoading: isLandLoading} = useLand(landID)

    if (isLandLoading) {
        return <LoadingPage />
    }

    return <RealPage land={land} />
}

function RealPage({land}: Readonly<{land: Land}>) {
    const gameID = land.gameID
    const {game, isLoading} = useGame(gameID)

    if (isLoading) {
        return <LoadingPage />
    }

    return <>
        <Title>{land.name}</Title>
        <Subtitle centered><Link to={`/games/${game.id}`}>{game.name}</Link></Subtitle>
        <br />
        <p>Niveaux de {land.name === "unnamed" ? "Sans nom" : land.name} ({game.name})</p>
        <LevelZone
            levels={land.levels}
            game_id={game.id}
            toggleModal={() => {}}
            displayAdder={false}
        />
    </>
}

export default LandPage