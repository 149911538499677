import { IsInt, IsString } from "class-validator";


export class LevelsAddBody {

    @IsString()
    declare name: string;

    @IsInt()
    declare landID: number;
}