import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { LevelsParam } from "../../../levels.params";
import { LevelTransitionsLaby } from "../transitions-laby.types";

export class LevelTransitionsLabyListCall extends APIEndpointGET<LevelsParam, {}, LevelTransitionsLaby> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("transitions-laby")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}