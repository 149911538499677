import { Game, H4A_API } from "@hamm4all/shared"
import { useAuth } from "../../../../context/user/user.context"
import H4AImage from "../../../../layout/image/image.component"
import GameIdentityUploadThumbnailButton from "./GameIdentityUploadThumbnailButton"

interface GameIdentityLeftPartProps {
    game: Game
}

export default function GameIdentityLeftPart({game}: Readonly<GameIdentityLeftPartProps>) {
    const {hasRole} = useAuth()
    const imageSize = "150px"

    return <>
        <p className="image">
            <H4AImage src={game.thumbnail_id} alt="land_image" style={{
                "width": imageSize,
                "height": imageSize,
            }} />
        </p>
        {hasRole(H4A_API.v1.games.thumbnail.update.getRole()) && <GameIdentityUploadThumbnailButton gameID={game.id} />}
        </>
}