import { IsInt, IsNotEmpty, IsString, Min } from "class-validator";

export class UserProfile {

    @IsInt()
    declare id: number;
    declare name: string;

    @Min(1)
    declare role: number;

    @Min(0)
    declare experience: number;
    declare createdAt: string;
    declare profilePicture?: string;

    @Min(0)
    declare currentLevel: number;

    @Min(0)
    declare experienceToReachCurrentLevel: number;

    @Min(0)
    declare experienceToReachNextLevel: number;
}

export class ReducedUser {

    @IsInt()
    declare id: number;

    @IsString()
    @IsNotEmpty()
    declare name: string;
}