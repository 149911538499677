import { HTTPMethod } from "../../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../../api.endpoint";
import { H4ARole } from "../../../../../api.roles";
import { ThumbnailLinkParam } from "../thumbnail-links.params";

export class ThumbnailLinkDeleteCall extends APIEndpointAction<ThumbnailLinkParam, {}, {}> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<ThumbnailLinkParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
            .addParam("sequenceIndex")
            .addPath("thumbnail-links")
            .addParam("thumbnailLinkIndex")
    }

    public getMethod(): HTTPMethod {
        return "DELETE"
    }

    public getRole(): H4ARole {
        return H4ARole.MODERATOR
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ThumbnailLinkParam, {}>): string {
        return `Une erreur est survenue lors de la suppression du lien d'image : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ThumbnailLinkParam, {}, {}>): string {
        return `Le lien d'image a bien été supprimé !`
    }
}