import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { GameParam } from "../games.params";
import { Game } from "../games.types";
import { GamesUpdateBody } from "./games.update.body";

export class GamesUpdateCall extends APIEndpointAction<GameParam, GamesUpdateBody, Game> {
    
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<GameParam, GamesUpdateBody>): string {
        return `Une erreur est survenue lors de la mise à jour du jeu : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<GameParam, GamesUpdateBody, Game>): string {
        return `Le jeu a bien été mis à jour !`
    }
}