import { HTTPMethod } from "../../../../../api.call";
import { APIEndpointAction, URIBuilder, APIVersion, APIEndpointErrorParams, APIEndpointSuccessParams } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { ScreenshotParam } from "../../screenshots.params";
import { FruitOrderUpdateBody } from "./fruit-order.update.body";

export class FruitOrderUpdateCall extends APIEndpointAction<ScreenshotParam, FruitOrderUpdateBody, {}> {
    protected getUriBuilder(): URIBuilder<ScreenshotParam> {
        return new URIBuilder<ScreenshotParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("screenshots")
            .addParam("screenshotOrderNumber")
            .addPath("fruit-order");
    }

    protected getVersion(): APIVersion {
        return 1;
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ScreenshotParam, FruitOrderUpdateBody>): string {
        return `Une erreur s'est produite lors de la mise à jour de l'ordre du screen ${params.params.screenshotOrderNumber} du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ScreenshotParam, {}>): string {
        return `L'ordre du screen ${params.params.screenshotOrderNumber} du niveau ${params.params.levelName} a bien été mis à jour.`
    }
}