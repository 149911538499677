import { Fragment } from "react";
import { AdderIcon } from "../adder/adder.component";
import { ReducedLevel } from "@hamm4all/shared";
import styles from "./level-thumbnail.module.css"
import { useAuth } from "../../context/user/user.context";
import LevelThumbnail from "../../common/components/LevelThumbnail/LevelThumbnail";
import OneTenthColumn from "../../common/layout/columns/OneTenthColumn";

interface LevelZoneProps {
    game_id: number,
    levels: Array<ReducedLevel>,
    is_first_level_apart?: boolean;
    roleToAdd?: number;
    toggleModal: (value: boolean) => void;
    displayAdder: boolean;
}

export default function LevelZone(props: Readonly<LevelZoneProps>) {
    return <div className={`columns is-multiline is-gapless box ${styles["level-zone"]}`}>
        {props.levels.map((level, index) => 
            <OneTenthColumn key={level.id + "-" + index}>
                <LevelThumbnail 
                    gameID={props.game_id}
                    level={level}
                />
            </OneTenthColumn>
        )}
    </div>

}