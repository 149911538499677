import { GameParam, H4A_API, Level } from "@hamm4all/shared";
import { useAuth } from "../../../context/user/user.context";
import { Title, TitleWithEdit } from "../../../common/layout/title/Title";
import AddUpdateLevelModal from "../../levels/components/level-add-update-modal.component";
import { useState } from "react";

interface LevelPageTitleProps extends GameParam {
    level: Level;
}

export default function LevelPageTitle({gameID, level}: Readonly<LevelPageTitleProps>) {
    const {hasRole} = useAuth();

    return <>
        {hasRole(H4A_API.v1.levels.update.getRole()) ?
            <LevelPageTitleWithEdit gameID={gameID} level={level} /> :
            <LevelPageTitleWithoutEdit gameID={gameID} level={level} />
        }
    </>
}

function LevelPageTitleWithEdit({gameID, level}: Readonly<LevelPageTitleProps>) {
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
    return <>
        <TitleWithEdit toggleModal={setEditModalOpen}>
            Niveau {level.name}
        </TitleWithEdit>
        <AddUpdateLevelModal
            key={level.name}
            type="update"
            gameID={+gameID}
            modal={{
                "isOpen": editModalOpen,
                "setIsOpen": setEditModalOpen
            }}
            initialData={{
                "landID": level.land.id,
                "name": level.name,
                "levelID": level.id
            }}
        />
    </>
}

function LevelPageTitleWithoutEdit({level}: Readonly<LevelPageTitleProps>) {
    return <Title>
        Niveau {level.name}
    </Title>
}