import { useMutation, useQueryClient } from "@tanstack/react-query"
import { APIEndpointAction } from "@hamm4all/shared/dist/calls/api.endpoint"
import { createNotification } from "../../components/notifications/notification.component"
import { APIResponse } from "@hamm4all/shared"

interface UseAPIMutationProps<Params extends object, Body extends object, ReturnType extends object> {
    onSuccess?: (response: APIResponse<ReturnType>) => void
    endpoint: APIEndpointAction<Params, Body, ReturnType>
    params: Params
}

export default function useAPIMutation<Params extends object, Body extends object, ReturnType extends object>(props: Readonly<UseAPIMutationProps<Params, Body, ReturnType>>) {
    const queryClient = useQueryClient()
    const callFunction = props.endpoint.getCallFunction(props.params, {})

    const mutation = useMutation({
        mutationFn: callFunction,
        onMutate(variables) {
            /* Nothing */
        },
        onSuccess: (response, body) => {
            createNotification({
                "message": props.endpoint.getSuccessMessage({
                    "params": props.params,
                    "body": body,
                    "response": response
                }),
                "type": "success"
            })

            const queryKeysToInvalidate = props.endpoint.getQueryKeysToInvalidate(props.params)
            for (const queryKey of queryKeysToInvalidate) {
                queryClient.invalidateQueries({
                    "queryKey": queryKey,
                })
            }

            props.onSuccess?.(response)
        },
        onError: (error, body) => {
            createNotification({
                "message": props.endpoint.getErrorMessage({
                    "params": props.params,
                    "body": body,
                    "error": error as any
                }),
                "type": "error"
            })
        }
    })

    return mutation
}