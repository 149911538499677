import { Game, H4A_API } from "@hamm4all/shared";
import { Button } from "react-bulma-components";
import DifficultyRatingModal from "../../DifficultyRatingModal";
import { useState } from "react";
import GameIdentityField from "./GameIdentityField";
import styles from "./GameIdentityDifficultyField.module.css";
import { useAuth } from "../../../../../context/user/user.context";

interface GameIdentityDifficultyFieldProps {
    game: Game;
}

export default function GameIdentityDifficultyField({game}: Readonly<GameIdentityDifficultyFieldProps>) {
    return (
        <GameIdentityField label="Difficulté">
            <DifficultyBoxResponse
                gameHasVariableDifficulty={game.has_variable_difficulty}
                difficulty={game.difficulty}
                gameID={game.id}
            />
        </GameIdentityField>
    )
}

interface DifficultyBoxResponseProps {
    gameHasVariableDifficulty: boolean;
    difficulty: number | null;
    gameID: number;
}

function DifficultyBoxResponse(props: Readonly<DifficultyBoxResponseProps>) {
    const [difficultyModalOpen, setDifficultyModalOpen] = useState(false)
    const {hasRole} = useAuth()

    return <>
        {props.gameHasVariableDifficulty ?
            "Difficulté variable" :
            <span className={styles["game-identity-difficulty-field-value"]}>
                {props.difficulty ? `${Math.round(props.difficulty * 100) / 100}/10` : "Difficulté inconnue"}
                {hasRole(H4A_API.v1.games.grades.add.getRole()) &&
                    <>
                        <Button color="info" size="small" onClick={() => setDifficultyModalOpen(true)}>Noter la difficulté</Button>
                        <DifficultyRatingModal
                            isOpen={difficultyModalOpen}
                            setIsOpen={setDifficultyModalOpen}
                            gameID={props.gameID}
                        />
                    </>
                }
            </span>
        }
    </>
}
