interface GameIdentityFieldProps {
    label: string;
    children: React.ReactNode;
}

export default function GameIdentityField({label, children}: Readonly<GameIdentityFieldProps>) {
    return (
        <tr>
            <td>
                <div className="has-text-left">
                    {label}
                </div>
            </td>
            <td>
                <div className="has-text-left">
                    {children}
                </div>
            </td>
        </tr>
    )
}