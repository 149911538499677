import { GamePartParam, H4A_API } from "@hamm4all/shared";
import useThumbnailSequences from "../../game-parts/game-part/hooks/sequences/useThumbnailSequences";
import ThumbnailSequenceZone from "./sequence/ThumbnailSequenceZone";
import { useAuth } from "../../../context/user/user.context";
import EditSequencesOrderButton from "./EditSequencesOrderButton";
import AddSequenceButton from "./AddSequenceButton";

interface ThumbnailSequencesZoneProps extends GamePartParam {

}

export default function ThumbnailSequencesZone(props: Readonly<ThumbnailSequencesZoneProps>) {
    const {hasRole} = useAuth()
    const {thumbnailSequences, isLoading} = useThumbnailSequences(props)

    if (isLoading) {
        return <></>
    }

    return <div>
        <div className="buttons is-centered">
            {hasRole(H4A_API.v1.thumbnailSequences.order.update.getRole()) && 
                <EditSequencesOrderButton
                    thumbnailSequences={thumbnailSequences}
                    gameID={props.gameID}
                    gamePartIndex={props.gamePartIndex}
                />
            }
        </div>
        {thumbnailSequences.map((thumbnailSequence) => 
            <ThumbnailSequenceZone
                key={thumbnailSequence.id}
                thumbnailSequence={thumbnailSequence}
                gameID={props.gameID}
                gamePartIndex={props.gamePartIndex}
                sequenceIndex={thumbnailSequence.orderNumber}
            />
        )}

        {hasRole(H4A_API.v1.thumbnailSequences.add.getRole()) && 
            <AddSequenceButton gameID={props.gameID} gamePartIndex={props.gamePartIndex} />
        }
    </div>
}