import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { LevelsParam } from "../../levels.params";
import { LevelsVideo } from "../videos.types";

export class LevelsVideoListCall extends APIEndpointGET<LevelsParam, {}, LevelsVideo[]> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("videos")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}