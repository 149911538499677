import { validateSync } from "class-validator"
import { plainToClass } from "class-transformer"
import { useParams } from "react-router-dom";
import { H4AError } from "../errors/h4a-error";

export class WrongURLParamsError extends H4AError {

    constructor(
        public readonly validationErrors: string[]
    ) {
        super('Invalid URL parameters');
    }

    getDisplay(): JSX.Element {
        return <>
            <p>Les paramètres de l'URL ne sont pas corrects. Voici une explication :</p>
            <ul>
                {this.validationErrors.map((error, index) =>
                    <li key={index}>{error}</li>
                )}
            </ul>
        </>
    }
}

export default function useURLParams<T extends object>(ValidatorClass: new () => T): T {
    const params = useParams();

    const instance = plainToClass(ValidatorClass, params);
    const validationError = validateSync(instance);

    if (validationError.length > 0) {
        throw new WrongURLParamsError(validationError.map(e => e.toString()));
    }

    return instance;
}
