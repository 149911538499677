import { LevelScreenshot, LevelsParam } from "@hamm4all/shared";
import ModalForm from "../../../../components/form/modal-form.component";
import useSaveScreenOrder from "../../../levels/screenshots/hook/useSaveScreenOrder";
import { useState } from "react";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { getBlobPathFromId } from "../../../../utilities/blobs/blobs.utilities";

interface LevelScreenshotChangeOrderModalProps extends LevelsParam {
    isModalOpen: boolean
    setIsModalOpen: (value: boolean) => void
    screenshots: LevelScreenshot[]
}

export default function LevelScreenshotChangeOrderModal(props: Readonly<LevelScreenshotChangeOrderModalProps>) {
    const [screenshotsOrder, setScreenshotsOrder] = useState<LevelScreenshot[]>(props.screenshots)
    const saveNewScreenOrderMutation = useSaveScreenOrder({
        "gameID": props.gameID,
        "levelName": props.levelName,
        "closeModal": () => props.setIsModalOpen(false)
    })

    function onDragEnd(event: DragEndEvent) {
        const {active, over} = event
        if (active.id === over?.id) {
            return
        }
        
        const oldIndex = screenshotsOrder.findIndex((screenshot) => screenshot.id === active.id)
        const newIndex = screenshotsOrder.findIndex((screenshot) => screenshot.id === over?.id)
        setScreenshotsOrder(arrayMove(screenshotsOrder, oldIndex, newIndex))
    }
    
    return (
        <ModalForm
            isOpen={props.isModalOpen}
            setIsOpen={props.setIsModalOpen}
            title="Changer l'ordre des screens"
            handleSubmit={async () => saveNewScreenOrderMutation.mutate({
                "newOrderIDs": screenshotsOrder.map((screenshot) => screenshot.id)
            })}
        >
            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
                <SortableContext items={screenshotsOrder} strategy={horizontalListSortingStrategy}>
                    {screenshotsOrder.map((screenshot, index) => (
                        <SortableScreenshot key={screenshot.id} screenshot={screenshot} />
                    ))}
                </SortableContext>
            </DndContext>

        </ModalForm>
    )
}

interface SortableScreenshotProps {
    screenshot: Readonly<LevelScreenshot>
}

function SortableScreenshot(props: Readonly<SortableScreenshotProps>) {
    const {setNodeRef, transition, transform, attributes, listeners} = useSortable({ id: props.screenshot.id })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return <img
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        src={getBlobPathFromId(props.screenshot.id)}
        alt={props.screenshot.id}
        width={"50%"}
    />
}