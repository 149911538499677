import { useState } from "react"
import ItemFormModal from "../../components/items/items.add-update.component"
import { GameParam } from "@hamm4all/shared"

interface GameItemsAddButtonProps extends GameParam {

}

export default function GameItemsAddButton({gameID}: Readonly<GameItemsAddButtonProps>) {
    const [addItemModalOpen, setAddItemModalOpen] = useState(false)
    
    return <>
        <button className="button is-primary" onClick={() => setAddItemModalOpen(true)}>
            Ajouter un objet
        </button>

        <ItemFormModal
            type="create"
            modal={{
                isModalOpen: addItemModalOpen,
                setIsModalOpen: setAddItemModalOpen,
            }}
            gameID={gameID}
        />
    </>

    
}