import { H4A_API } from "@hamm4all/shared"
import useAPIQuery from "../../../../hooks/use-api-query.hook"

interface UseGamePartProps {
    gameID: number
    partIndex: number
}

export default function useGamePart(props: UseGamePartProps) {
    const gamePartQuery = useAPIQuery({
        "endpoint": H4A_API.v1.gameParts.get,
        "params": {
            "gameID": props.gameID,
            "gamePartIndex": props.partIndex
        },
        "query": {}
    })

    if (gamePartQuery.isError) {
        throw new Error(`Une erreur s'est produite lors de la récupération de la partie de jeu ${props.partIndex} du jeu ${props.gameID}`)
    }

    return {
        gamePart: gamePartQuery.data,
        ...gamePartQuery
    }
}