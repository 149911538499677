import { Box } from "react-bulma-components";
import { useLevelClassicTransitions } from "../../levels/hooks/use-level-classic-transitions.hook";
import { useLevelLabyTransitions } from "../../levels/hooks/use-level-laby-transitions.hook";
import useLevels from "../../levels/hooks/use-levels.hook";
import { Subtitle } from "../../../common/layout/title/Subtitle";
import PreviousTransitions from "./components/PreviousTransitions";
import NextTransitions from "./components/NextTransitions";
import DimensionTransitions from "./components/DimensionTransitions";
import LabyrinthTransitions from "./components/LabyrinthTransitions";
import EditLinksZone from "./components/EditLinksZone";

interface LevelNavigationProps {
    levelName: string;
    levelID: number;
    gameID: number;
}

export default function LevelNavigationBox(props: Readonly<LevelNavigationProps>) {
    const transitionsQuery = useLevelClassicTransitions({
        "gameID": props.gameID,
        "levelName": props.levelName
    })

    const transitionsLabyQuery = useLevelLabyTransitions({
        "gameID": props.gameID,
        "levelName": props.levelName
    })

    const levelsOfGameQuery = useLevels(props.gameID)

    if (transitionsQuery.isLoading || levelsOfGameQuery.isLoading || transitionsLabyQuery.isLoading) {
        return <></>
    }

    const {previous, next} = transitionsQuery.data;
    const levelsOfGame = levelsOfGameQuery.data;

    const previousTransitions = previous;
    const nextTransitions = next.filter((transition) => transition.type === "simple");
    const dimensionsTransitions = next.filter((transition) => transition.type === "dimension");
    const labyrinthLevelsTransitions = transitionsLabyQuery.data;

    return <Box>
        <Subtitle centered>Transitions</Subtitle>
        <div className="columns">
            <PreviousTransitions gameID={props.gameID} previousTransitions={previousTransitions} />
            <NextTransitions gameID={props.gameID} nextTransitions={nextTransitions} />
        </div>
        <hr />
        <div className="columns">
            <DimensionTransitions gameID={props.gameID} dimensionsTransitions={dimensionsTransitions} />
            <LabyrinthTransitions gameID={props.gameID} labyrinthTransitions={labyrinthLevelsTransitions} />
            <EditLinksZone 
                key={props.levelID}
                gameID={props.gameID}
                levelName={props.levelName}
                levelsOfGame={levelsOfGame}
                nextTransitions={next}
                labyrinthLevelsTransitions={labyrinthLevelsTransitions}
            />
        </div>
    </Box>
}