import { GamePartParam, H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../../../core/api/useAPIMutation";

interface UseAddThumbnailSequenceProps extends GamePartParam {
    onSuccess: () => void
}

export default function useAddThumbnailSequence(props: Readonly<UseAddThumbnailSequenceProps>) {
    const addThumbnailSequenceMutation = useAPIMutation({
        "endpoint": H4A_API.v1.thumbnailSequences.add,
        "params": props,
        "onSuccess": props.onSuccess
    })

    if (addThumbnailSequenceMutation.isError) {
        throw new Error(addThumbnailSequenceMutation.error.message)
    }
    
    return addThumbnailSequenceMutation
}