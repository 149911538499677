import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { GamePartParam } from "../../game-parts.params";
import { ThumbnailSequence } from "../thumbnail-sequences.types";

export class ThumbnailSequenceListCall extends APIEndpointGET<GamePartParam, {}, ThumbnailSequence[]> {

    protected getVersion(): APIVersion {
        return 1
    }
    
    protected getUriBuilder(): URIBuilder<GamePartParam> {
        return new URIBuilder<GamePartParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}