import { Link } from "react-router-dom";
import styles from "./ThumbnailCardBox.module.css";
import H4AImage from "../../layout/image/image.component";

interface ThumbnailCardBoxProps {
    href: string;
    name: string;
    difficulty?: number;
    thumbnail_url: string;
    variable_difficulty?: boolean;
    level_count?: number;
}

export function ThumbnailCardBox(props: Readonly<ThumbnailCardBoxProps>) {

    let difficulty = `${Math.round(props.difficulty! * 100) / 100}/10`;
    if (! props.difficulty) {
        difficulty = props.variable_difficulty ? `Difficulté variable` : `Difficulté inconnue`;
    }

    const levelsCountContent = useLevelsCountContent(props.level_count);

    return <Link to={props.href} style={{"width": "232px"}}>
        <div className={styles['game-thumbnail-container'] + " hoverprimary box"}>
            <div>
                <div className="pb-2" style={{"borderBottom": "1px solid gray"}}>
                    <p className="has-text-white has-text-centered" style={{"whiteSpace": "initial"}}>
                        {props.name}
                    </p>
                </div>
                <div className="card-header-title is-centered has-text-centered">
                    <div>
                        {(props.difficulty !== undefined || props.variable_difficulty !== undefined) &&
                            <p>{difficulty}</p>
                        }
                        {props.level_count !== undefined &&
                            <p>{levelsCountContent}</p>
                        }
                    </div>
                </div>
            </div>
            <div className={styles["game-thumbnail-image-container"]}>
                <H4AImage src={props.thumbnail_url} alt="land_image" width={"192px"} height={"192px"} />
            </div>
        </div>
    </Link>

}

function useLevelsCountContent(levelCount: number | undefined) {
    if (levelCount === undefined) {
        return "";
    }

    let content = `${levelCount} niveaux`;
    if (levelCount === 1) {
        content = "1 niveau";
    }
    else if (levelCount === 0) {
        content = "Aucun niveau";
    }

    return content;
}