import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../hooks/use-api-query.hook";

export default function useLevels(gameID: number) {
    const levelsQuery = useAPIQuery({
        "endpoint": H4A_API.v1.games.levels,
        "params": {
            "gameID": gameID
        },
        "query": {}
    })

    return {
        ...levelsQuery,
        levels: levelsQuery.data
    }
}