import React from "react";
import FormFieldWrapper from "../form-field.component";

interface FormFieldSelectProps extends React.HTMLProps<HTMLSelectElement> {
    label: string;
    changeHandler: (event: string) => void;
    options: Array<{value: string, label: string}>;
}

export function FormFieldSelect({label, changeHandler, options, ...selectOptions}: Readonly<FormFieldSelectProps>) {

    function onChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        const values = Array.from(e.target.selectedOptions).map(option => option.value)
        changeHandler(values[0])
    }

    return <FormFieldWrapper label={label}>
        <div className={`select`}>
            <select onChange={onChangeHandler} {...selectOptions}>
                {options.map((option) => 
                    <option key={option.label} value={option.value}>{option.label}</option>
                )}
            </select>
        </div>
    </FormFieldWrapper>
}


interface FormFieldSelectMultipleProps extends React.HTMLProps<HTMLSelectElement> {
    label: string;
    changeHandler: (element: string[]) => void;
    options: Array<{value: string, label: string}>;
}

export function FormFieldSelectMultiple({label, changeHandler, options, ...selectOptions}: Readonly<FormFieldSelectMultipleProps>) {
    
        function onChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
            const values = Array.from(e.target.selectedOptions).map(option => option.value)
            changeHandler(values)
        }
    
        return <FormFieldWrapper label={label}>
            <div className={`select is-multiple`} >
                <select onChange={onChangeHandler} {...selectOptions} multiple>
                    {options.map((option) => 
                        <option key={option.label} value={option.value}>{option.label}</option>
                    )}
                </select>
            </div>
        </FormFieldWrapper>
}