import { H4A_API, LevelsParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../core/api/useAPIMutation";

interface UseVideoAddProps extends LevelsParam {
    closePopup?: () => void
}

export default function useVideoAdd(props: Readonly<UseVideoAddProps>) {
    const videoMutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.videos.add,
        "onSuccess": props.closePopup,
        "params": props
    })

    return videoMutation

}
           