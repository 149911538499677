import { Content } from "react-bulma-components";
import { Table } from "../../layout/table/table.component";
import { H4A_API } from "@hamm4all/shared";
import { Title } from "../../common/layout/title/Title";
import useAPIQuery from "../../hooks/use-api-query.hook";
import React from "react";

export default function FamiliesItems() {

    const {data: families} = useAPIQuery({
        "endpoint": H4A_API.v1.families.list,
        "params": {},
        "query": {}
    })

    if (!families) {
        return <></>
    }

    return <>
        <Title>Les familles</Title>
        <Content>
            <p>Les objets sont regroupés en différentes familles, que le joueur débloque grâce à des quêtes. Les données affichées sur cette page ont été directement extraites du jeu, et certaines peuvent sembler étranges, notamment certains coefficients :<br/>
            La <a href="#item1002">canne de Bobble</a>, coefficient 7, qui a une rareté à mi chemin entre un coefficient 4 et un coefficient 5.</p>
            <p>Les objets des familles <a href="#family1020">clés des glaces</a> et <a href="#family1024">batons de joie</a> sont marqués coefficient 6. Cependant, ces familles ne sont jamais débloquées et il est impossible de trouver ces objets dans les niveaux standards. Ces objets sont donc à considérer comme des coefficients 0.</p>
            {families.map(family => <React.Fragment key={family.id}>
                <h2 id={`family${family.id}`}>Famille {family.name.fr}</h2>
                <Table key={family.name.fr}>
                    <thead>
                        <tr>
                            <td>Image</td>
                            <td>Nom</td>
                            <td>Coefficient</td>
                            {family.id >= 1000 && <td>Valeur</td>}
                        </tr>
                    </thead>
                    <tbody>
                        {family.items.map((item: any) => 
                            <tr key={item.name.fr}>
                                <td>
                                    <img src={item.img} alt="item" style={{"maxWidth": "40px", "maxHeight": "40px"}} />
                                </td>
                                <td>
                                    {item.name.fr}
                                </td>
                                <td className="rarity">
                                    {item.rarity}
                                </td>
                                {family.id >= 1000 && <td>{item.value}</td>}
                            </tr>  
                        )}
                    </tbody>
                </Table>
            </React.Fragment>)}
        </Content>
    </>

}