import { useState } from "react"
import AddUpdateLevelModal from "../../../levels/components/level-add-update-modal.component"

interface LevelsAddButtonProps {
    gameID: number
}

export default function LevelsAddButton(props: Readonly<LevelsAddButtonProps>) {
    const [isAddLevelModalOpen, setIsAddLevelModalOpen] = useState(false)
    return <>
        <button className="button is-fullwidth is-info" onClick={() => setIsAddLevelModalOpen(true)}>
            Ajouter un niveau
        </button>
        <AddUpdateLevelModal
            type="add"
            gameID={props.gameID}
            modal={{
                "isOpen": isAddLevelModalOpen,
                "setIsOpen": setIsAddLevelModalOpen
            }} 
        />
    </>
}