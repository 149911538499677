import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ThumbnailLinkModal from "../../../../game-parts/game-part/components/ThumbnailLinkModal/ThumbnailLinkModal";
import { ReducedLevel, ThumbnailLink, ThumbnailSequenceParam } from "@hamm4all/shared";

interface ThumbnailLinkEditButtonProps extends ThumbnailSequenceParam {
    link: ThumbnailLink;
    position?: {x: number, y: number}
    levels: ReducedLevel[]
}

export default function ThumbnailLinkEditButton(props: Readonly<ThumbnailLinkEditButtonProps>) {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    return <>
        <button className="button is-small is-info" onClick={() => setIsEditModalOpen(true)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
        
        <ThumbnailLinkModal
            type="edit"
            gameID={props.gameID}
            gamePartIndex={props.gamePartIndex}
            sequenceIndex={props.sequenceIndex}
            isModalOpen={isEditModalOpen}
            setIsModalOpen={setIsEditModalOpen}
            initialPosition={props.position}
            levelsOfGame={props.levels}
            initialData={{
                "levelID": props.link.level.id,
                "thumbnailLinkIndex": props.link.orderNumber
            }}
        />
    </>
}