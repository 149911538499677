import { H4A_API } from "@hamm4all/shared"
import useAPIMutation from "../../../core/api/useAPIMutation"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface ItemDeleteButtonProps {
    itemID: string
}

export default function ItemDeleteButton({itemID}: Readonly<ItemDeleteButtonProps>) {
    const deleteItemMutation = useAPIMutation({
        "endpoint": H4A_API.v1.items.delete,
        "params": {
            "itemID": itemID
        }
    })

    return <>
        <button
            className="button is-danger"
            onClick={async () => {
                if (!window.confirm("Es-tu sûr de vouloir supprimer cet item ?")) {
                    return
                }

                deleteItemMutation.mutate({})
            }}
        >
            <FontAwesomeIcon icon={faTrash} />
        </button>
    </>
}