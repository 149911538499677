import { Title } from "../../common/layout/title/Title";

export default function ThanksPage() {
    return <>
        <Title>Remerciements</Title>
        <div className="content">
            <p>Pour ceux qui ne le savent pas, je suis BlessedRacc, j'ai 22 ans, et je suis le fondateur et administrateur de ce site. Mais ce que vous voyez n'est pas de ma main exclusivement (et heureusement) car sinon, je crois que j'en serais encore à implémenter les niveaux d'Officialfest.</p>
            <br/>
            <h3>Premièrement, parlons de <strong>yuuty</strong></h3>
            <p>Il est le co-fondateur du site et modérateur (en quelque sorte, toujours). On a fondé ce site ensemble, même si j'ai rapidement pris la programmation du site en main. Il avait je pense la mission la plus difficile. En effet, le site est quand même sorti fin novembre/début décembre 2018, donc avant la sortie officielle d'Eternalfest. Au début, on prévoyait de ne rien automatiser et de tout faire à la main (les screens, acheter le serveur, etc...), notamment parce qu'on ne savait pas faire. C'est à cet instant qu'Eternalfest a entendu parler de ce site, et a décider de nous aider, en hébergeant ce site sur leurs serveurs, et en nous fournissant tous les screens d'Hammerfest. <br/>Dans cette histoire, yuuty a eu droits aux tâches de répétition, comme par exemple faire les templates et les liens entre les niveaux A LA MAIN. Cela a duré au moins le premier mois de développement du site, et il a passé véritablement des dizaines d'heures à m'aider, et pour ça, je ne lui dirai jamais assez "merci", car je ne suis pas certain que j'aurais eu la motivation de continuer sans lui. Encore aujourd'hui, il se charge de mettre à jour la plupart des screens.</p>
            <br/>
            <h3>Ensuite, parlons de <strong>Demurgos</strong></h3>
            <p>En effet, il a assez vite hébergé le site, permettant de ne pas dépenser notre argent de poche d'adolescents, à moi et à yuuty. Mais en plus de ça, il m'a rapidement aidé pour tout ce qui est automatisation du site. Il a toujours su être là quand j'avais besoin d'aide, et pour ça, il mérite d'être énormément remercié. Sans lui, je crois que yuuty serait encore en train de faire des templates à la main.</p>
            <br/>
            <p>Passons maintenant aux autres joueurs ayant beaucoup contribué, et que j'ai envie de remercier</p>
            <ul>
                <li>Erneraude</li>
                <li>Moulins</li>
                <li>gmnemo</li>
                <li>assiette</li>
                <li>Nassimou</li>
                <li>Jean-Michel Tractoprank</li>
                <li>Zeya</li>
                <li>stratus</li>
                <li>maxdefolsch</li>
                <li>Lokapi</li>
                <li>Bya (Max8312083)</li>
                <li>fabianh</li>
                <li>babast88</li>
                <li>Jules93</li>
                <li>al4002</li>
                <li>eipi10</li>
                <li>Mva54</li>
                <li>pacmania</li>
                <li>Tolizebra</li>
                <li>Jjdu51</li>
                <li>Aksamyt</li>
                <li>Globox62</li>
            </ul>
            <p>Je remercie également l'ensemble des personnes sur le Discord du jeu, que j'ai appris à connaître et à apprécier. Vive Hammerfest !</p>
        </div>
    </>
}