import { useState } from "react";
import { Button } from "react-bulma-components";
import ThumbnailSequencesOrderModal from "./sequence/ThumbnailSequenceOrderModal";
import { GamePartParam, ThumbnailSequence } from "@hamm4all/shared";

interface EditSequencesOrderButtonProps extends GamePartParam {
    thumbnailSequences: ThumbnailSequence[];
}

export default function EditSequencesOrderButton(props: Readonly<EditSequencesOrderButtonProps>) {
    const [isEditingSequencesOrder, setIsEditingSequencesOrder] = useState(false)

    return <>
        <Button color="info" onClick={() => setIsEditingSequencesOrder(true)}>Changer l'ordre des séquences</Button>
        <ThumbnailSequencesOrderModal
            key={props.thumbnailSequences.map((sequence) => sequence.id).join("")} // This key is used to force the re-render of the modal when the sequences change. Otherwise, the modal would keep the old sequences, and mess up with the second update after a first update
            gameID={props.gameID}
            gamePartIndex={props.gamePartIndex}
            sequences={props.thumbnailSequences}
            isModalOpen={isEditingSequencesOrder}
            setIsModalOpen={setIsEditingSequencesOrder}
        />
    </>
}