export default function LevelGrid() {
    const verticalNumberOflines = 21
    const horizontalNumberOfLines = 25

    const verticalLines = []
    const horizontalLines = []

    const verticalStepPercent = 100 / verticalNumberOflines
    const horizontalStepPercent = 100 / horizontalNumberOfLines

    for (let i = 0; i < verticalNumberOflines; i++) {
        verticalLines.push(<line key={i} x1={`${i * verticalStepPercent + verticalStepPercent / 2}%`} y1={0} x2={`${i * verticalStepPercent + verticalStepPercent / 2}%`} y2={"100%"} stroke="purple" />)
    }

    for (let i = 0; i < horizontalNumberOfLines; i++) {
        horizontalLines.push(
            <line
                key={i}
                x1={`${verticalStepPercent / 2}%`}
                y1={`${i * horizontalStepPercent}%`}
                x2={`${100 - verticalStepPercent / 2}%`}
                y2={`${i * horizontalStepPercent}%`}
                stroke="purple"
            />
        )
    }

    return <svg style={{"position": "absolute", "zIndex": 500000, "top": "0px", "left": "0px", "width": "100%", "height": "98.9%"}}>
        {verticalLines}
        {horizontalLines}
    </svg>
}