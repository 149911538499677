interface FormFieldWrapperProps {
    label: string;
    children: any;
}

export default function FormFieldWrapper({label, children}: Readonly<FormFieldWrapperProps>) {
    return (
        <div className="field">
            <label className="label">
                {label}
                <div className="control">
                    {children}
                </div>
            </label>
        </div>
    )
}