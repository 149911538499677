import { Box } from "react-bulma-components";
import useDescription from "../../levels/hooks/use-description.hook";
import LevelDescriptionTitle from "./LevelDescriptionTitle";
import { LevelsParam } from "@hamm4all/shared";

interface LevelDescriptionProps extends LevelsParam {
}

export default function LevelDescription({gameID, levelName}: Readonly<LevelDescriptionProps>) {
    const descriptionQuery = useDescription({
        "gameID": gameID,
        "levelName": levelName
    })

    if (descriptionQuery.isLoading) {
        return <></>
    }

    const {descriptionData} = descriptionQuery

    return (
        <Box>
            <LevelDescriptionTitle gameID={gameID} levelName={levelName} description={descriptionData} />

            {descriptionData && <>
                <p>{descriptionData?.content}</p>
                <br/>
                <p className="has-text-right" style={{"fontStyle": "italic"}}>
                    Edité par {descriptionData?.updated_by.name}, le {new Date(descriptionData?.updated_at).toLocaleDateString("fr-FR")}
                </p>
            </>}
        </Box>
    )
}