import { LevelCoordinates, ReducedLevel, ThumbnailSequenceParam } from "@hamm4all/shared"
import { useState } from "react"
import { FormFieldSelect } from "../../../../../components/form/field-components/form-field-select.component"
import ModalForm from "../../../../../components/form/modal-form.component"
import useAddThumbnailLink from "../../hooks/links/useAddThumbnailLink"
import useUpdateThumbnailLink from "../../hooks/links/useUpdateThumbnailLink"

interface BaseThumbnailLinkModalProps extends ThumbnailSequenceParam {
    isModalOpen: boolean
    setIsModalOpen: (state: boolean) => void
    levelsOfGame: ReducedLevel[]
    initialPosition?: LevelCoordinates;
}

interface AddThumbnailLinkModalProps extends BaseThumbnailLinkModalProps {
    type: "create"
}

interface EditThumbnailLinkModalProps extends BaseThumbnailLinkModalProps {
    type: "edit"
    initialData: {
        levelID: number;
        thumbnailLinkIndex: number;
    }
}

type ThumbnailSequenceModalProps = AddThumbnailLinkModalProps | EditThumbnailLinkModalProps

export default function ThumbnailLinkModal(props: Readonly<ThumbnailSequenceModalProps>) {
    const [selectedLevelID, setSelectedLevelID] = useState(
        props.type === "create" ? props.levelsOfGame[0]?.id : props.initialData.levelID
    )

    const addThumbnailLinkMutation = useAddThumbnailLink({
        "gameID": props.gameID,
        "gamePartIndex": props.gamePartIndex,
        "sequenceIndex": props.sequenceIndex,
        "onSuccess": () => props.setIsModalOpen(false)
    })
    const editThumbnailLinkMutation = useUpdateThumbnailLink({
        "gameID": props.gameID,
        "gamePartIndex": props.gamePartIndex,
        "sequenceIndex": props.sequenceIndex,
        "thumbnailLinkIndex": props.type === "edit" ? props.initialData.thumbnailLinkIndex : props.levelsOfGame[0]?.id,
        "onSuccess": () => props.setIsModalOpen(false)
    })
    const effectiveMutation = props.type === "create" ? addThumbnailLinkMutation : editThumbnailLinkMutation

    return (
        <ModalForm
            isOpen={props.isModalOpen}
            setIsOpen={props.setIsModalOpen}
            title={props.type === "create" ? "Ajouter un lien de miniature" : "Modifier un lien de miniature"}
            handleSubmit={async () => effectiveMutation.mutate({
                "levelID": selectedLevelID,
                "position": props.initialPosition
            })}
        >
            <FormFieldSelect
                label="Niveau"
                options={props.levelsOfGame.map(level => ({
                    "value": level.id.toString(),
                    "label": level.name
                }))}
                value={selectedLevelID}
                changeHandler={value => setSelectedLevelID(+value)}
            />

        </ModalForm>
    )
}