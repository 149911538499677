import { HTTPMethod } from "../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { LevelsParam } from "../../../levels.params";
import { LevelScreenshot } from "../../screenshots.types";
import { LevelsScreenshotUploadBody } from "../screenshots.upload.body";


export class ScreenshotAddCall extends APIEndpointAction<LevelsParam, LevelsScreenshotUploadBody, LevelScreenshot> {
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("screenshots")
    }

    public override isFileForm(): boolean {
        return true
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<LevelsParam, FormData>): string {
        return `Une erreur s'est produite lors de l'ajout du screen du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<LevelsParam, LevelsScreenshotUploadBody, LevelScreenshot>): string {
        return `Le screen du niveau ${params.params.levelName} a bien été ajouté !`
    }
}