import styles from "./loading.module.css";
import { Progress } from "react-bulma-components";
import React from "react";
import { Title } from "../../common/layout/title/Title";

interface LoadingPageProps {
  title?: string;
}

export function LoadingPage(props: Readonly<LoadingPageProps>) {
  const ellapsedTime = useEllapsedTime();

  return (
    <div className={styles["loading-page"]}>
      <div className={styles["loading-page-content"]}>
        <div className="loading-page-title">
          <Title>{props.title ?? "Chargement"}</Title>
        </div>
        <div>
          <img src="/loading.gif" alt="Loading Tubz" />
        </div>
        <div className={styles["progress-bar-zone"]}>
          <Progress className={styles["progress-bar"]} max={100} color="info">Loading</Progress>
          <div>
            Temps écoulé : {ellapsedTime.toFixed(1)}s
          </div>
        </div>
      </div>
    </div>
  );
}

const SECONDS_BETWEEN_REFRESH = 0.1

function useEllapsedTime() {
  const [ellapsedTime, setEllapsedTime] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setEllapsedTime((ellapsedTime) => ellapsedTime + SECONDS_BETWEEN_REFRESH);
    }, SECONDS_BETWEEN_REFRESH * 1000);

    return () => clearInterval(interval);
  }, []);

  return ellapsedTime;
}