import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../../hooks/use-api-query.hook";

export default function useLand(landID: number) {
    const landQuery = useAPIQuery({
        "endpoint": H4A_API.v1.lands.get,
        "params": {
            "landID": landID
        },
        "query": {},
    })

    return {
        land: landQuery.data,
        ...landQuery
    }
}