import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Techniques from './pages/techniques/techniques';
import CrystalRules from './pages/rules/crystals';
import LettersRules from './pages/rules/letters';
import CacheEnnemiRule from './pages/rules/cacheEnnemi';
import OrdersRule from './pages/rules/orders';
import BugsRule from './pages/rules/bugs';
import FruitsEnemies from './pages/enemies/fruits';
import BossesEnemies from './pages/enemies/bosses';
import SpecialItems from './pages/items/special-items';
import FamiliesItems from './pages/items/families';
import QuestsItems from './pages/items/quests';
import CoefficientsItems from './pages/Items/ItemsPage';
import KeysItems from './pages/items/keys';
import LevelsCreations from './pages/creations/levels.creations';
import MusicsCreations from './pages/creations/musics.creations';
import ToolsCreation from './pages/creations/tools.creations';
import ThanksPage from './pages/thanks/thanks';
import ConnectPage from './pages/connect/connect.page';
import ProfilePage from './pages/users/profile';
import ChangelogPage from './pages/Changelog/Changelog';
import AuthRoute from './routes/AuthRoute';
import UsersPage from './pages/UsersPage/UsersPage';
import GamesPage from './pages/Games/GamesPage';
import GamePage from './pages/Game/GamePage';
import { H4A_API } from "@hamm4all/shared";
import LandsPage from './pages/games/lands/lands.page';
import LandPage from './pages/games/lands/land.page';
import GamePartsPage from './pages/GameParts/GamePartsPage';
import LevelPage from './pages/Level/LevelPage';
import LevelsPage from './pages/levels/page/levels.page';
import GamePart from './pages/GamePart/GamePartPage';
import Layout from './layout/layout';
import GameItemsPage from './pages/GameItems/GameItemsPage';
import { ErrorBoundary } from './components/error/error-boundary';

export default function H4ARoutes() {
    return <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="techniques" element={<Techniques />} />
            <Route path="rules">
                <Route path="crystals" element={<CrystalRules />} />
                <Route path="letters" element={<LettersRules />} />
                <Route path="cache-ennemi" element={<CacheEnnemiRule />} />
                <Route path="orders" element={<OrdersRule />} />
                <Route path="bugs" element={<BugsRule />} />
            </Route>
            <Route path="enemies">
                <Route path="fruits" element={<FruitsEnemies />} />
                <Route path="bosses" element={<BossesEnemies />} />
            </Route>
            <Route path="objects">
                <Route path="special" element={<SpecialItems />} />
                <Route path="families" element={<FamiliesItems />} />
                <Route path="quests" element={<QuestsItems />} />
                <Route path="coeffs" element={<CoefficientsItems />} />
                <Route path="keys" element={<KeysItems />} />
            </Route>
            <Route path="creations">
                <Route path="levels" element={<LevelsCreations />} />
                <Route path="musics" element={<MusicsCreations />} />
                <Route path="tools" element={<ToolsCreation />} />
            </Route>
            <Route path="thanks" element={<ThanksPage />} />
            <Route path="games">
                <Route index element={<GamesPage />} />
                <Route path={H4A_API.v1.games.get.getLastPartOfUri()}>
                    <Route index element={<GamePage />} />
                    <Route path="parts">
                        <Route index element={<GamePartsPage />} />
                        <Route path={H4A_API.v1.gameParts.get.getLastPartOfUri()} element={<GamePart />} />
                    </Route> 
                    <Route path="levels">
                        <Route index element={<LevelsPage />} />
                        <Route path={H4A_API.v1.levels.get.getLastPartOfUri()}>
                            <Route index element={<LevelPage />} />
                        </Route>
                    </Route>
                    <Route path="lands">
                        <Route index element={<LandsPage />} />
                    </Route>
                    <Route path="items" element={<GameItemsPage />} />
                </Route>
            </Route>

            <Route path="lands">
                <Route path={H4A_API.v1.lands.get.getLastPartOfUri()} element={<LandPage />} />
            </Route>

            <Route path="connect" element={<ConnectPage />} />
            <Route path="users" >
                <Route element={<AuthRoute grade={5} />}>
                    <Route index element={<UsersPage />} />
                </Route>
                <Route path={H4A_API.v1.user.profile.getLastPartOfUri()} element={<ProfilePage />} />
            </Route>
            <Route path="changelog" element={<ChangelogPage />} />
        </Route>
    </Routes>
} 