import { Content } from "react-bulma-components";
import { Table } from "../../layout/table/table.component";
import orders from "./orders.json"
import { Title } from "../../common/layout/title/Title";

export default function OrdersRule() {

    return <>
        <Title>Les Ordres</Title>
        <Content>
            <p>Les ordres sont un concept très important qu'il faut saisir vite en début de jeu.</p>
            <p>Chaque niveau contenant plus de deux ennemis possède un ordre prédéfini et fixe pour tuer ces ennemis. Cet ordre, s'il est respecté, fera tomber un cristal géant à la fin du niveau qui tombe au centre du niveau et passe à travers les plate-formes. Les points que donne ce gros cristal est égal à n²*2500, où n est le nombre d'ennemis initiaux dans le niveau. Autrement dit :</p>
        </Content>
        <Table>
            <thead>
                <tr>
                    <td>Cristal</td>
                    <td>Points</td>
                    <td>Ennemis</td>
                </tr>
            </thead>
            <tbody>
                {orders.map(order =>
                    <tr key={order.value}>
                        <td><img src={order.img} alt="ordre"/></td>
                        <td className="has-text-centered">{order.value}</td>
                        <td className="has-text-centered">{order.nbKilled}</td>
                    </tr>
                )}
            </tbody>
        </Table>
    </>
}