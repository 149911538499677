import { H4A_API, Item } from "@hamm4all/shared";
import { Link } from "react-router-dom";
import { LazyLoadImage, LazyLoadComponent } from "react-lazy-load-image-component";
import ItemImage from "../../common/components/ItemImage";
import { useAuth } from "../../context/user/user.context";
import Markdown from "react-markdown";
import ItemRowActionButtons from "./ItemRowActionButtons";

interface ItemRowProps {
    item: Item;
    displayGame: boolean;
}

export default function ItemRow({item, displayGame}: Readonly<ItemRowProps>) {
    const { hasRole } = useAuth()
    const pointsValue = usePointsValue(item)

    return (
        <tr>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>
                <ItemImage picture_id={item.picture_id} />
            </td>
            <td className="name">{item.name["fr"]}</td>
            <td>
                <div className="is-flex" style={{"alignItems": "center"}}>
                    <LazyLoadComponent>
                        <Markdown
                            components={{
                                p(props) {
                                    return <span className="icon-text">
                                        {props.children}
                                    </span>
                                },
                                img(props) {
                                    return <>
                                        &nbsp;
                                        <span className="icon">
                                            <LazyLoadImage
                                                src={props.src as string}
                                                alt={props.alt as string}
                                            />
                                        </span>
                                        &nbsp;
                                    </>
                                },
                            }}
                        >
                            {pointsValue + (item.effect ?? "")}
                        </Markdown>
                    </LazyLoadComponent>
                </div>
            </td>
            {displayGame &&
                <td>
                    <Link to={`/games/${item.game.id}`}>
                        {item.game.name}
                    </Link>
                </td>
            }
            {hasRole(H4A_API.v1.items.update.getRole()) && 
                <td>
                    <LazyLoadComponent>
                        <ItemRowActionButtons item={item} />
                    </LazyLoadComponent>
                </td>
            }
        </tr>
    )
}

function usePointsValue(item: Item) {
    const plural = item.value > 1 ? "s" : ""
    if (! item.value) {
        return ""
    }
    return `${item.value} point${plural}.\n`
}