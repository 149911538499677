import { ThumbnailSequence, ThumbnailLink, ThumbnailSequenceParam, H4A_API } from "@hamm4all/shared";
import { Subtitle, SubtitleWithEdit } from "../../../../common/layout/title/Subtitle";
import { useState } from "react";
import ThumbnailSequenceModal from "../../../game-parts/game-part/components/ThumbnailSequenceModal/ThumbnailSequenceModal";
import { useAuth } from "../../../../context/user/user.context";
import useThumbnailSequenceTitle from "../../hooks/useThumbnailSequenceTitle";

interface ThumbnailSequenceZoneTitleProps extends ThumbnailSequenceParam {
    thumbnailSequence: ThumbnailSequence;
    thumbnailLinks: ThumbnailLink[];
}

export default function ThumbnailSequenceZoneTitle(props: Readonly<ThumbnailSequenceZoneTitleProps>) {
    const {hasRole} = useAuth()
    const titleContent = useThumbnailSequenceTitle({
        "sequence": props.thumbnailSequence,
        "thumbnailLinks": props.thumbnailLinks,
        "gameID": props.gameID
    })
    return (
        hasRole(H4A_API.v1.thumbnailSequences.update.getRole()) ?
            <ThumbnailSequenceZoneTitleWithEdit title={titleContent} {...props} /> :
            <ThumbnailSequenceZoneTitleWithoutEdit title={titleContent}{...props} />
    ) 
}

interface ZoneTitleProps extends ThumbnailSequenceParam {
    title: string;
    thumbnailSequence: ThumbnailSequence;
}

function ThumbnailSequenceZoneTitleWithoutEdit(props: Readonly<ZoneTitleProps>) {
    return (
        <Subtitle>{props.title}</Subtitle>
    )
}

function ThumbnailSequenceZoneTitleWithEdit(props: Readonly<ZoneTitleProps>) {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    return <>
        <SubtitleWithEdit toggleModal={setIsEditModalOpen}>
            {props.title}
        </SubtitleWithEdit>
        <ThumbnailSequenceModal
            gameID={props.gameID}
            gamePartIndex={props.gamePartIndex}
            isModalOpen={isEditModalOpen}
            setIsModalOpen={setIsEditModalOpen}
            type="edit"
            initialData={{
                "firstLevelApart": props.thumbnailSequence.isFirstLevelApart,
                "hasLabyrinth": props.thumbnailSequence.hasLabyrinth
            }}
            sequenceIndex={props.sequenceIndex}
        />
    </>
}