import { LevelsVideo } from "@hamm4all/shared"

export default function LevelVideosDisplay(props: Readonly<LevelsVideo>) {
    return (
        <>
            <div style={{"paddingBottom": `56.25%`, "width": "100%", "height": "0", "position": "relative"}}>
                <iframe src={props.link} title="Vidéo"  allowFullScreen style={{
                    "position": "absolute",
                    "top": "0",
                    "left": "0",
                    "width": "100%",
                    "height": "100%",
                }}></iframe>
            </div>
            <p>Vies perdues : {props.livesLost}</p>
            <p>Contient l'ordre du niveau : {getBooleanResponse(props.hasOrder)}</p>
            <br/>
            <p className="has-text-right is-italic">Modifié par {props.user.name}, le {new Date(props.updatedAt).toLocaleDateString("fr-FR")}</p>
        </>
    )
}

function getBooleanResponse(value: boolean) {
    return value ?
        <span style={{ "color": "green" }}>Oui</span> :
        <span style={{ "color": "red" }}>Non</span>
}