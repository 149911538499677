import { HTTPMethod } from "../../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../../api.endpoint";
import { H4ARole } from "../../../../../api.roles";
import { GamePartParam } from "../../../game-parts.params";
import { ThumbnailSequenceOrderBody } from "../thumbnail-sequences.order.body";

export class ThumbnailSequenceOrderUpdateCall extends APIEndpointAction<GamePartParam, ThumbnailSequenceOrderBody, {}> {

    protected getVersion(): APIVersion {
        return 1
    }
    
    protected getUriBuilder(): URIBuilder<GamePartParam> {
        return new URIBuilder<GamePartParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences-order")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getQueryKeysToInvalidate(params: GamePartParam): (string | object)[][] {
        return [
            ["games", params.gameID.toString(), "parts", params.gamePartIndex.toString(), "thumbnail-sequences"]
        ]
    }

    public override getErrorMessage(params: APIEndpointErrorParams<GamePartParam, ThumbnailSequenceOrderBody>): string {
        return `Une erreur est survenue lors de la mise à jour de l'ordre des séquences d'images : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<GamePartParam, ThumbnailSequenceOrderBody, {}>): string {
        return `L'ordre des séquences d'images a bien été mis à jour !`
    }
}