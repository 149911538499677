import { LevelsParam } from "@hamm4all/shared";
import { LoadingPage } from "../../components/loading/loading.component";
import { Box, Columns } from "react-bulma-components";
import LevelNavigationBox from "./navigation/LevelNavigation";
import useURLParams from "../../hooks/use-url-params.hook";
import LevelDescription from "./description/LevelDescription";
import useGame from "../games/hooks/use-game.hook";
import useLevel from "../levels/hooks/use-level.hook";
import LevelScreenshotZone from "./screenshots/LevelScreenshotsZone";
import LevelVideos from "./videos/components/LevelVideosZone";
import LevelPageTitle from "./components/LevelPageTitle";
import LevelPageSubtitle from "./components/LevelPageSubtitle";

export default function LevelPage() {
    const {gameID, levelName} = useURLParams(LevelsParam)

    const {game, isLoading: isGameLoading} = useGame(gameID)
    const {level, isLoading: isLevelLoading} = useLevel({
        "gameID": gameID,
        "levelName": levelName
    })

    if (isLevelLoading || isGameLoading ) {
        return <LoadingPage />
    }

    return <>
        <LevelPageSubtitle game={game} level={level} />
        <LevelPageTitle gameID={gameID} level={level} />
        <br/>
        <LevelNavigationBox levelID={level.id} levelName={level.name} gameID={gameID} />

        <Box>
            <Columns>
                <Columns.Column>
                    <LevelScreenshotZone gameID={gameID} levelName={levelName} />
                </Columns.Column>
                <Columns.Column>
                    <LevelDescription gameID={gameID} levelName={levelName} />
                    <LevelVideos gameID={gameID} levelName={levelName} />
                </Columns.Column>
            </Columns>
        </Box>
    </>
}