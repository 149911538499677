import { H4A_API, LevelsParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../core/api/useAPIMutation";

interface UseUploadNewScreenshotProps extends LevelsParam {
    onSuccess: () => void;
}

export default function useUploadNewScreenshot(props: UseUploadNewScreenshotProps) {
    const mutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.screenshots.add,
        "onSuccess": props.onSuccess,
        "params": props
    })

    return mutation
}