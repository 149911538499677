import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { Game } from "../games.types";
import { GamesAddBody } from "./games.add.body";


export class GamesAddCall extends APIEndpointAction<{}, GamesAddBody, Game> {
    
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder()
            .addPath("games")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.MODERATOR
    }

    public override getErrorMessage(params: APIEndpointErrorParams<{}, GamesAddBody>): string {
        return `Une erreur est survenue lors de l'ajout du jeu ${params.body.name} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<{}, GamesAddBody, Game>): string {
        return `Le jeu ${params.body.name} a bien été ajouté !`
    }
}