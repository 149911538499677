import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";

export class LogoutCall extends APIEndpointAction<{}, {}, {}> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("auth")
            .addPath("logout")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<{}, {}>): string {
        return `Une erreur s'est produite lors de la déconnexion : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<{}, {}>): string {
        return `Tu as bien été déconnecté.`
    }
}