import { H4A_API, ThumbnailSequenceParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../../core/api/useAPIMutation";

interface UseAddThumbnailLinkProps extends ThumbnailSequenceParam {
    onSuccess: () => void
}

export default function useAddThumbnailLink(props: Readonly<UseAddThumbnailLinkProps>) {
    const addThumbnailLinkMutation = useAPIMutation({
        "endpoint": H4A_API.v1.thumbnailSequences.links.add,
        "params": props,
        "onSuccess": props.onSuccess
    })

    if (addThumbnailLinkMutation.isError) {
        throw new Error(`Impossible d'ajouter le lien à la séquence de miniatures de la partie ${props.gamePartIndex} du jeu ${props.gameID}: ${addThumbnailLinkMutation.error.message}`)
    }

    return addThumbnailLinkMutation
}