import { H4A_API, ThumbnailSequenceParam } from "@hamm4all/shared"
import useAPIMutation from "../../../../../core/api/useAPIMutation"

interface UseUpdateThumbnailSequenceProps extends ThumbnailSequenceParam {
    onSuccess: () => void
}

export default function useUpdateThumbnailSequence(props: Readonly<UseUpdateThumbnailSequenceProps>) {
    const updateThumbnailSequenceMutation = useAPIMutation({
        "endpoint": H4A_API.v1.thumbnailSequences.update,
        "params": props,
        "onSuccess": props.onSuccess
    })
    
    return updateThumbnailSequenceMutation
}