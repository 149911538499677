import { Item } from "@hamm4all/shared";
import { FormFieldSelectMultiple } from "../../../components/form/field-components/form-field-select.component";
import useItemGamesWithoutDuplicates from "../hooks/useItemGamesWithoutDuplicates";

interface ItemsSorterByGameProps {
    setFilteredGames: (games: string[]) => void;
    items: Item[]
}

export default function ItemsSorterByGame({items, setFilteredGames}: Readonly<ItemsSorterByGameProps>) {
    const gamesWithoutDuplicates = useItemGamesWithoutDuplicates(items)

    return (
        <div className="box">
            <FormFieldSelectMultiple
                label="Filtrer les objets par jeu"
                name="games"
                options={gamesWithoutDuplicates}
                changeHandler={setFilteredGames}
            />
        </div>
    )
}