import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../hooks/use-api-query.hook";

interface UseDescriptionProps {
    gameID: number;
    levelName: string;
}

export default function useDescription(props: UseDescriptionProps) {
    const {gameID, levelName} = props
    const levelQuery = useAPIQuery({
        "endpoint": H4A_API.v1.levels.description.list,
        "params": {
            "gameID": gameID,
            "levelName": levelName
        },
        "query": {
            "limit": 1,
        }
    })

    const latestDescription = levelQuery.data?.[0]

    return {
        ...levelQuery,
        descriptionData: latestDescription
    }
}