import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { FileParam } from "../files.params";
import { H4AFile } from "../files.types";

export class FileGetCall extends APIEndpointGET<FileParam, {}, H4AFile> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<FileParam> {
        return new URIBuilder<FileParam>()
            .addPath("files")
            .addParam("id")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}