import packageJson from "../../../package.json";

export default function Version() {
    return <span style={{
        position: "fixed",
        bottom: "2em",
        right: "2em",
        padding: "5px",
        color: "white"
    }}>
        {packageJson.version}
    </span>
}