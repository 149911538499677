import { ThumbnailSequenceParam, ThumbnailLink, H4A_API, ReducedLevel } from "@hamm4all/shared";
import styles from "./ThumbnailLinkControlButtons.module.css"
import ThumbnailLinkEditButton from "./ThumbnailLinkEditButton";
import ThumbnailLinkDeleteButton from "./ThumbnailLinkDeleteButton";
import { useAuth } from "../../../../../context/user/user.context";

interface ThumbnailLinkControlButtonsProps extends ThumbnailSequenceParam {
    link: ThumbnailLink;
    position?: {x: number, y: number}
    levels: ReducedLevel[]
}

export default function ThumbnailLinkControlButtons(props: Readonly<ThumbnailLinkControlButtonsProps>) {
    const {hasRole} = useAuth()

    return <div className={`buttons has-addons ${styles["thumbnail-link-control-buttons"]}`}>
        {hasRole(H4A_API.v1.thumbnailSequences.links.update.getRole()) &&  
            <ThumbnailLinkEditButton {...props} />
        }
        {hasRole(H4A_API.v1.thumbnailSequences.links.delete.getRole()) &&
            <ThumbnailLinkDeleteButton {...props} thumbnailLinkIndex={props.link.orderNumber} />
        }
    </div>
}