import { H4A_API, ThumbnailSequenceParam } from "@hamm4all/shared";
import useAPIQuery from "../../../../../hooks/use-api-query.hook";

interface UseThumbnailLinksProps extends ThumbnailSequenceParam {

}

export default function useThumbnailLinks(props: Readonly<UseThumbnailLinksProps>) {

    const thumbnailLinksQuery = useAPIQuery({
        "endpoint": H4A_API.v1.thumbnailSequences.links.list,
        "params": {
            "gameID": props.gameID,
            "gamePartIndex": props.gamePartIndex,
            "sequenceIndex": props.sequenceIndex
        },
        "query": {}
    })

    if (thumbnailLinksQuery.isError) {
        throw new Error(`Impossible de charger les liens des miniatures pour la séquence ${props.sequenceIndex} de la partie ${props.gamePartIndex} du jeu ${props.gameID}`)
    }

    return {
        "thumbnailLinks": thumbnailLinksQuery.data,
        ...thumbnailLinksQuery
    }
}