import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { ItemParam } from "../items.params";
import { ItemsPictureUpdateBody } from "./items.picture.update.body";

export class ItemsPictureUpdateCall extends APIEndpointAction<ItemParam, ItemsPictureUpdateBody, {}> {
    
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<ItemParam>()
            .addPath("items")
            .addParam("itemID")
            .addPath("picture")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public override isFileForm(): boolean {
        return true
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getQueryKeysToInvalidate(params: ItemParam): (string | object)[][] {
        return [
            ["items"],
        ]
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ItemParam, FormData>): string {
        return `Une erreur s'est produite lors de la mise à jour de l'image de l'objet : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ItemParam, {}>): string {
        return `L'image de l'objet a bien été mise à jour.`
    }
}