import { useState } from "react";
import ChangelogAddModal from "./ChangelogAddModal";

interface ChangelogAddButtonProps {

}

export default function ChangelogAddButton(props: ChangelogAddButtonProps) {
    const [isChangelogAddModalOpen, setIsChangelogAddModalOpen] = useState(false);

    return <>
        <button className="button is-info" onClick={() => setIsChangelogAddModalOpen(true)}>
            Ajouter une version
        </button>

        <ChangelogAddModal
            isModalOpen={isChangelogAddModalOpen}
            setIsModalOpen={setIsChangelogAddModalOpen}
        />
    </>
}