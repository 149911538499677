import { Content } from "react-bulma-components";
import { Title } from "../../common/layout/title/Title";
import { Table } from "../../layout/table/table.component";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bugs from "./bugs.json"

export default function BugsRule() {

    return <>
        <Title>Les bugs</Title>
        <Content>
            <p>Bien qu'Hammerfest soit un des meilleurs jeux que cette Terre n'ait jamais abrité, il est cependant très riche en bugs de diverses sortes. Ici sont répertoriés tous les bugs connus, et s'ils ont un fix sur Eternalfest.</p>
        </Content>
        <Table>
            <thead>
                <tr>
                    <td>Description</td>
                    <td>BugFix</td>
                </tr>
            </thead>
            <tbody>
                {bugs.map(bug => <tr key={bug.content}>
                    <td>{bug.content}</td>
                    <td className="has-text-centered">{bug.hasFix ?
                        <FontAwesomeIcon icon={faCheck} className="has-text-success" /> :
                        <FontAwesomeIcon icon={faTimes} className="has-text-danger" />}
                    </td>
                </tr>)}
            </tbody>
        </Table>
    </>

}