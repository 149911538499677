import { HTTPMethod } from "../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { GameParam } from "../../games.params";
import { Land } from "../lands.types";
import { LandsAddBody } from "./lands.add.body";

export class LandsAddCall extends APIEndpointAction<GameParam, LandsAddBody, Land> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("lands");
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<GameParam, LandsAddBody>): string {
        return `Une erreur est survenue lors de l'ajout de la contrée ${params.body.name} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<GameParam, LandsAddBody, Land>): string {
        return `La contrée ${params.body.name} a bien été ajoutée !`
    }
}