import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { Level } from "../levels.types";
import { LevelsParam } from "../levels.params";
import { LevelsUpdateBody } from "./levels.update.body";
import { HTTPMethod } from "../../../api.call";
import { H4ARole } from "../../api.roles";

export class LevelsUpdateCall extends APIEndpointAction<LevelsParam, LevelsUpdateBody, Level> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<LevelsParam, LevelsUpdateBody>): string {
        return `Une erreur s'est produite lors de la mise à jour du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<LevelsParam, LevelsUpdateBody, Level>): string {
        return `Le niveau ${params.params.levelName} a bien été mis à jour.`
    }
}