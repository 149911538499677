import { useState } from "react";
import { H4A_API, GameParam } from "@hamm4all/shared";
import CardZone from "../../../components/card-zone/card-zone.component";
import ModalForm from "../../../components/form/modal-form.component";
import { FormFieldInput, FormFieldTextArea } from "../../../components/form/field-components/form-field-input.component";
import useURLParams from "../../../hooks/use-url-params.hook";
import useGame from "../hooks/use-game.hook";
import { LoadingPage } from "../../../components/loading/loading.component";
import useLands from "./hooks/use-lands.hook";
import { Link } from "react-router-dom";
import { Subtitle } from "../../../common/layout/title/Subtitle";
import { Title } from "../../../common/layout/title/Title";
import useAPIMutation from "../../../core/api/useAPIMutation";
import { ThumbnailCardBox } from "../../../components/ThumbnailCardBox/ThumbnailCardBox";


export default function LandsPage() {
    const {gameID} = useURLParams(GameParam)
    const [isAddLandModalOpen, setIsAddLandModalOpen] = useState(false);

    const {game, isLoading: isGameLoading} = useGame(gameID)
    const {lands, isLoading: isLandsLoading} = useLands(gameID)

    if (isGameLoading || isLandsLoading) {
        return <LoadingPage />;
    }

    return <>
        <Title>Contrées</Title>
        <Subtitle centered>
            <Link to={`/games/${gameID}`}>Retour</Link>
        </Subtitle>
        <br />
        <br />
        <p>Ici sont répertoriées toutes les contrées du jeu {game.name}.</p>
        <br />
        <CardZone 
            roleToAdd={1}
            toggleModal={setIsAddLandModalOpen}
            modal={<AddLandModal gameID={gameID} isOpen={isAddLandModalOpen} setIsOpen={setIsAddLandModalOpen} />}
        >
            {lands.map((land, index) => 
                <ThumbnailCardBox
                    key={land.id}
                    href={`/lands/${land.id}`}
                    name={land.name === "unnamed" ? "Contrée sans nom" : land.name}
                    thumbnail_url={game.thumbnail_id}
                />
            )}
        </CardZone>
    </>
}

function AddLandModal(props: Readonly<{gameID: number, isOpen: boolean, setIsOpen: (value: boolean) => void}>) {
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")

    const addLandMutation = useAPIMutation({
        "endpoint": H4A_API.v1.lands.add,
        "params": {
            "gameID": props.gameID
        },
        "onSuccess": () => props.setIsOpen(false),
    })

    return (
        <ModalForm
            title="Ajouter une contrée"
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            handleSubmit={async () => addLandMutation.mutate({
                "name": name,
                "description": description
            })}
        >
            <FormFieldInput
                label="Nom"
                value={name}
                changeHandler={setName}
                required
                placeholder="Nom"
            />

            <FormFieldTextArea
                label="Description"
                value={description}
                changeHandler={setDescription}
                placeholder="Description"
            />
        </ModalForm>
    )
}