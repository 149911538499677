import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { LevelsParam } from "../../levels.params";
import { LevelsDescriptionAddBody } from "./description.body";
import { LevelDescription } from "../description.types";
import { HTTPMethod } from "../../../../api.call";
import { H4ARole } from "../../../api.roles";


export class LevelsDescriptionAddCall extends APIEndpointAction<LevelsParam, LevelsDescriptionAddBody, LevelDescription> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("descriptions")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<LevelsParam, LevelsDescriptionAddBody>): string {
        return `Une erreur s'est produite lors de l'ajout de la description du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<LevelsParam, LevelsDescriptionAddBody, LevelDescription>): string {
        return `La description du niveau ${params.params.levelName} a bien été ajoutée !`
    }
}