import { Link } from "react-router-dom";
import { Title } from "../common/layout/title/Title";

export default function ForbiddenPage() {
    return <>
        <Title>Accès Interdit</Title>
        <br/>
        <p>Oups, j'ai l'impression que tu n'as pas le droit d'être ici...</p>
        <p>Vérifie que tu es bien connecté. Si ça ne fonctionne toujours pas, ça veut dire que tu n'as pas un rang assez élevé sur le site pour voir le contenu de cette page :eyes:</p>
        <p>Allez, maintenant, <Link to="/">Ouste !</Link></p>
    </>
}