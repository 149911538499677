import { Fragment } from "react";
import { H4A_API } from "@hamm4all/shared";
import useChangelog from "./hooks/useChangelog";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";
import { getLocalDateDisplayFromDateString } from "../../common/utils/date-utils";
import { LoadingPage } from "../../components/loading/loading.component";
import { useAuth } from "../../context/user/user.context";
import Markdown from "react-markdown";
import { Content } from "react-bulma-components";
import ChangelogAddButton from "./components/ChangelogAddButton";

export default function ChangelogPage() {
    const {hasRole} = useAuth();

    const changelogQuery = useChangelog();

    if (changelogQuery.isPending) {
        return <LoadingPage />
    }

    const {changelog} = changelogQuery;

    return <>
        <Title>Changelog</Title>
        <br/>
        <br/>
        <Subtitle>Dernières versions</Subtitle>
        {hasRole(H4A_API.v1.changelog.add.getRole()) &&
            <ChangelogAddButton />
        }
        {changelog.map((changelog) => <Fragment key={changelog.versionName}>
            <Subtitle>
                Version {changelog.versionName} ({getLocalDateDisplayFromDateString(changelog.createdAt)}) :
            </Subtitle>
            <Content>
                <Markdown>
                    {changelog.description.replace(/\\n/g, "\n- ")}
                </Markdown>
            </Content>
            <br/>
        </Fragment>)}
    </>
}