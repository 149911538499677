import { IsInt, IsString, Length } from "class-validator";

export class GamePartsAddBody {
    
    @IsString()
    @Length(1, 128)
    declare name: string;

    @IsString()
    @Length(1, 1024)
    declare description: string;
}