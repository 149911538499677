import { AxiosResponse } from "axios";
import { APIResponse, HTTPMethod, apiCall, AxiosProps } from "./api.call";
import { parseH4AUrl } from "./api.urls";
import { H4ARole } from "./routes/api.roles";

export type APIVersion = 0 | 1

export class URIBuilder<Params extends object> {

    private uriParts: string[];

    constructor() {
        this.uriParts = [];
    }

    addParam(param: Extract<keyof Params, string>) {
        this.addPath(`:${param}`);
        return this;
    }

    addPath(path: string) {
        this.uriParts.push(`${path}`);
        return this;
    }

    build() {
        return "/" + this.uriParts.join("/");
    }

    getLastPart() {
        return this.uriParts[this.uriParts.length - 1];
    }

    getLastGroupPart() {
        return this.uriParts[this.uriParts.length - 2];
    }
}

abstract class APIEndpoint<
    ParamsType extends object = {},
    BodyType extends object = {},
    ReturnType extends object = {},
    QueryType extends object = {}
> {

    protected abstract getVersion(): APIVersion;
    protected abstract getUriBuilder(): URIBuilder<ParamsType>;
    protected abstract getHeaders(): object;
    public abstract getMethod(): HTTPMethod;
    public abstract getRole(): H4ARole;

    protected getRawQueryKeys(params: ParamsType): (string | object)[] {
        const uri = this.getParsedUri(params);
        return [...uri.split("/").slice(3)] // Remove the first 3 elements (first is empty, second is `/api`, third is the version)
    }

    public getAdditionalHeaders() {
        return {};
    }

    public isSilent() {
        return false;
    }

    public getCallFunction(params: ParamsType, query: QueryType) {
        return (body: BodyType) => {
            return apiCall<ReturnType>({
                "url": this.getParsedUri(params),
                "body": body,
                "method": this.getMethod(),
                "query": query,
                "isSilent": this.isSilent(),
                "headers": {
                    ...this.getHeaders(),
                    ...this.getAdditionalHeaders()
                }
            });
        }
    }

    /**
     * Fetches the raw-form URI (starting from `/api`)
     * @example `/api/v1/users/:user`
     * @returns 
     */
    getFullRawUri() {
        const version = this.getVersion();
        let apiPrefix = "/api";
        if (version > 0) {
            apiPrefix += `/v${version}`;
        }
        return `${apiPrefix}${this.getUriBuilder().build()}`;
    }

    /**
     * Fetches the parsed URI (starting from `/api`)
     * @example `/api/v1/users/46`
     * @returns 
     */
    getParsedUri(uriParseElements: ParamsType) {
        const builtUri = this.getFullRawUri();
        return parseH4AUrl(builtUri, uriParseElements)
    }

    /**
     * Get the very last part of the URI
     * @returns 
     */
    getLastPartOfUri() {
        return this.getUriBuilder().getLastPart()
    }

    /**
     * Get the URI path of the group of this endpoint
     * @example `/api/v1/users/:user_id` --> `users`
     */
    getUriGroupPath() {
        return this.getUriBuilder().getLastGroupPart();
    }

    getUri() {
        return this.getUriBuilder().build();
    }
}

export abstract class APIEndpointGET<
    ParamsType extends object = {},
    QueryType extends object = {},
    ReturnType extends object = {}
> extends APIEndpoint<ParamsType, {}, ReturnType, QueryType> {
    public getQueryKeys(params: ParamsType, query: QueryType) {
        const queryKeys = this.getRawQueryKeys(params);
        if (Object.keys(query).length > 0) {
            queryKeys.push(query);
        }
        return queryKeys;
    }

    public override getMethod(): HTTPMethod {
        return "GET";
    }

    protected getHeaders(): object {
        return {}
    }

    public async call(callFunction: (props: AxiosProps<{}, QueryType>) => Promise<ReturnType>, params: ParamsType, query: QueryType): Promise<ReturnType> {
        return callFunction({
            "url": this.getParsedUri(params),
            "body": {},
            "method": this.getMethod(),
            "query": query,
            "isSilent": this.isSilent(),
            "headers": {
                ...this.getHeaders(),
                ...this.getAdditionalHeaders()
            }
        });
    }
}

export interface APIEndpointErrorParams<
    ParamsType extends object = {},
    BodyType extends object = {},
> {
    params: ParamsType
    body: BodyType
    error: AxiosResponse<{message: string}>
}

export interface APIEndpointSuccessParams<
    ParamsType extends object = {},
    BodyType extends object = {},
    ReturnType extends object = {}
> {
    params: ParamsType,
    body: BodyType,
    response: AxiosResponse<ReturnType>
}

export abstract class APIEndpointAction<
    ParamsType extends object = {},
    BodyType extends object = {},
    ReturnType extends object = {}
> extends APIEndpoint<ParamsType, BodyType, ReturnType, {}> {
    public getQueryKeysToInvalidate(params: ParamsType) {
        const queryKeys = this.getRawQueryKeys(params);
        if (this.getMethod() === "DELETE" || this.getMethod() === "PUT") {
            queryKeys.pop();
        }
        return [
            queryKeys
        ];
    }

    public isFileForm() {
        return false;
    }

    protected getHeaders(): object {
        return {
            "Content-Type": this.isFileForm() ? "multipart/form-data" : "application/json"
        }
    }

    public getErrorMessage(params: APIEndpointErrorParams<ParamsType, BodyType>) {
        let message = `${params.error}`;
        if (params.error?.data?.message) {
            message = `${params.error.data.message} [${params.error.status}]`;
        }

        return message;
    }

    public getSuccessMessage(params: APIEndpointSuccessParams<ParamsType, BodyType, ReturnType>) {
        return "L'opération a réussi";
    }
}
