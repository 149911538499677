import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentProps, forwardRef, useEffect, useState } from "react";
import { getBlobPathFromId } from "../../utilities/blobs/blobs.utilities";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface H4AImageProps extends ComponentProps<"img"> {
    fallbackSrc?: string;
}

function H4ARawImage(props: Readonly<H4AImageProps>, ref: React.Ref<HTMLImageElement>) {
    let {fallbackSrc, src, onLoad, ...imgProps} = props

    const [loadingError, setLoadingError] = useState(
        src === undefined || src === "" || src === null
    )

    const realRef = ref ?? props.ref
    fallbackSrc ??= "/assets/min/unknown.png"
    return <>
        {loadingError ?
            <LazyLoadImage {...imgProps} src={fallbackSrc} alt="" /*ref={realRef}*/ width={"100%"} /> :
            <>
                <LazyLoadImage
                    width={"100%"}
                    src={getBlobPathFromId(src!)}
                    {...imgProps}
                    onError={() => setLoadingError(true)}
                    alt="Loading"
                    effect={"blur"}
                    /*ref={realRef}*/
                />
            </>
        }
    </>
}

const H4AImage = forwardRef<HTMLImageElement, H4AImageProps>(H4ARawImage)
export default H4AImage