import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../hooks/use-api-query.hook";

interface UseGamePartsProps {
    gameID: number
}

export default function useGameParts(props: UseGamePartsProps) {
    const gamePartsQuery = useAPIQuery({
        "endpoint": H4A_API.v1.gameParts.list,
        "params": {
            "gameID": props.gameID
        },
        "query": {}
    })

    if (gamePartsQuery.isError) {
        throw new Error(`Une erreur s'est produite lors de la récupération des parties de jeu du jeu ${props.gameID}`)
    }

    return {
        gameParts: gamePartsQuery.data,
        ...gamePartsQuery
    }
}