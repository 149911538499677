import { Content } from "react-bulma-components";
import levels from "./levels.creations.json"
import { Title } from "../../common/layout/title/Title";

export default function LevelsCreations() {
    return <>
        <Title>Créations - Niveaux</Title>
        <Content>
            <p>Dans cet onglet de menu, je mettrai les liens des blogs des niveaux créés par la communauté !<br/>Les voici :</p>
            <ul>
                {levels.map((blog) => 
                    <li key={blog.owner}>
                        <a href={blog.link}>{blog.owner}</a>
                    </li>
                )}
            </ul>
        </Content>
    </>
}