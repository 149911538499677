import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint"
import { H4ARole } from "../../../api.roles"
import { GamePartParam } from "../game-parts.params"
import { GamePart } from "../game-parts.types"

export class GamePartsGetCall extends APIEndpointGET<GamePartParam, {}, GamePart> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<GamePartParam> {
        return new URIBuilder<GamePartParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")

    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
    
}