import { Title } from "../../common/layout/title/Title";
import { H4A_API } from "@hamm4all/shared";
import { useState } from "react";
import useAPIQuery from "../../hooks/use-api-query.hook";
import ItemsPageIntroduction from "./components/ItemsPageIntroduction";
import ItemsSorterByGame from "./components/ItemsSorterByGame";
import ItemsTable from "../../components/ItemTable/ItemTable";

export default function ItemsPage() {
    console.time("ItemsPage")
    const [filteredGames, setFilteredGames] = useState<string[]>([])
    const {data: items, isLoading} = useAPIQuery({
        "endpoint": H4A_API.v1.items.list,
        "params": {},
        "query": {},
    })

    if (isLoading) {
        return <>
            <Title>Les coefficients</Title>
            <ItemsPageIntroduction />
        </>
    }

    items.sort((a, b) => { return b.coefficient - a.coefficient })

    const displayedItems = 
        filteredGames.length > 0
            ? items.filter((item) => filteredGames.includes(item.game.id.toString()))
            : items

    console.timeEnd("ItemsPage")
    return <>
        <Title>Les coefficients</Title>
        <ItemsPageIntroduction />
        <ItemsSorterByGame items={items} setFilteredGames={setFilteredGames} />
        <ItemsTable items={displayedItems} displayGame={true} />
    </>
}