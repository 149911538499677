import { ThumbnailSequence, ThumbnailSequenceParam } from "@hamm4all/shared";
import useThumbnailLinks from "../../../game-parts/game-part/hooks/links/useThumbnailLinks";
import { Box } from "react-bulma-components";
import LevelThumbnailZone from "../links/LevelThumbnailZone";
import ThumbnailSequenceZoneTitle from "./ThumbnailSequenceZoneTitle";

export interface ThumbnailSequenceZoneProps extends ThumbnailSequenceParam {
    thumbnailSequence: ThumbnailSequence
}

export default function ThumbnailSequenceZone(props: Readonly<ThumbnailSequenceZoneProps>) {
    const {thumbnailLinks, isLoading} = useThumbnailLinks({
        "gameID": props.gameID,
        "gamePartIndex": props.gamePartIndex,
        "sequenceIndex": props.sequenceIndex
    })

    if (isLoading) {
        return <></>
    }

    return (
        <div className="block">
            <Box>
                <ThumbnailSequenceZoneTitle
                    thumbnailSequence={props.thumbnailSequence}
                    gameID={props.gameID}
                    gamePartIndex={props.gamePartIndex}
                    sequenceIndex={props.sequenceIndex}
                    thumbnailLinks={thumbnailLinks}
                />

                <LevelThumbnailZone
                    gameID={props.gameID}
                    gamePartIndex={props.gamePartIndex}
                    sequenceIndex={props.sequenceIndex}
                    links={thumbnailLinks}
                    sequence={props.thumbnailSequence}
                />
            </Box>
        </div>
    )
}