import { H4A_API, Level, LevelsParam, LevelTransitionClassic, LevelTransitionsLaby } from "@hamm4all/shared";
import { Subtitle } from "../../../../common/layout/title/Subtitle";
import { useAuth } from "../../../../context/user/user.context";
import ClassicLinksEditButton from "./ClassicLinksEditButton";
import LabyLinksEditButton from "./LabyLinksEditButton";

interface EditLinksZoneProps extends LevelsParam {
    levelsOfGame: Level[];
    nextTransitions: LevelTransitionClassic[];
    labyrinthLevelsTransitions: LevelTransitionsLaby;
}

export default function EditLinksZone(props: Readonly<EditLinksZoneProps>) {
    const {hasRole} = useAuth();
    return (
        <div className="column has-text-centered">
            <Subtitle centered>Modifier</Subtitle>
            <div className="buttons is-centered">
                {hasRole(H4A_API.v1.levels.transitions.classic.update.getRole()) &&
                    <ClassicLinksEditButton {...props} />
                }
                {hasRole(H4A_API.v1.levels.transitions.laby.update.getRole()) &&
                    <LabyLinksEditButton {...props} />
                }
            </div>
        </div>
    )
}