import { H4A_API } from "@hamm4all/shared";
import { useState } from "react";
import { Title } from "../../common/layout/title/Title";
import { Columns, Container, Form } from "react-bulma-components";
import useAPIQuery from "../../hooks/use-api-query.hook";
import { LoadingPage } from "../../components/loading/loading.component";
import RandomLevelButton from "./components/RandomLevelButton";
import GamesZoneDisplay from "./components/GamesZoneDisplay";
import GamesSorter, { SortingTypes } from "./components/GamesSorter";
import GameThumbnail from "../../common/components/GameThumbnail/GameThumbnail";

export default function GamesPage() {
    const [search, setSearch] = useState("");
    const [sortingMethod, setSortingMethod] = useState<SortingTypes>("difficulty");

    const gamesQuery = useAPIQuery({
        "endpoint": H4A_API.v1.games.list,
        "params": {},
        "query": {}
    })

    if (gamesQuery.isLoading) {
        return <LoadingPage />;
    }

    const games = gamesQuery.data;
    const officialfest = games.find((game) => game.name === "Les Cavernes de Hammerfest")

    return <>
        <Title>Jeux</Title>
        <br/>
        <br/>
        <p>Voici tous les jeux, Hammerfest et Eternalfest confondus, triés par ordre croissant de difficulté :</p>
        <br/>
        <br/>
        {officialfest &&
            <Columns multiline vCentered centered className="is-mobile">
                <Columns.Column className="is-6-touch is-4-desktop is-3-fullhd">
                    <GameThumbnail game={officialfest} />
                </Columns.Column>
            </Columns>
        }
        <br/>
        <Container>
            <Columns centered>
                <Columns.Column desktop={{size: "one-quarter"}}>
                    <div className="field">
                        <div className="control">
                            <Form.Input type="search" placeholder="Rechercher un jeu" onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                </Columns.Column>
                <Columns.Column desktop={{size: "one-quarter"}}>
                    <RandomLevelButton />
                </Columns.Column>
                <Columns.Column desktop={{size: "one-quarter"}}>
                    <GamesSorter
                        sortingMethod={sortingMethod}
                        setSortingMethod={setSortingMethod}
                    />
                </Columns.Column>
            </Columns>
        </Container>

        <br/>
        <GamesZoneDisplay
            games={games}
            officialfest={officialfest}
            search={search}
            sortingMethod={sortingMethod}
        />
        
    </>

}