import { Game, GAMES_THUMBNAIL_FILE_FIELD_NAME, H4A_API } from "@hamm4all/shared"
import { useState } from "react"
import ModalFormFileUpload from "../../../../components/form/modal-form-file-upload.component"
import useAPIMutation from "../../../../core/api/useAPIMutation"

interface GameIdentityUploadThumbnailButtonProps {
    gameID: number
}

export default function GameIdentityUploadThumbnailButton({gameID}: Readonly<GameIdentityUploadThumbnailButtonProps>) {
    const [updateThumbnailModalOpen, setUpdateThumbnailModalOpen] = useState(false)

    const uploadThumbnailMutation = useAPIMutation({
        "endpoint": H4A_API.v1.games.thumbnail.update,
        "params": {
            "gameID": gameID
        },
        "onSuccess": () => {
            setUpdateThumbnailModalOpen(false)
        }
    })

    return <>
        <button className="button is-info is-fullwidth is-small" onClick={() => setUpdateThumbnailModalOpen(true)}>Ajouter une miniature</button>
        <ModalFormFileUpload
            isOpen={updateThumbnailModalOpen}
            setIsOpen={setUpdateThumbnailModalOpen}
            handleSubmit={async (file) => {
                const formData = new FormData()
                formData.append(GAMES_THUMBNAIL_FILE_FIELD_NAME, file)
                uploadThumbnailMutation.mutate(formData)
            }}
            title="Ajouter une miniature"
            name="thumbnail"
            label="Miniature"
        />
    </>
}