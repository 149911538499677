import { H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseSaveChangelogProps {
    closeModal: () => void;
}

export default function useSaveChangelog(props: Readonly<UseSaveChangelogProps>) {
    const saveChangelogMutation = useAPIMutation({
        "endpoint": H4A_API.v1.changelog.add,
        "params": props,
        "onSuccess": props.closeModal
    })

    return saveChangelogMutation
}