import { LevelsParam, LevelsVideo } from "@hamm4all/shared";
import { useState } from "react";
import { Button } from "react-bulma-components";
import { LevelVideosModal } from "./LevelVideosUpdateModal";

interface LevelVideosEditButtonProps extends LevelsParam {
    selectedVideoIndex: number;
    currentVideo: LevelsVideo | undefined;
    videos: LevelsVideo[];
}

export default function LevelVideosEditButton({selectedVideoIndex, currentVideo, videos, gameID, levelName}: Readonly<LevelVideosEditButtonProps>) {
    const [isEditVideoModalOpen, setIsEditVideoModalOpen] = useState<boolean>(false)

    return <>
        <Button color="info" disabled={videos.length === 0} onClick={() => setIsEditVideoModalOpen(true)}>Modifier cette vidéo</Button>

        {currentVideo &&
            <LevelVideosModal
                gameID={gameID}
                levelName={levelName}
                isModalOpen={isEditVideoModalOpen}
                setModalOpen={setIsEditVideoModalOpen}
                action="edit"
                videoIndex={selectedVideoIndex}
                initialData={currentVideo}
            />
        }
    </>
}