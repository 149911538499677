import styles from "./LevelsBulkWarp.module.css"
import LevelsAddButton from "./LevelsAddButton"
import { useAuth } from "../../../../context/user/user.context"
import { Game, H4A_API } from "@hamm4all/shared"
import { ThumbnailCardBox } from "../../../../components/ThumbnailCardBox/ThumbnailCardBox"

interface LevelsBulkWarpProps {
    game: Game
}

export default function LevelsBulkWarp(props: Readonly<LevelsBulkWarpProps>) {
    const {hasRole} = useAuth()

    return (
        <div className={styles["levels-bulk-warp"]}>
            <div className="box">
                <ThumbnailCardBox
                    href={`/games/${props.game.id}/levels`}
                    name={"Tous les niveaux"}
                    thumbnail_url={props.game.thumbnail_id}
                    level_count={props.game.level_count}
                />
                {hasRole(H4A_API.v1.levels.add.getRole()) && <LevelsAddButton gameID={props.game.id} />}
            </div>
        </div>
    )
}
