import { GamePartParam, ThumbnailSequenceUpdateBody } from "@hamm4all/shared";
import { useState } from "react";
import useAddThumbnailSequence from "../../hooks/sequences/useAddThumbnailSequence";
import useUpdateThumbnailSequence from "../../hooks/sequences/useUpdateThumbnailSequence";
import ModalForm from "../../../../../components/form/modal-form.component";
import { FormFieldCheckbox } from "../../../../../components/form/field-components/form-checkbox.component";

interface BaseThumbnailSequenceModalProps extends GamePartParam {
    isModalOpen: boolean
    setIsModalOpen: (state: boolean) => void
}

interface AddThumbnailSequenceModalProps extends BaseThumbnailSequenceModalProps {
    type: "create"
}

interface EditThumbnailSequenceModalProps extends BaseThumbnailSequenceModalProps {
    type: "edit"
    initialData: ThumbnailSequenceUpdateBody
    sequenceIndex: number
}

type ThumbnailSequenceModalProps = AddThumbnailSequenceModalProps | EditThumbnailSequenceModalProps

export default function ThumbnailSequenceModal(props: Readonly<ThumbnailSequenceModalProps>) {
    const [isFirstLevelApart, setIsFirstLevelApart] = useState(
        props.type === "create" ? false : props.initialData.firstLevelApart
    )
    const [hasLabyrinth, setHasLabyrinth] = useState(
        props.type === "create" ? false : props.initialData.hasLabyrinth
    )

    const addMutation = useAddThumbnailSequence({
        "gameID": props.gameID,
        "gamePartIndex": props.gamePartIndex,
        "onSuccess": () => props.setIsModalOpen(false)
    })
    const editMutation = useUpdateThumbnailSequence({
        "gameID": props.gameID,
        "gamePartIndex": props.gamePartIndex,
        "sequenceIndex": props.type === "edit" ? props.sequenceIndex : 0,
        "onSuccess": () => props.setIsModalOpen(false)
    })
    const effectiveMutation = props.type === "create" ? addMutation : editMutation
    
    return (
        <ModalForm
            isOpen={props.isModalOpen}
            setIsOpen={props.setIsModalOpen}
            title={props.type === "create" ? "Ajouter une séquence de miniatures" : "Modifier une séquence de miniatures"}
            handleSubmit={async () => effectiveMutation.mutate({
                "firstLevelApart": isFirstLevelApart,
                "hasLabyrinth": hasLabyrinth
            })}
        >
            <FormFieldCheckbox
                label="La séquence est-elle une dimension ?"
                checked={isFirstLevelApart}
                changeHandler={setIsFirstLevelApart}
            />

            <FormFieldCheckbox
                label="Y a-t-il un labyrinthe ?"
                checked={hasLabyrinth}
                changeHandler={setHasLabyrinth}
            />
        </ModalForm>
    )
}