import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { ItemParam } from "../items.params";
import { ItemsUpdateBody } from "./items.update.body";

export class ItemsUpdateCall extends APIEndpointAction<ItemParam, ItemsUpdateBody, {}> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<ItemParam>()
            .addPath("items")
            .addParam("itemID")
    }

    public getMethod(): HTTPMethod {
        return "PUT"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ItemParam, ItemsUpdateBody>): string {
        return `Une erreur s'est produite lors de la mise à jour de l'objet : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ItemParam, ItemsUpdateBody, {}>): string {
        return `L'objet a bien été mis à jour.`
    }
}