import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { APIEndpointGET } from "@hamm4all/shared/dist/calls/api.endpoint";
import { createNotification } from "../components/notifications/notification.component";
import { APIError, APIResponse } from "@hamm4all/shared";

interface UseApiQueryProps<
    Params extends {},
    Query extends {},
    ReturnType extends {}
> {
    endpoint: APIEndpointGET<Params, Query, ReturnType>;
    params: Params;
    query: Query;
}

export default function useAPIQuery<
    Params extends object,
    ReturnType extends object,
    Query extends object
>({ endpoint, params, query }: UseApiQueryProps<Params, Query, ReturnType>) {
    const callFunction = endpoint.getCallFunction(params, query);

    const queryToAPI = useQuery<APIResponse<ReturnType>, APIError>({
        "queryKey": endpoint.getQueryKeys(params, query),
        "queryFn": callFunction,
        "retry": false,
        "throwOnError": (error, query) => {
            if ((error as any).data) {
                const apiError = error as any;
                createNotification({
                    type: 'error',
                    message: `${apiError.data.message} [${apiError.status}]`,
                });
            }
            else {
                createNotification({
                    type: 'error',
                    message: `${error.message}`,
                });
            }

            return true;
        },
    })

    const [isLongLoading, setIsLongLoading] = useState(false);

    if (queryToAPI.isFetching && !isLongLoading) {
        setTimeout(() => {
            setIsLongLoading(true);
        }, 200);
    }

    return {
        ...queryToAPI,
        isLongLoading,
        data: queryToAPI.data?.data!
    }
}