import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { LevelsParam } from "../levels.params";
import { Level } from "../levels.types";

export class LevelsGetCall extends APIEndpointGET<LevelsParam, {}, Level> {

    protected getVersion(): APIVersion {
        return 1
    }
    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}