import { IsInt, Min } from "class-validator";
import { LevelsParam } from "../levels.params";
import { Type } from "class-transformer";


export class LevelsVideoParam extends LevelsParam {
    @IsInt()
    @Min(0)
    @Type(() => Number)
    declare videoIndex: number;
}