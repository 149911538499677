import { HTTPMethod } from "../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { GameParam } from "../../games.params";
import { GameGradeAddBody } from "./games.grade.add.body";


export class GameGradesAddCall extends APIEndpointAction<GameParam, GameGradeAddBody, {}> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<GameParam> {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("grades")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getQueryKeysToInvalidate(params: GameParam): string[][] {
        return [
            ["games", params.gameID.toString()]
        ]
    }

    public override getErrorMessage(params: APIEndpointErrorParams<GameParam, GameGradeAddBody>): string {
        return `Une erreur est survenue lors de l'ajout de la note : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<GameParam, GameGradeAddBody, {}>): string {
        return `Tu as bien noté le jeu ${params.body.grade} !`
    }
}