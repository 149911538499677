import { EditIcon } from "./EditIcon";
import { TitleProps, TitleWithEditProps } from "./Title";

interface SubtitleProps extends TitleProps {
    centered?: boolean
}

export function Subtitle(props: Readonly<SubtitleProps>) {

    const isCenteredClassName = props.centered ? "has-text-centered" : "";
    return (
        <h2 className={`subtitle is-5 ${isCenteredClassName}`}>
            {props.children}
        </h2>
    )
}

interface SubtitleWithEditProps extends TitleWithEditProps {
    centered?: boolean
}

export function SubtitleWithEdit(props: Readonly<SubtitleWithEditProps>) {
    const subtitle = <Subtitle centered={props.centered}>
        {props.children}
    </Subtitle>

    return <div className="columns">
        {props.centered && <div className="column"></div>}
        <div className="column">
            {subtitle}
        </div>
        <EditPart {...props} />
    </div>
}

interface EditPartProps extends TitleWithEditProps {

}


function EditPart(props: Readonly<EditPartProps>) {
    return (
        <div className="column is-flex" style={{"alignItems": "center", "justifyContent": "right"}}>
            <div className="subtitle is-5">
                <EditIcon toggleModal={props.toggleModal} />
            </div>
        </div>
    )
}