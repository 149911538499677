import { Content } from "react-bulma-components";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";

export default function BossesEnemies() {
    return <>
        <Title>Les boss</Title>
        <Content>
            <p>Se comptant au nombre de 2 dans Hammerfest, ils n'en demeurent pas moins terrifiants !</p>
            <Subtitle>La Chauve-souris</Subtitle>
            <p>La Chauve-Souris (parfois abrégée CS) est un mini-boss qui se trouve au niveau 100. Elle tue Igor au contact.<br/>La Chauve Souris ne cesse de changer de position sauf si elle est frappée par une bombe d'Igor : dans ce cas, elle s'arrête sur place et produit des boule de feu qui tournent autour d'elle.<br/>Ces boules de feu peuvent être détruites au contacte d'une bombe. Ensin, si la Chauve Souris est frappée de nouveau alors qu'elle est encore stationnaire, elle perd un point de vie, et le cycle continue.<br/>Au bout de trois fois, la chauve souris commence son animation de mort.<br/>ATTENTION : Même si son animation se déclenche, il faut attendre que la flèche montrant "Niveau Suivant" apparaisse pour sortir du niveau.</p>
            <br/>
            <hr/>
            <img src={"/favicon.png"} alt="Tuberculoz"/>
            <Subtitle>Tuberculoz</Subtitle>
            <p>Tuberculoz (souvent appelé "Tubz") est le boss final du jeu Officiel. Il se trouve au niveau 101.<br/>Tuberculoz envoie régulièrement des sortes de plantes violettes, desquelles sortent des oranges. Le nombre d'oranges maximum est de 4 dans le niveau.<br/>Si les oranges font un surfing avec Tuberculoz, il perd un point de vie sur les 10 qu'il possède, et dans ce cas, toute attaque qu'il était en train de faire est interrompues.<br/>Les oranges tuées ne rapportent pas de cristaux.<br/>Tuberculoz possède trois attaques différentes :</p>
            <ul>
                <li>L'attaque violette : Cette attaque va être utilisée lorsque le boss se fige et prend une aura mauve/violette. Il va alors disparaître de l'endroit où il se trouve, pour réapparaître à la même coordonnée Y qu'Igor,apparaissant d'un côté du niveau et allant tout droit de l'autre côté du niveau. Cette opération est répétée deux fois : d'un côté et de l'autre.</li>
                <li>L'attaque tornade : Cette attaque arrive lorsque Tubz incante une tornade autour de lui. A ce moment, Igor, ses bombes et tous les fruits du niveaux s'élèvent dans les airs pendant quelques secondes.</li>
                <li>L'attaque feu : Cette attaque va être utilisée lorsque le boss se fige et prend une aura jaune/orange. Au bout de 3 secondes d'incantation, une rangée de boules de feu étroite va s'élever du sol et monter vers le haut du niveau. Il y a cependant un espace correcpondant à là où se situait Tubz lors de sont incantation qui n'est pas rempli de boules de feu, et qui permet d'esquiver cette attaque.</li>
            </ul>
            <br/>
            <p>Une fois le boss attaqué dix fois, son animation de mort se joue, le réduisant en un tas de cendre informe.</p>
        </Content>
    </>

}
