import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../hooks/use-api-query.hook";

export interface UseLevelProps {
    gameID: number;
    levelName: string;
}

export default function useLevel(props: UseLevelProps) {
    const {gameID, levelName} = props
    const levelQuery = useAPIQuery({
        "endpoint": H4A_API.v1.levels.get,
        "params": {
            "gameID": gameID,
            "levelName": levelName
        },
        "query": {}
    })

    return {
        ...levelQuery,
        level: levelQuery.data
    }
}