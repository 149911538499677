import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { UserProfile } from "../users.types";

export class UsersListCall extends APIEndpointGET<{}, {}, UserProfile[]> {
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("users")
    }

    public getRole(): H4ARole {
        return H4ARole.ADMINISTRATOR
    }
}