import { useState } from "react";
import { FormFieldInput } from "../../../../components/form/field-components/form-field-input.component";
import ModalForm from "../../../../components/form/modal-form.component";
import useAddGamePart from "../../hooks/useAddGamePart";
import useUpdateGamePart from "../../hooks/useUpdateGamePart";

interface AddGamePartModalProps extends BaseGamePartModalProps {
    type: "add";
}

interface BaseGamePartModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    gameID: number;
}

interface UpdateGamePartModalProps extends BaseGamePartModalProps {
    type: "update";
    initialData: {
        partIndex: number;
        name: string;
        description: string;
    }
}

type GamePartModalProps = AddGamePartModalProps | UpdateGamePartModalProps

export default function GamePartModal(props: Readonly<GamePartModalProps>) {
    const [name, setName] = useState<string>(
        props.type === "update" ? props.initialData.name : ""
    );
    const [description, setDescription] = useState<string>(
        props.type === "update" ? props.initialData.description : ""
    );

    const gamePartsAddMutation = useAddGamePart({
        "gameID": props.gameID,
        "onClose": () => props.setIsOpen(false)
    })
    const gamePartsUpdateMutation = useUpdateGamePart({
        "gameID": props.gameID,
        "onClose": () => props.setIsOpen(false),
        "gamePartIndex": props.type === "update" ? props.initialData.partIndex : -1
    })

    const effectiveMutation = props.type === "add" ? gamePartsAddMutation : gamePartsUpdateMutation;

    return (
        <ModalForm
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            title={`Ajouter une partie de jeu`}
            handleSubmit={async () => effectiveMutation.mutate({
                "name": name,
                "description": description
            })}
        >
            <FormFieldInput 
                label="Nom"
                name="name"
                placeholder="Nom de la partie de jeu"
                required
                changeHandler={setName}
                value={name}
            />

            <FormFieldInput 
                label="Description"
                name="description"
                placeholder="Description de la partie de jeu"
                required
                changeHandler={setDescription}
                value={description}
            />
        </ModalForm>
    )
}