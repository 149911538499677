import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint"
import { H4ARole } from "../../api.roles"
import { GameParam } from "../games.params"
import { Game } from "../games.types"

export class GamesGetCall extends APIEndpointGET<GameParam, {}, Game> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<GameParam>()
            .addPath("games")
            .addParam("gameID")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    } 
}