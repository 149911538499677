import { H4A_API, LevelsParam } from "@hamm4all/shared";
import useAPIQuery from "../../../../hooks/use-api-query.hook";

export interface UseVideosProps extends LevelsParam {}

export default function useVideos(props: Readonly<UseVideosProps>) {
    const videosQuery = useAPIQuery({
        "endpoint": H4A_API.v1.levels.videos.list,
        "params": {
            "gameID": props.gameID,
            "levelName": props.levelName
        },
        "query": {}
    })

    return {
        ...videosQuery,
        videos: videosQuery.data
    }

}