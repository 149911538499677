import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { Family } from "../families.types";


export class FamiliesListCall extends APIEndpointGET<{}, {}, Array<Family>> {

    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("families")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}