import { Columns } from "react-bulma-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoginForm from "./login/login.form";
import SignupForm from "./signup/signup.form";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { useAuth } from "../../context/user/user.context";

export default function ConnectPage() {
    const {refreshAuth} = useAuth()
    const [params] = useSearchParams()
    const navigate = useNavigate()

    const onValidAction = async () => {
        await refreshAuth()
        const redirectPage = params.get("previousPage") ?? "/"
        navigate(redirectPage)
    }

    return <Columns>
        <Columns.Column desktop={{"size": "half"}}>
            <Subtitle centered>CONNEXION</Subtitle>
            <LoginForm onLogin={onValidAction} />
        </Columns.Column>
        <Columns.Column desktop={{"size": "half"}}>
            <Subtitle centered>INSCRIPTION</Subtitle>
            <SignupForm onSignup={onValidAction} />
        </Columns.Column>
    </Columns>
}