import { Button } from "react-bulma-components";
import { H4A_API, LevelScreenshot, LevelsParam, ScreenshotParam } from "@hamm4all/shared";
import { useState } from "react";
import LevelScreenshotAddModal from "./panel/LevelScreenshotAddModal";
import LevelScreenshotUpdateModal from "./panel/LevelScreenshotUpdateModal";
import LevelScreenshotChangeOrderModal from "./panel/LevelScreenshotChangeOrderModal";
import { Subtitle } from "../../../common/layout/title/Subtitle";
import { useAuth } from "../../../context/user/user.context";

interface LevelScreenshotControlPanelProps extends ScreenshotParam {
    screenshots: LevelScreenshot[]
}

export default function LevelScreenshotControlPanel(props: Readonly<LevelScreenshotControlPanelProps>) {
    const {hasRole} = useAuth();

    return <>
        <Subtitle centered>Screens</Subtitle>
        <div className="buttons is-centered">
            {hasRole(H4A_API.v1.levels.screenshots.update.getRole()) &&
                <LevelScreenshotUpdate {...props} />
            }
            {hasRole(H4A_API.v1.levels.screenshots.add.getRole()) &&
                <LevelScreenshotAdd {...props} />
            }
            {hasRole(H4A_API.v1.levels.screenshots.order.update.getRole()) &&
                <LevelScreenshotChangeOrder {...props} screenshots={props.screenshots} />
            }
        </div>
    </>
}

interface LevelScreenshotAddProps extends ScreenshotParam {

}

function LevelScreenshotAdd(props: Readonly<LevelScreenshotAddProps>) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    return <>
        <Button color="info" onClick={() => setIsModalOpen(true)}>Ajouter un screen</Button>
        <LevelScreenshotAddModal
            {...props}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
        />
    </>
}

interface LevelScreenshotUpdateProps extends LevelScreenshotControlPanelProps {}

function LevelScreenshotUpdate(props: Readonly<LevelScreenshotUpdateProps>) {
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false)
    const isScreenModifiable = props.screenshotOrderNumber > 1 || props.screenshots.length > 0

    return <>
        <Button color="info" disabled={!isScreenModifiable} onClick={() => setIsUpdateModalOpen(true)}>Modifier ce screen</Button>
        <LevelScreenshotUpdateModal
            {...props}
            isModalOpen={isUpdateModalOpen}
            setIsModalOpen={setIsUpdateModalOpen}
        />
    </>
}

interface LevelScreenshotChangeOrderProps extends LevelsParam {
    screenshots: LevelScreenshot[]
}

function LevelScreenshotChangeOrder(props: Readonly<LevelScreenshotChangeOrderProps>) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    return <>
        <Button color="info" disabled={props.screenshots.length <= 1} onClick={() => setIsModalOpen(true)}>Changer l'ordre des screens</Button>
        <LevelScreenshotChangeOrderModal
            {...props}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            screenshots={props.screenshots}
        />
    </>
}