import { IsDateString, IsNotEmpty } from "class-validator";

export class ChangelogForVersion {
    @IsNotEmpty()
    declare versionName: string;

    @IsNotEmpty()
    declare description: string;

    @IsDateString()
    declare createdAt: string;
}