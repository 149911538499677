import { H4A_API, Game } from "@hamm4all/shared"
import { useState } from "react"
import { FormFieldInput, FormFieldTextArea } from "../../components/form/field-components/form-field-input.component"
import { FormFieldSelect } from "../../components/form/field-components/form-field-select.component"
import ModalForm from "../../components/form/modal-form.component"
import FormAuthorsSelector from "../../components/authors/selector.authors"
import useAPIMutation from "../../core/api/useAPIMutation"

type GameModalFormProps = GameModalFormCreateProps | GameModalFormEditProps

interface GameModalFormCreateProps {
    type: "create",
    modalInteraction: FormModalProps
}

interface GameModalFormEditProps {
    type: "edit",
    gameData: Game,
    modalInteraction: FormModalProps
}

interface FormModalProps {
    isOpen: boolean,
    setIsOpen: (toggle: boolean) => void
}


export default function GameModalForm(props: Readonly<GameModalFormProps>) {
    const [name, setName] = useState(props.type === "edit" ? props.gameData?.name : "");
    const [description, setDescription] = useState(props.type === "edit" ? props.gameData?.description : "");

    let defaultDifficulty: "variable" | "fixed" = "variable";
    if (props.type === "edit") {
        defaultDifficulty = props.gameData?.has_variable_difficulty ? "variable" : "fixed"
    }

    const [difficulty, setDifficulty] = useState<"variable" | "fixed">(defaultDifficulty);
    const [releaseDate, setReleaseDate] = useState(props.type === "edit" ? props.gameData?.released_at : "");
    const [authors, setAuthors] = useState(props.type === "edit" ? props.gameData?.authors.map(author => author.id) : []);

    const gamesAddMutation = useAPIMutation({
        "endpoint": H4A_API.v1.games.add,
        "params": {},
        "onSuccess": () => props.modalInteraction.setIsOpen(false)
    })
    const gamesUpdateMutation = useAPIMutation({
        "endpoint": H4A_API.v1.games.update,
        "params": {
            "gameID": props.type === "edit" ? props.gameData.id : 0
        },
        "onSuccess": () => props.modalInteraction.setIsOpen(false)
    })

    const gamesMutation = props.type === "create" ? gamesAddMutation : gamesUpdateMutation
    const formTitle = props.type === "create" ? "Ajouter un jeu" : "Modifier un jeu"
    
    return (
        <ModalForm
            isOpen={props.modalInteraction.isOpen}
            setIsOpen={props.modalInteraction.setIsOpen}
            handleSubmit={async () => gamesMutation.mutate({
                "name": name,
                "description": description,
                "released_at": releaseDate,
                "has_variable_difficulty": difficulty === "variable",
                "authors": authors
            })}
            title={formTitle}>
            <FormFieldInput label="Nom du jeu" type="text" name="name" minLength={1} maxLength={100} autoFocus required changeHandler={setName} value={name} />
            <FormFieldTextArea label="Description du jeu" type="text" name="description" minLength={10} maxLength={4000} autoFocus required changeHandler={setDescription} value={description} />
            <FormFieldInput label="Date de sortie du jeu" type="date" name="releaseDate" changeHandler={setReleaseDate} value={releaseDate.split("T")[0]} required />
            <FormFieldSelect
                label="Difficulté du jeu"
                type="select"
                name="difficulty"
                value={difficulty}
                changeHandler={newValue => setDifficulty(newValue as "variable" | "fixed")}
                options={[
                    {value: "variable", label: "Variable"},
                    {value: "fixed", label: "Fixe"}
                ]}
            />
            <FormAuthorsSelector authors={authors} setAuthors={setAuthors} title="Auteur(s) du jeu" />
        </ModalForm>
    )
}