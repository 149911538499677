import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { ChangelogForVersion } from "../changelog.types";

export class ChangelogListCall extends APIEndpointGET<{}, {}, Array<ChangelogForVersion>> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder()
            .addPath("changelog")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}