import { Content } from "react-bulma-components";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";

export default function SpecialItems() {

    return <>
        <Title>Les objets spéciaux</Title>
        <Content>
            <div>
                <img src="/assets/items/1186.svg" alt="objet interdit 1" />
                <img src="/assets/items/1187.svg" alt="objet interdit 2" />
                <img src="/assets/items/1188.svg" alt="objet interdit 3" />
                <img src="/assets/items/1189.svg" alt="objet interdit 4" />
            </div>
            <p>Ces objets ne sont pas présents dans le jeu, dans le sens où ils n'ont ni nom, ni valeur, ni famille. Cependant, ils sont accessibles sur le site, et même théoriquement utilisables sur le forum, bien qu'il soit impossible de les débloquer. Il est strictement interdit d'utiliser ces images sur le forum, ce qui a vallu à ces objets la dénomination d'"objets interdits".</p>
            <Subtitle>Autres objets</Subtitle>
            <p>Ces objets, qui ressemblent à des ébauches de certains objets présents en jeu, se trouvaient dans les bannières publicitaires animées du jeu. Bien que ressemblant à certains objets connus, leurs effets sont bien différents :</p>
            <ul>
                <li><img src="/assets/items/misc/31.svg" alt="" /> Des cristaux multicolores à valeurs variables et aléatoires, qui rappellent les <img src="/assets/items/1000.svg" alt="" /> Cristaux d'Hammerfest.</li>
                <li><img src="/assets/items/misc/32.svg" alt="" /> Bien que cet objet ressemble comme deux gouttes d'eau au <img src="/assets/items/82.svg" alt="" /> Jugement avant-dernier, son effet est très différent : il fait tomber une pluie de feu qui tue les ennemis et fait tomber des petits cristaux.</li>
                <li><img src="/assets/items/misc/33.svg" alt="" /> Cet objet, dont le design rappel le <img src="/assets/items/106.svg" alt="" /> Livre des champignons, a un effet similaire à une perle : des bonbons apparaissent sur chaque plateforme du niveau. Contrairement aux perles, cela ne tue pas les ennemis présents dans le niveau.</li>
                <li><img src="/assets/items/misc/34.svg" alt="" /> Cet objet, visuellement très proche du <img src="/assets/items/1003.svg" alt="" /> Bonbon Berlinmauve, vaut 75 points, et apparait après qu'Igor ait attrapé un livre.</li>
            </ul>
        </Content>
    </>


}