import ModalForm from "../../../components/form/modal-form.component";
import { useState } from "react";
import { Level, LevelTransitionClassic } from "@hamm4all/shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FormFieldSelect } from "../../../components/form/field-components/form-field-select.component";
import useSaveClassicTransitions from "../hooks/useSaveClassicTransitions";

interface LevelLinksModalProps {
    gameID: number;
    levelName: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    initialNextLinks: LevelTransitionClassic[];
    levelsOfGame: Level[]
}

interface LevelLink {
    type: "simple" | "dimension";
    targetID: number;
}

export default function LevelClassicLinksModal({ isOpen, setIsOpen, initialNextLinks, gameID, levelName, levelsOfGame }: Readonly<LevelLinksModalProps>) {
    const [nextClassicLinks, setNextClassicLinks] = useState<LevelLink[]>(
        initialNextLinks
            .filter(transition => transition.type === "simple")
            .map(transition => ({ "type": transition.type, "targetID": transition.level.id })
    ))
    const [nextDimensionLinks, setNextDimensionLinks] = useState<LevelLink[]>(
        initialNextLinks
            .filter(transition => transition.type === "dimension")
            .map(transition => ({ "type": transition.type, "targetID": transition.level.id })
    ))

    function addLink(type: "simple" | "dimension") {
        if (type === "simple") {
            setNextClassicLinks([...nextClassicLinks, {
                "type": type,
                "targetID": levelsOfGame[0].id
            }]);
        } else {
            setNextDimensionLinks([...nextDimensionLinks, {
                "type": type,
                "targetID": levelsOfGame[0].id
            }]);
        }
    }

    function removeLink(type: "simple" | "dimension", targetID: number) {
        if (type === "simple") {
            setNextClassicLinks(nextClassicLinks.filter(link => link.targetID !== targetID));
        } else {
            setNextDimensionLinks(nextDimensionLinks.filter(link => link.targetID !== targetID));
        }
    }

    function onChangeLinkType(type: "simple" | "dimension", currentTargetID: number, newTargetID: number) {
        if (type === "simple") {
            setNextClassicLinks(nextClassicLinks.map(link => {
                if (link.targetID === currentTargetID) {
                    return {
                        ...link,
                        "targetID": newTargetID
                    }
                } else {
                    return link;
                }
            }));
        } else {
            setNextDimensionLinks(nextDimensionLinks.map(link => {
                if (link.targetID === currentTargetID) {
                    return {
                        ...link,
                        "targetID": newTargetID
                    }
                } else {
                    return link;
                }
            }));
        }
    }

    const nextLinksUpdateMutation = useSaveClassicTransitions({ gameID, levelName, onSuccess: () => setIsOpen(false)});

    return (
        <ModalForm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Editer les niveaux suivants"
            handleSubmit={async () => nextLinksUpdateMutation.mutate({
                "transitions": [...nextClassicLinks, ...nextDimensionLinks]
            })}
        >
            <p>Liens classiques</p>
            {nextClassicLinks.map((link, index) => (
                <div key={index} className="field has-addons">
                    <div className="control">
                        <FormFieldSelect
                            label={""}
                            changeHandler={(newValue) => onChangeLinkType("simple", link.targetID, +newValue)}
                            options={levelsOfGame.map(level => ({
                                "label": level.name,
                                "value": level.id.toString()
                            }))}
                            defaultValue={link.targetID.toString()}
                        />
                    </div>
                    <div className="control">
                        <button className="button is-danger" onClick={() => removeLink(link.type, link.targetID)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                </div>
            ))}

            <button className="button is-success" onClick={() => addLink("simple")}>
                <FontAwesomeIcon icon={faAdd} />
            </button>

            <hr />
            <p>Dimensions</p>
            {nextDimensionLinks.map(link => (
                <div key={link.targetID + link.type} className="field has-addons">
                    <div className="control">
                        <FormFieldSelect
                            label={""}
                            changeHandler={(newValue) => onChangeLinkType("dimension", link.targetID, +newValue)}
                            options={levelsOfGame.map(level => ({
                                "label": level.name,
                                "value": level.id.toString()
                            }))}
                            defaultValue={link.targetID.toString()}
                        />
                    </div>
                    <div className="control">
                        <button className="button is-danger" onClick={() => removeLink(link.type, link.targetID)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                </div>
            ))}

            <button className="button is-success" onClick={() => addLink("dimension")}>
                <FontAwesomeIcon icon={faAdd} />
            </button>
        </ModalForm>
    )
}