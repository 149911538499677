import { H4A_API, ScreenshotParam } from "@hamm4all/shared";
import useAPIQuery from "../../../../hooks/use-api-query.hook";

interface UseOrderNumbersProps extends ScreenshotParam {
}

export default function useOrderNumbers(props: Readonly<UseOrderNumbersProps>) {
    const {gameID, levelName, screenshotOrderNumber} = props
    const orderNumbersQuery = useAPIQuery({
        "endpoint": H4A_API.v1.levels.screenshots.fruitOrder.list,
        "params": {
            "gameID": gameID,
            "levelName": levelName,
            "screenshotOrderNumber": screenshotOrderNumber
        },
        "query": {}
    })

    if (orderNumbersQuery.isError) {
        throw new Error(`Les numéros de l'ordre du niveau ${levelName} du jeu ${gameID} n'ont pas pu être chargés.`)
    }

    const orderNumbers = orderNumbersQuery.data

    return {
        ...orderNumbersQuery,
        orderNumbers
    }
}