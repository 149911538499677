import { HTTPMethod } from "../../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../api.endpoint";
import { H4ARole } from "../../../../api.roles";
import { ThumbnailSequenceParam } from "../thumbnail-sequences.params";

export class ThumbnailSequencesDeleteCall extends APIEndpointAction<ThumbnailSequenceParam, {}, {}> {
    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder(): URIBuilder<ThumbnailSequenceParam> {
        return new URIBuilder<ThumbnailSequenceParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
            .addParam("sequenceIndex")
    }

    public getMethod(): HTTPMethod {
        return "DELETE"
    }

    public getRole(): H4ARole {
        return H4ARole.MODERATOR
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ThumbnailSequenceParam, {}>): string {
        return `Une erreur est survenue lors de la suppression de la séquence d'images : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ThumbnailSequenceParam, {}, {}>): string {
        return `La séquence d'images a bien été supprimée !`
    }
}