import { H4A_API, ScreenshotParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../core/api/useAPIMutation";

interface UseUpdateScreenshotProps extends ScreenshotParam {
    onClose: () => void
}

export default function useUpdateScreenshot(props: UseUpdateScreenshotProps) {
    const mutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.screenshots.update,
        "params": props,
        "onSuccess": props.onClose,
    })

    return mutation
}