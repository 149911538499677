import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { Language } from "../languages.types";

export class LanguagesListCall extends APIEndpointGET<{}, {}, Language[]> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("languages")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    } 
}