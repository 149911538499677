import { GameParam, H4A_API } from "@hamm4all/shared";
import { useAuth } from "../../context/user/user.context";
import GameItemsAddButton from "./GameItemsAddButton";

interface GameItemsButtonsProps extends GameParam {

}

export default function GameItemsButtons(props: Readonly<GameItemsButtonsProps>) {
    const {hasRole} = useAuth();

    return <>
        {hasRole(H4A_API.v1.items.add.getRole()) &&
            <GameItemsAddButton {...props} />
        }
    </>
}