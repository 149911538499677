import { LazyLoadImage } from "react-lazy-load-image-component";
import { getBlobPathFromId } from "../../utilities/blobs/blobs.utilities";

interface ItemImageProps {
    picture_id?: string;
}

export default function ItemImage(props: Readonly<ItemImageProps>) {
    return (
        <LazyLoadImage
            src={getBlobPathFromId(props.picture_id ?? "")}
            style={{
                maxWidth: '40px', maxHeight: "40px"
            }} alt=""
        />
    )
}
    