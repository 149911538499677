import { useState } from "react"
import LevelClassicLinksModal from "../../../levels/components/level-links-modal.component"
import { Level, LevelsParam, LevelTransitionClassic } from "@hamm4all/shared"

interface ClassicLinksEditButtonProps extends LevelsParam {
    nextTransitions: LevelTransitionClassic[]
    levelsOfGame: Level[]
}

export default function ClassicLinksEditButton(props: Readonly<ClassicLinksEditButtonProps>) {
    const [classicEditLinksModalActive, setClassicEditLinksModalActive] = useState(false)
    return <>
        <button
            className="button is-success is-outlined"
            onClick={() => setClassicEditLinksModalActive(true)}
        >
            Editer les liens
        </button>

        <LevelClassicLinksModal
            isOpen={classicEditLinksModalActive}
            setIsOpen={setClassicEditLinksModalActive}
            initialNextLinks={props.nextTransitions}
            gameID={props.gameID}
            levelName={props.levelName}
            levelsOfGame={props.levelsOfGame}
        />
    </>
}