import { HTTPMethod } from "../../../../../api.call"
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../../api.endpoint"
import { H4ARole } from "../../../../api.roles"
import { GamePartParam } from "../../game-parts.params"
import { ThumbnailSequenceAddBody } from "./thumbnail-sequences.add.body"

export class ThumbnailSequenceAddCall extends APIEndpointAction<GamePartParam, ThumbnailSequenceAddBody, {}> {

    protected getVersion(): APIVersion {
        return 1
    }
    
    protected getUriBuilder(): URIBuilder<GamePartParam> {
        return new URIBuilder<GamePartParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("parts")
            .addParam("gamePartIndex")
            .addPath("thumbnail-sequences")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.GUEST
    }

    public override getErrorMessage(params: APIEndpointErrorParams<GamePartParam, ThumbnailSequenceAddBody>): string {
        return `Une erreur est survenue lors de l'ajout de la séquence d'images : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<GamePartParam, ThumbnailSequenceAddBody, {}>): string {
        return `La séquence d'images a bien été ajoutée !`
    }
}