import { ThumbnailSequence, ThumbnailLink } from "@hamm4all/shared";

interface UseThumbnailSequenceTitleProps {
    sequence: ThumbnailSequence;
    thumbnailLinks: ThumbnailLink[];
    gameID: number;
}

export default function useThumbnailSequenceTitle({sequence, thumbnailLinks, gameID}: Readonly<UseThumbnailSequenceTitleProps>): string {
    const firstThumbnailLink = thumbnailLinks[0]

    let prefix = "Séquence"
    if (sequence.isFirstLevelApart) {
        prefix = "Dimension"
    }
    if (sequence.hasLabyrinth) {
        prefix = "Labyrinthe"
    }

    let name: string = sequence.orderNumber.toString()
    if (firstThumbnailLink) {
        name = firstThumbnailLink.level.name
    }

    let sequenceName = sequence.name ?? ""
    if (sequence.name === "Sans nom") {
        sequenceName = ""
    }
    const sequenceNameSuffix = sequenceName ? `- ${sequenceName}` : ""

    return `${prefix} ${name} ${sequenceNameSuffix}`
}