import { H4A_API, LevelsParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../core/api/useAPIMutation";

interface UseSaveScreenOrderProps extends LevelsParam {
    closeModal: () => void
}

export default function useSaveScreenOrder(props: UseSaveScreenOrderProps) {
    const mutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.screenshots.order.update,
        "params": props,
        "onSuccess": props.closeModal,
    })

    return mutation
}