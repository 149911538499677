import { Type } from "class-transformer";
import { IsBoolean, IsDateString, IsInt, IsNotEmpty, Min, ValidateNested } from "class-validator";
import { ReducedUser } from "../../users/users.types";


export class LevelsVideo {

    @IsInt()
    declare id: number;

    @IsNotEmpty()
    declare link: string;

    @IsBoolean()
    declare hasOrder: boolean;

    @IsInt()
    declare levelID: number;

    @IsDateString()
    declare createdAt: string;

    @IsDateString()
    declare updatedAt: string;

    @ValidateNested()
    @Type(() => ReducedUser)
    declare user: ReducedUser;

    @IsInt()
    @Min(0)
    declare livesLost: number;
}