import { useState } from "react"
import LevelLabyLinksModal from "../../../levels/components/level-laby-links-modal.component"
import { Level, LevelsParam, LevelTransitionsLaby } from "@hamm4all/shared"

interface LabyLinksEditButtonProps extends LevelsParam {
    labyrinthLevelsTransitions: LevelTransitionsLaby
    levelsOfGame: Level[]
}

export default function LabyLinksEditButton(props: Readonly<LabyLinksEditButtonProps>) {
    const [labyrinthEditLinksModalActive, setLabyrinthEditLinksModalActive] = useState(false)
    return <>
        <button
            className="button is-success is-outlined"
            onClick={() => setLabyrinthEditLinksModalActive(true)}
        >
            Editer le labyrinthe
        </button>

        <LevelLabyLinksModal
            isOpen={labyrinthEditLinksModalActive}
            setIsOpen={setLabyrinthEditLinksModalActive}
            initialLinks={props.labyrinthLevelsTransitions}
            gameID={props.gameID}
            levelName={props.levelName}
            levelsOfGame={props.levelsOfGame}
        />
    </>
}