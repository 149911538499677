import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bulma-components";

interface PaginationProps {
    index: number
    setIndex: (index: number) => void
    length: number
}

export default function Pagination({index, setIndex, length}: Readonly<PaginationProps>) {
    return (
        <div className="buttons is-centered is-grouped">
            <Button color="info" disabled={index < 1} onClick={() => setIndex(index - 1)}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Button color="info" disabled>{length === 0 ? 0 : index + 1}/{length}</Button>
            <Button color="info" disabled={index >= length - 1} onClick={() => setIndex(index + 1)}>
                <FontAwesomeIcon icon={faArrowRight} />
            </Button>
        </div>
    )
}