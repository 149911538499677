import { H4A_API, ScreenshotParam } from "@hamm4all/shared";
import useAPIMutation from "../../../../core/api/useAPIMutation";

interface UseSaveOrderNumbersProps extends ScreenshotParam {

}

export default function useSaveOrderNumbers(props: Readonly<UseSaveOrderNumbersProps>) {
    const saveOrderNumbersMutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.screenshots.fruitOrder.update,
        "params": props
    })

    return saveOrderNumbersMutation
}