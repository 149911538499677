import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ModalFormFileUpload from "../../form/modal-form-file-upload.component";
import { H4A_API, ITEMS_PICTURE_FILE_FIELD_NAME } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface ItemUploadButtonProps {
    itemID: string
}

export default function ItemUploadButton(props: Readonly<ItemUploadButtonProps>) {
    const [isUploadItemImageModalOpen, setIsUploadItemImageModalOpen] = useState(false)
    const uploadImageMutation = useAPIMutation({
        "endpoint": H4A_API.v1.items.picture.update,
        "params": {
            "itemID": props.itemID
        },
        "onSuccess": () => {
            setIsUploadItemImageModalOpen(false)
        }
    })

    return <>
        <button className="button is-info" onClick={() => setIsUploadItemImageModalOpen(true)}>
            <FontAwesomeIcon icon={faUpload} />
        </button>
        <ModalFormFileUpload
            isOpen={isUploadItemImageModalOpen}
            setIsOpen={setIsUploadItemImageModalOpen}
            handleSubmit={async (file) => {
                const formData = new FormData()
                formData.append(ITEMS_PICTURE_FILE_FIELD_NAME, file)

                uploadImageMutation.mutate(formData)
            }}
            title="Ajouter une image"
            name="picture"
            label="Choisir une image"
        />
    </>
}