import { Game, Level } from "@hamm4all/shared";
import { Link } from "react-router-dom";
import { Subtitle } from "../../../common/layout/title/Subtitle";

interface LevelPageSubtitleProps {
    level: Level;
    game: Game;
}

export default function LevelPageSubtitle({level, game}: Readonly<LevelPageSubtitleProps>) {
    return <Subtitle centered>
        <Link to={`/games/${game.id}`}>{game.name}</Link> -&nbsp;{level.land.name}
    </Subtitle>
}
