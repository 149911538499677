import { Link } from "react-router-dom";
import { Columns } from "react-bulma-components";
import "./quests.css"
import { Fragment } from "react";
import { H4A_API } from "@hamm4all/shared";
import { Subtitle } from "../../common/layout/title/Subtitle";
import { Title } from "../../common/layout/title/Title";
import useAPIQuery from "../../hooks/use-api-query.hook";

export default function QuestsItems() {
    const questsQuery = useAPIQuery({
        "endpoint": H4A_API.v1.quests.list,
        "params": {},
        "query": {}
    })

    if (questsQuery.isPending) {
        return <></>
    }

    const quests = questsQuery.data

    return <>
        <Title>Les quêtes</Title>
        <p>Certains objets permettent de compléter des quêtes, qui donnent accès à de nouvelles familles, des déguisements, des options et modes de jeu, ainsi que certains bonus divers, comme des parties ou des bonus lors de l'achat de parties, à l'époque où celui ci fonctionnait encore.</p>
        <p>Depuis Depuis le bug de mars 2017, la quête <Link to="#quest74">Maître des bombes</Link> ne fonctionne plus correctement. En effet, lorsque la quête est validée, elle est sensée contacter un serveur pour ajouter le joueur à la liste des détenteurs de la <img src="/assets/items/1237.svg" alt="nog" /> Neige-o-glycérine (probablement pour le concours des t-shirts). Ce système ne fonctionne aujourd'hui plus, et compléter cette quête aura pour effet d'empêcher de compléter d'autres quêtes à l'avenir, ainsi que de bloquer le meilleur score et le meilleur niveau du joueur. Elle ne débloque plus non plus l'option "Explosifs Instables".</p>
        <br/>
        {quests.map(quest => <Fragment key={quest.name.fr}>
            <Subtitle centered>{quest.name.fr}</Subtitle>
            <Columns multiline vCentered centered>
                {quest.items.map((item: any) =>
                    <Columns.Column className="item-with-quantity-container" size={1} key={item.name.fr}>
                        <Link to={`/objects/families#item${item.id}`}>
                            <img src={item.img} alt={item.name.fr} style={{"maxWidth": "40px", "maxHeight": "40px"}} />
                        </Link>
                        ×{item.quantity}
                    </Columns.Column>
                )}
            </Columns>
            <p className="description">{quest.desc.fr}</p>
            <p className="has-text-centered">
                {quest.give?.tokens && <>Donne {quest.give.tokens} parties gratuites<br/></>}
                {quest.give?.bank && <>Les achats de parties rapportent +{quest.give.bank}% parties<br/></>}
                {quest.give?.mode && <>Débloque le mode "<strong className="has-text-white">{quest.give.mode}</strong>"<br/></>}
                {quest.give?.option && <>Débloque l'option "<strong className="has-text-white">{quest.give.option}</strong>"<br/></>}
                {quest.give?.log && <>Active le logueur<br/></>}
                {quest.give?.families?.map((family: any) => <Fragment key={family.id}>
                    {family.name && <>Débloque la famille "<Link to={`/objects/families#family${family.id}`}>{family.name.fr}</Link>"<br/></>}
                    {!family.name && family.id >= 5000 && <>Permet d'ouvrir une serrure<br/></>}
                </Fragment>)}
                {quest.remove?.families?.map((family: any) => <Fragment key={family.id}>
                    {family.name && <>Retire la famille "<Link to={`/objects/families#family${family.id}`}>{family.name.fr}</Link>"<br/></>}
                </Fragment>)}
                {!quest.give && !quest.remove && <>Cette quête n'a aucun effet<br/></>}
            </p>
            <hr/>
        </Fragment>)}
    </>
}