import { HTTPMethod } from "../../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { ScreenshotParam } from "../screenshots.params";


export class LevelsScreenshotDeleteCall extends APIEndpointAction<ScreenshotParam, {}, {}> {

    protected getVersion(): APIVersion {
        return 1;
    }

    protected getUriBuilder() {
        return new URIBuilder<ScreenshotParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("screenshots")
            .addParam("screenshotOrderNumber")
    }

    public getMethod(): HTTPMethod {
        return "DELETE"
    }

    public getRole(): H4ARole {
        return H4ARole.MODERATOR
    }

    public override getErrorMessage(params: APIEndpointErrorParams<ScreenshotParam, {}>): string {
        return `Une erreur s'est produite lors de la suppression du screen ${params.params.screenshotOrderNumber} du niveau ${params.params.levelName} : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<ScreenshotParam, {}>): string {
        return `Le screen ${params.params.screenshotOrderNumber} du niveau ${params.params.levelName} a bien été supprimé.`
    }
}