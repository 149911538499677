import { HTTPMethod } from "../../../api.call";
import { APIEndpointGET, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { Author } from "../authors.types";


export class AuthorsListCall extends APIEndpointGET<{}, {}, Author[]> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("authors")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}