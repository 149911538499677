import { Game } from "@hamm4all/shared";
import GameIdentityField from "./right-part/GameIdentityField";
import GameIdentityDifficultyField from "./right-part/GameIdentityDifficultyField";

interface GameIdentityRightPartProps {
    game: Game;
}

export default function GameIdentityRightPart({game}: Readonly<GameIdentityRightPartProps>) {
    const formattedAuthors = game.authors.map(author => author.name).join(", ")
    return <table className="table is-fullwidth">
        <tbody>
            <GameIdentityField label="Date de sortie">
                {new Date(game.released_at).toLocaleDateString("fr-FR", {weekday: "long", year: "numeric", month: "long", day: "numeric"})}
            </GameIdentityField>
            <GameIdentityField label="Auteurs">
                {game.authors.length > 0 ? formattedAuthors : "Aucun créateur n'est spécifié"}
            </GameIdentityField>
            <GameIdentityDifficultyField game={game}/>
            <GameIdentityField label="Nombre de niveaux">
                {game.level_count}
            </GameIdentityField>
        </tbody>
    </table>
}