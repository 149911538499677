import { UserProfile } from "@hamm4all/shared";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { updateSession } from "../../pages/connect/session";

type AuthContextType = {
    user: UserProfile | undefined;
    setUser: (rights: UserProfile) => void;
    refreshAuth: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({children}: { children: ReactNode }) => {
    const [user, setUser] = useState<UserProfile | undefined>(undefined);

    // Function to refresh authentication
    const refreshAuth = async () => {
        try {
            const user = await updateSession();
            setUser(user);
        } catch {
            setUser(undefined);
        }
    };

    // Call refreshAuth on mount
    useEffect(() => {
        refreshAuth();
    }, []);

    const value = { user, setUser, refreshAuth };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    const { user, refreshAuth } = context;

    const isAuthenticated = !!user;

    const hasRole = useCallback((role: number) => {
        if (! user) return false
        return user.role >= role;
    }, [user]);

    return { ...user, isAuthenticated, hasRole, refreshAuth };
};