import { Transform, Type } from "class-transformer";
import { IsOptional, IsNumber } from "class-validator";

export class ItemsListQuery {

    @IsOptional()
    @Transform(({ value }) => Array.isArray(value) ? value.map(Number) : value)
    @IsNumber({}, { each: true })
    @Type(() => Number)
    declare games?: Array<number> | number;
}