import { Outlet } from "react-router-dom";
import ForbiddenPage from "./Forbidden";
import { useAuth } from "../context/user/user.context";

interface AuthRouteProps {
    grade?: number;
}

export default function AuthRoute(props: Readonly<AuthRouteProps>) {
    const grade = props.grade ?? 1
    const {hasRole} = useAuth()

    return (
        <>
            {hasRole(grade) ?
                <Outlet /> :
                <ForbiddenPage />
            }
        </>
    )
}