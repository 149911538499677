import { useState } from "react";
import CardZone from "../../../../components/card-zone/card-zone.component"
import useGameParts from "../../hooks/useGameParts";
import { LoadingPage } from "../../../../components/loading/loading.component";
import { ThumbnailCardBox } from "../../../../components/ThumbnailCardBox/ThumbnailCardBox";
import GamePartModal from "../AddGamePartModal/GamePartModal";
import { Game, H4A_API } from "@hamm4all/shared";

interface GamePartsCardZoneProps {
    game: Game
}

export default function GamePartsCardZone(props: Readonly<GamePartsCardZoneProps>) {
    const [isAddGamePartModalOpen, setIsAddGamePartModalOpen] = useState(false);
    const gamePartsQuery = useGameParts({"gameID": props.game.id})

    if (gamePartsQuery.isLoading) {
        return <LoadingPage />
    }

    const {gameParts} = gamePartsQuery;

    return (
        <CardZone
            roleToAdd={H4A_API.v1.gameParts.add.getRole()}
            toggleModal={setIsAddGamePartModalOpen}
            modal={
                <GamePartModal gameID={props.game.id} isOpen={isAddGamePartModalOpen} setIsOpen={setIsAddGamePartModalOpen} type="add" />
            }
        >
            <>
                {gameParts.map((gamePart, index) => {
                    return <ThumbnailCardBox
                        key={gamePart.id}
                        href={`/games/${props.game.id}/parts/${index + 1}`}
                        name={gamePart.name}
                        thumbnail_url={props.game.thumbnail_id}
                        level_count={gamePart.level_count}
                    />
                })}
            </>
        </CardZone>
    )
}