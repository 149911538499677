import { APIEndpointGET, APIVersion, URIBuilder } from "../../../../api.endpoint";
import { H4ARole } from "../../../api.roles";
import { LevelsParam } from "../../levels.params";
import { LevelDescription } from "../description.types";
import { LevelsDescriptionListQuery } from "./description.list.query";

export class LevelsDescriptionListCall extends APIEndpointGET<LevelsParam, LevelsDescriptionListQuery, LevelDescription[]> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder() {
        return new URIBuilder<LevelsParam>()
            .addPath("games")
            .addParam("gameID")
            .addPath("levels")
            .addParam("levelName")
            .addPath("descriptions")
    }

    public getRole(): H4ARole {
        return H4ARole.NOT_AUTHENTICATED
    }
}