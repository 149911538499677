import { ThumbnailSequenceParam, ThumbnailSequence, ThumbnailLink, ReducedLevel, H4A_API } from "@hamm4all/shared";
import { useState } from "react";
import OneTenthColumn from "../../../../../../common/layout/columns/OneTenthColumn";
import { AdderIcon } from "../../../../../../components/adder/adder.component";
import LevelThumbnail from "../../../../../../common/components/LevelThumbnail/LevelThumbnail";
import ThumbnailLinkModal from "../../../../../game-parts/game-part/components/ThumbnailLinkModal/ThumbnailLinkModal";
import { useAuth } from "../../../../../../context/user/user.context";
import ThumbnailLinkControlButtons from "../../control-buttons/ThumbnailLinkControlButtons";

interface RenderClassicSequenceProps extends ThumbnailSequenceParam {
    sequence: ThumbnailSequence;
    links: ThumbnailLink[];
    levels: ReducedLevel[];
}

export default function RenderClassicSequence(props: Readonly<RenderClassicSequenceProps>) {
    const {hasRole} = useAuth()
    const [isAddThumbnailLinkModalOpen, setIsAddThumbnailLinkModalOpen] = useState(false)

    return (
        <div className={`columns is-multiline is-gapless box is-mobile`} style={{"alignItems": "center", "justifyItems": "center"}}>
            {props.links.map((link) => 
                <OneTenthColumn key={link.orderNumber}>
                    <LevelThumbnail
                        gameID={props.gameID}
                        level={link.level}
                        controlButtons={
                            <ThumbnailLinkControlButtons
                                gameID={props.gameID}
                                gamePartIndex={props.gamePartIndex}
                                sequenceIndex={props.sequenceIndex}
                                link={link}
                                levels={props.levels}
                            />
                        }
                    />
                </OneTenthColumn>
            )}
            {hasRole(H4A_API.v1.thumbnailSequences.links.add.getRole()) && <>
                <OneTenthColumn>
                    <AdderIcon toggleModal={() => setIsAddThumbnailLinkModalOpen(true)} />
                </OneTenthColumn>
                <ThumbnailLinkModal
                    type="create"
                    gameID={props.gameID}
                    levelsOfGame={props.levels}
                    gamePartIndex={props.gamePartIndex}
                    sequenceIndex={props.sequenceIndex}
                    isModalOpen={isAddThumbnailLinkModalOpen}
                    setIsModalOpen={setIsAddThumbnailLinkModalOpen}
                />
            </>}
        </div>
    )
}