import { H4A_API } from "@hamm4all/shared"
import useAPIMutation from "../../../core/api/useAPIMutation"

interface UseAddGamePartProps {
    gameID: number
    onClose: () => void
}

export default function useAddGamePart(props: UseAddGamePartProps) {
    const mutation = useAPIMutation({
        "endpoint": H4A_API.v1.gameParts.add,
        "params": props,
        "onSuccess": props.onClose
    })

    return mutation
}