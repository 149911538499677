import { H4A_API } from "@hamm4all/shared";
import Draggable from "react-draggable";
import { useContext } from "react";
import { OrderNumbersContext } from "../../levels/screenshots/context/OrderNumbersContext";
import { useAuth } from "../../../context/user/user.context";

export default function LevelOrderComponent() {
    const {hasRole} = useAuth()
    const orderNumbersContext = useContext(OrderNumbersContext)
    if (!orderNumbersContext) {
        return <></>
    }

    function changeOrderNumberPosition(index: number, x: number, y: number) {
        const newOrderNumbers = [...orderNumbers]
        newOrderNumbers[index] = {
            "x": x / screenshotSize.width * 21,
            "y": y / screenshotSize.height * 25
        }
        setOrderNumbers(newOrderNumbers)
    }

    const {orderNumbers, setSelectedNumberIndex, setOrderNumbers} = orderNumbersContext

    const screenshotSize = orderNumbersContext.screenshotSize
    const hasRightToMoveNumbers = hasRole(H4A_API.v1.levels.screenshots.fruitOrder.update.getRole())

    return <>
        {orderNumbers.map((orderNumber, index) => {
            const style: any = {
                "position": "absolute",
                "top": `0px`,
                "left": `0px`,
                "fontFamily": "Franklin Gothic Heavy",
                "fontSize": 22,
                "fontWeight": "bold"
            }
            if (hasRightToMoveNumbers) {
                style.cursor = "move"
            }

            if (index === orderNumbersContext.selectedNumberIndex) {
                style.borderBottom = "2px solid turquoise"
            }
            
            return (
                <Draggable
                    key={index}
                    position={{
                        x: orderNumber.x / 21 * screenshotSize.width,
                        y: orderNumber.y / 25 * screenshotSize.height
                    }}
                    disabled={!hasRightToMoveNumbers}
                    bounds="parent"
                    onStop={(e, data) => changeOrderNumberPosition(index, data.x, data.y)}
                >
                    <div style={style} onClick={() => setSelectedNumberIndex(index)}>
                        {index + 1}
                    </div>
                </Draggable>
            )
        })}
    </>
}