import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LanguageInputProps {
    required?: boolean;
    value: string;
    language: string;
    onNameChange: (language: string, name: string) => void;
    onRemove: (language: string) => void;
}

export default function FormLanguageInput({ language, value, onNameChange, onRemove, required }: Readonly<LanguageInputProps>) {

    return (
        <div className="field has-addons">
            <div className="control">
                <button className="button is-static" tabIndex={-1}>
                    {language}
                </button>
            </div>
            <div className="control is-expanded">
                <input className="input" type="text" placeholder={language} onChange={e => onNameChange(language, e.target.value)} value={value}/>
            </div>
            <div className="control">
                <button className={`button is-danger`} onClick={() => onRemove(language)} disabled={required} title={required ? "Impossible d'enlever le champ pour cette langue, car c'est la langue par défault" : ""} tabIndex={-1}>
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            </div>
        </div>
    )
}