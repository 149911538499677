import ItemWeightTable from "./ItemWeightTable/ItemWeightTable";

export default function ItemsPageIntroduction() {
    return (
        <div className="content">
            <p>Les objets ont ce qu'on appelle un coefficient de rareté, qui détermine leur probabilité d'apparaitre en jeu. À de rares exceptions près, plus le coefficient est élevé, moins on a de chance de trouver l'objet en jeu.</p>
            <p>Voici la liste des probabilités d'apparition pour chaque coefficient :</p>
            <ItemWeightTable />
            <p>Les coefficients 0 ne peuvent apparaitre qu'à des endroits précis dans le jeu (comme les clés), ou après un évènement (comme les cristaux ou les petits champignons). Bien que les clés et les pads soient notés coefficient 6 dans le jeu, leurs familles ne sont jamais débloquées et il est impossible de les voir apparaître à des emplacements standards d'objets. Ces objets sont donc à considérer comme des coefficients 0.</p>
            <p>Il existe une technique appelée le blocage de quêtes, qui consiste à bloquer certaines quêtes pour augmenter la probabilité d'apparition de certes objets à coefficient 5. C'est notamment le cas de la <img src="/assets/items/1192.svg" alt="" /> Méluzzine, de la <img src="/assets/items/1213.svg" alt="" /> Liqueur maléfique ou de l' <img src="/assets/items/1218.svg" alt="" /> Agrafeuse du Chaos.</p>
            <p>Les deux seuls joueurs connus à avoir débloqué un coefficient 6 autre que le <img src="/assets/items/112.svg" alt=""/> Pioupiou carnivore sont <a href="http://www.hfest.net/user.html/63782">JoeKickass</a> et <a href="https://eternalfest.net/users/37a91fdd-9a5b-41c5-a6af-0b0643cb265e">fabianh</a>, sur le serveur anglais.</p>
        </div>
    )
}