import { ThumbnailLink, ThumbnailSequence, ThumbnailSequenceParam } from "@hamm4all/shared";
import useLevels from "../../../levels/hooks/use-levels.hook";
import RenderClassicSequence from "./render/classic/RenderClassicSequence";
import RenderDimensionSequence from "./render/dimension/RenderDimensionSequence";
import RenderLabySequence from "./render/laby/RenderLabySequence";

interface LevelZoneProps extends ThumbnailSequenceParam {
    sequence: ThumbnailSequence;
    links: ThumbnailLink[]
}

export default function LevelThumbnailZone(props: Readonly<LevelZoneProps>) {
    const {levels, isLoading} = useLevels(props.gameID)

    if (isLoading) {
        return <></>
    }

    return <>
        {(! props.sequence.isFirstLevelApart) && (! props.sequence.hasLabyrinth) &&
            <RenderClassicSequence
                gameID={props.gameID}
                sequence={props.sequence}
                links={props.links}
                levels={levels}
                sequenceIndex={props.sequence.orderNumber}
                gamePartIndex={props.gamePartIndex}
            />
        }
        {props.sequence.isFirstLevelApart &&
            <RenderDimensionSequence
                gameID={props.gameID}
                sequence={props.sequence}
                links={props.links.filter((link) => !link.position)}
                levels={levels}
                sequenceIndex={props.sequence.orderNumber}
                gamePartIndex={props.gamePartIndex}
            />
        }
        {props.sequence.hasLabyrinth && 
            <RenderLabySequence
                gameID={props.gameID}
                sequence={props.sequence}
                links={props.links}
                levels={levels}
                gamePartIndex={props.gamePartIndex}
            />
        }
    </>
}