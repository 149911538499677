import { Content } from "react-bulma-components"
import { Table } from "../../layout/table/table.component"
import "./fruits.css"
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fruits from "./fruits.json"
import { Title } from "../../common/layout/title/Title"

export default function FruitsEnemies() {
    return <>
        <Title>Les ennemis</Title>
        <Content>
            <p>Hammerfest est un jeu riche en ennemis. Ceux-ci ont divers pouvoir, et se comptent au nombre de 18 + 2 boss.</p>
            <br />
        </Content>
        <Table id="fruits-table">
            <thead>
                <tr>
                    <td>Apparence</td>
                    <td>Nom</td>
                    <td>Déplacement</td>
                    <td>Sauter</td>
                    <td>Monter</td>
                    <td>Descendre</td>
                    <td>Grimper</td>
                    <td>Pouvoir</td>
                </tr>
            </thead>
            <tbody>
                {fruits.map(fruit => 
                    <tr key={fruit.name}>
                        <td><img src={fruit.image} alt={fruit.name} /></td>
                        <td>{fruit.name}</td>
                        <td>
                            {fruit.move === "walk" ? "Marche" : ""}
                            {fruit.move === "crawl" ? "Rampe" : ""}
                            {fruit.move === "fly" ? "Vole" : ""}
                        </td>
                        <td className="has-text-centered">
                            {fruit.jump ?
                                <FontAwesomeIcon icon={faCheck} className="has-text-success" /> :
                                <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
                            }
                        </td>
                        <td className="has-text-centered">
                            {fruit.up ?
                                <FontAwesomeIcon icon={faCheck} className="has-text-success" /> :
                                <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
                            }
                        </td>
                        <td className="has-text-centered">
                            {fruit.down ?
                                <FontAwesomeIcon icon={faCheck} className="has-text-success" /> :
                                <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
                            }
                        </td>
                        <td className="has-text-centered">
                            {fruit.climb ?
                                <FontAwesomeIcon icon={faCheck} className="has-text-success" /> :
                                <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
                            }
                        </td>
                        <td className="has-text-centered">
                            {fruit.power ?
                                fruit.power :
                                <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    </>
}