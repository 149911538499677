import { LevelsVideoParam, H4A_API } from "@hamm4all/shared"
import useAPIMutation from "../../../../core/api/useAPIMutation"

interface UseVideoUpdateProps extends LevelsVideoParam {
    closePopup?: () => void
}

export default function useVideoUpdate(props: Readonly<UseVideoUpdateProps>) {
    const videoMutation = useAPIMutation({
        "endpoint": H4A_API.v1.levels.videos.update,
        "params": props,
        "onSuccess": props.closePopup
    })

    return videoMutation
}