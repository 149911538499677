import { IsDateString, IsUUID, ValidateNested } from "class-validator";
import { ReducedUser } from "../../users/users.types";
import { Type } from "class-transformer";


export class LevelScreenshot {
    @IsUUID()
    declare id: string;

    @IsDateString()
    declare created_at: string;

    @ValidateNested()
    @Type(() => ReducedUser)
    declare created_by: ReducedUser;
}