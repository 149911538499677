import { FormFieldCheckbox } from "../../../components/form/field-components/form-checkbox.component";
import LevelOrderComponent from "./LevelOrderComponent";
import { useContext, useState } from "react";
import { LevelScreenshot, LevelsParam } from "@hamm4all/shared";
import LevelGrid from "./LevelGrid";
import LevelScreenshotsPagination from "./LevelScreenshotsPagination";
import { OrderNumbersContext } from "../../levels/screenshots/context/OrderNumbersContext";
import { getBlobPathFromId } from "../../../utilities/blobs/blobs.utilities";

interface LevelScreenshotPlaceProps extends LevelsParam {
    screenshots: LevelScreenshot[];
    screenDisplayedOrderNumber: number;
    setScreenDisplayedOrderNumber: React.Dispatch<React.SetStateAction<number>>;
}

export default function LevelScreenshotPlace(props: Readonly<LevelScreenshotPlaceProps>) {
    const [displayGrid, setDisplayGrid] = useState<boolean>(false)
    const currentScreenshot = props.screenshots?.[props.screenDisplayedOrderNumber - 1]
    const displayedScreenshotPath = currentScreenshot?.id
    const orderNumbersContext = useContext(OrderNumbersContext)
    if (!orderNumbersContext) {
        return <></>
    }

    return <>
        <div style={{"position": "relative"}}>
            <img
                src={displayedScreenshotPath ? getBlobPathFromId(displayedScreenshotPath) : "/assets/min/unknown.png"}
                alt={props.levelName}
                title={props.levelName}
                width="100%"
                onLoad={(event) => {
                    orderNumbersContext!.setScreenshotSize({
                        "width": event.currentTarget.clientWidth,
                        "height": event.currentTarget.clientHeight
                    })
                }}
                onClick={() => orderNumbersContext.setSelectedNumberIndex(-1)}
            />
            {displayGrid && <LevelGrid />}
            {props.screenshots.length > 0 &&
                <LevelOrderComponent />
            }
        </div>
        <p className="has-text-right is-italic">
            Screen ajouté par {currentScreenshot?.created_by.name} le {new Date(currentScreenshot?.created_at).toLocaleDateString("fr-FR")}
        </p>
        <LevelScreenshotsPagination
            gameID={props.gameID}
            levelName={props.levelName}
            screenDisplayedOrderNumber={props.screenDisplayedOrderNumber}
            setScreenDisplayedOrderNumber={props.setScreenDisplayedOrderNumber}
            screenshots={props.screenshots}
            displayedScreenshotPath={displayedScreenshotPath}
        />
        <div className="box has-text-centered">
            <FormFieldCheckbox label="Afficher la grille" changeHandler={setDisplayGrid} />
        </div>
    </>
}