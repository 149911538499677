import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import useOrderNumbers from "../hook/useOrderNumbers";
import { ScreenshotParam } from "@hamm4all/shared";

interface OrderNumber {
    x: number;
    y: number;
}

interface OrderNumbersContextType {
    orderNumbers: { x: number, y: number }[],
    setOrderNumbers: (orderNumbers: { x: number, y: number }[]) => void,
    selectedNumberIndex: number,
    setSelectedNumberIndex: (index: number) => void,
    addOrderNumber: () => void,
    removeSelectedOrderNumber: () => void;
    screenshotSize: {width: number, height: number};
    setScreenshotSize: (size: {width: number, height: number}) => void;
}

export const OrderNumbersContext = createContext<OrderNumbersContextType | undefined>(undefined);

interface OrderNumbersProviderProps extends ScreenshotParam {
    children: ReactNode;
}

export function OrderNumbersProvider(props: Readonly<OrderNumbersProviderProps>) {
    const { orderNumbers, isLoading } = useOrderNumbers({
        "gameID": props.gameID,
        "levelName": props.levelName,
        "screenshotOrderNumber": props.screenshotOrderNumber
    });

    const [selectedNumberIndex, setSelectedNumberIndex] = useState<number>(-1);
    const [localOrderNumbers, setLocalOrderNumbers] = useState<OrderNumber[]>([]);
    const [screenshotSize, setScreenshotSize] = useState<{width: number, height: number}>({width: 0, height: 0});

    useEffect(() => {
      if (!isLoading) {
        setLocalOrderNumbers(orderNumbers);
      }
    }, [isLoading, orderNumbers]);

    function addOrderNumber() {
        setLocalOrderNumbers((old) => [...old, { "x": 10, "y": 10 }]);
    }

    const removeSelectedOrderNumber = useCallback(() => {
        setLocalOrderNumbers((old) => old.filter((_, i) => i !== selectedNumberIndex));
        setSelectedNumberIndex(-1);
    }, [selectedNumberIndex])

    const contextValue = useMemo<OrderNumbersContextType>(() => ({
        orderNumbers: localOrderNumbers,
        setOrderNumbers: setLocalOrderNumbers,
        selectedNumberIndex,
        setSelectedNumberIndex,
        addOrderNumber,
        removeSelectedOrderNumber,
        screenshotSize,
        setScreenshotSize
    }), [localOrderNumbers, removeSelectedOrderNumber, selectedNumberIndex, screenshotSize, setScreenshotSize]);

    return (
        <OrderNumbersContext.Provider
            value={contextValue}
        >
            {props.children}
        </OrderNumbersContext.Provider>
    );
};
