import { H4A_API } from "@hamm4all/shared";
import useAPIMutation from "../../../core/api/useAPIMutation";

interface UseLogoutProps {
    onSuccess: () => void;
}

export default function useLogout(props: Readonly<UseLogoutProps>) {
    const logout = useAPIMutation({
        "endpoint": H4A_API.v1.auth.logout,
        "onSuccess": () => props.onSuccess(),
        "params": {}
    })

    return logout
}