import { Game } from "@hamm4all/shared"
import { Form } from "react-bulma-components"

interface GamesSorterProps {
    sortingMethod: SortingTypes
    setSortingMethod: (sortingMethod: SortingTypes) => void
}

export type SortingTypes = "name" | "difficulty" | "release_date" | "level_count"

export const sortingMethods = {
    "name": (a: Game, b: Game) => a.name.localeCompare(b.name),
    "release_date": (a: Game, b: Game) => (new Date(a.released_at).getTime() || 0) - (new Date(b.released_at).getTime() || 0),
    "level_count": (a: Game, b: Game) => b.level_count - a.level_count,
    "difficulty": (a: Game, b: Game) => (a.difficulty ?? 0) - (b.difficulty ?? 0)
}

export default function GamesSorter(props: Readonly<GamesSorterProps>) {
    return (
        <div className="field">
            <div className="control">
                <Form.Select fullwidth onChange={(e) => props.setSortingMethod(e.target.value as SortingTypes)} data-cy={"games-sorter"}>
                    <option value="difficulty">Difficulté</option>
                    <option value="name">Nom</option>
                    <option value="release_date">Date de sortie</option>
                    <option value="level_count">Nombre de niveaux</option>
                </Form.Select>
            </div>
        </div>
    )
}