import { useState } from "react";
import { FormFieldTextArea } from "../../../components/form/field-components/form-field-input.component";
import ModalForm from "../../../components/form/modal-form.component";
import useUpdateDescription from "../../levels/hooks/useUpdateDescription";
import { useQueryClient } from "@tanstack/react-query";

interface DescriptionEditModalProps {
    initialDescription?: string;
    gameID: number;
    levelName: string
    setIsDescriptionEditModalOpen: (isOpen: boolean) => void
    isDescriptionEditModalOpen: boolean
}

export default function DescriptionEditModal(props: Readonly<DescriptionEditModalProps>) {
    const [description, setDescription] = useState(props.initialDescription ?? "")
    const descriptionUpdateMutation = useUpdateDescription({
        "gameID": props.gameID,
        "levelName": props.levelName,
        "onClose": () => props.setIsDescriptionEditModalOpen(false)
    })

    return (
        <ModalForm
            isOpen={props.isDescriptionEditModalOpen}
            setIsOpen={props.setIsDescriptionEditModalOpen}
            title="Ajouter une description"
            handleSubmit={async () => descriptionUpdateMutation.mutate({"content": description})}
        >
            <FormFieldTextArea
                name="description"
                label="Description"
                placeholder="Description du niveau"
                changeHandler={(value) => setDescription(value)}
                value={description}
            />
        </ModalForm>
    )
}