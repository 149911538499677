import { H4A_API } from "@hamm4all/shared";
import useAPIQuery from "../../../../hooks/use-api-query.hook";

interface UseScreenshotsProps {
    gameID: number;
    levelName: string;
}

export default function useScreenshots(props: UseScreenshotsProps) {
    const {gameID, levelName} = props
    const screenshotsQuery = useAPIQuery({
        "endpoint": H4A_API.v1.levels.screenshots.list,
        "params": {
            "gameID": gameID,
            "levelName": levelName
        },
        "query": {}
    })

    const screenshots = screenshotsQuery.data

    return {
        ...screenshotsQuery,
        screenshots
    }
}