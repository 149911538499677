import { useState } from "react";
import useVideos from "../hooks/useVideos";
import Pagination from "../../../../common/components/Pagination";
import { LevelsVideo } from "@hamm4all/shared";
import { Subtitle } from "../../../../common/layout/title/Subtitle";
import LevelVideosButtons from "./LevelVideosButtons";
import LevelVideosDisplay from "./LevelVideosPlace";

interface LevelVideosProps {
    gameID: number;
    levelName: string;
}

export default function LevelVideosZone(props: Readonly<LevelVideosProps>) {
    const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0)
    const videosQuery = useVideos({
        "gameID": props.gameID,
        "levelName": props.levelName
    })

    if (videosQuery.isLoading) {
        return <div>Chargement des vidéos...</div>
    }

    const videos = videosQuery.videos
    const currentVideo = videos[selectedVideoIndex] as (LevelsVideo | undefined)

    return <div className="box">
        <Subtitle centered>Vidéos</Subtitle>
        {currentVideo ?
            <LevelVideosDisplay {...currentVideo} /> :
            <div className="has-text-centered">
                Aucune vidéo n'a encore été ajoutée pour ce niveau
            </div>
        }
        <br/>
        <Pagination
            index={selectedVideoIndex}
            setIndex={setSelectedVideoIndex}
            length={videos.length}
        />
        <LevelVideosButtons
            gameID={props.gameID}
            levelName={props.levelName}
            selectedVideoIndex={selectedVideoIndex}
            currentVideo={currentVideo}
            videos={videos}
        />
    </div>
}