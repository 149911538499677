import { Type } from "class-transformer";
import { IsInt, Min } from "class-validator";
import { LevelsParam } from "../levels.params";


export class ScreenshotParam extends LevelsParam {

    @IsInt()
    @Type(() => Number)
    @Min(1)
    declare screenshotOrderNumber: number;
}