import { HTTPMethod } from "../../../api.call";
import { APIEndpointAction, APIEndpointErrorParams, APIEndpointSuccessParams, APIVersion, URIBuilder } from "../../../api.endpoint";
import { H4ARole } from "../../api.roles";
import { ChangelogForVersion } from "../changelog.types";
import { AddChangelogBody } from "./changelog.add.body";

export class AddChangelogCall extends APIEndpointAction<{}, AddChangelogBody, ChangelogForVersion> {
    protected getVersion(): APIVersion {
        return 1
    }

    protected getUriBuilder(): URIBuilder<{}> {
        return new URIBuilder()
            .addPath("changelog")
    }

    public getMethod(): HTTPMethod {
        return "POST"
    }

    public getRole(): H4ARole {
        return H4ARole.OWNER
    }

    public override getErrorMessage(params: APIEndpointErrorParams<{}, AddChangelogBody>): string {
        return `Une erreur s'est produite lors de l'ajout du changelog : ${params.error.data.message}`
    }

    public override getSuccessMessage(params: APIEndpointSuccessParams<{}, AddChangelogBody, ChangelogForVersion>): string {
        return `Le changelog a bien été ajouté !`
    }
}